import { Button, Toolbar, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import React, { CSSProperties, useContext, useState } from 'react';
import DeleteLibrariesDialog from '../../../components/dialogs/DeleteLibrariesDialog/DeleteLibrariesDialog';
import NewLibraryDialog from '../../../components/dialogs/NewLibraryDialog/NewLibraryDialog';
import DataTable from '../../../components/main/DataTable/DataTable';
import { UserRoleEnum, useGetLibrariesQuery } from '../../../generated/graphql';
import { ClientContext } from '../Clients.Provider';
import styles from './Libraries.module.css';
import ShowOnRole from '../../../components/main/ShowOnRole/ShowOnRole';

type LibrariesProps = {
    style?: CSSProperties | undefined;
};

const Libraries = (props: LibrariesProps) => {
    const { style } = props;
    const client = useContext(ClientContext);
    const [searchText, setSearchText] = useState("");
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [newDialogVisible, setNewDialogVisible] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const result = useGetLibrariesQuery({
        variables: {
            contains: searchText,
            clientId: client?.data?.Client?.id
        }
    });
    const handleSearchBoxChanged = (value: string) => {
        setSearchText(value);
    };
    const handleDataTableSelected = (ids: string[]) => {
        setSelectedIds(ids);
    };
    const newDialogClose = () => {
        setNewDialogVisible(false);
        result.refetch();
    };
    const deleteDialogClose = () => {
        setDeleteDialogVisible(false);
        result.refetch();
    };

    return (
        <>
            <StackLayout
                className={styles.Libraries}
                style={style}
                data-testid="Libraries"
                orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0 }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => result.refetch()}>
                        Refresh
                    </Button>
                    <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={client?.data?.Client?.isAdmin || false}>
                        <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setNewDialogVisible(true)}>
                            New Library
                        </Button>
                        {selectedIds.length > 0 && <>
                            <ToolbarSeparator />
                            <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteDialogVisible(true)}>
                                Delete
                            </Button>
                        </>}
                    </ShowOnRole>

                </Toolbar>
                <DataTable
                    showDefaultColumns
                    style={{ flex: 1 }}
                    isLoading={false}
                    data={result.data?.LibraryList?.nodes}
                    onSearchBoxChanged={handleSearchBoxChanged}
                    onSelected={handleDataTableSelected}
                    link="../Library"
                />
            </StackLayout>
            <NewLibraryDialog clientId={client?.data?.Client?.id} visible={newDialogVisible} onClose={newDialogClose} />
            <DeleteLibrariesDialog clientId={client?.data?.Client?.id || ""} ids={selectedIds} visible={deleteDialogVisible} onClose={deleteDialogClose} />
        </>
    );
};

export default Libraries;