import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './DeleteHealthChecksDialog.module.css';
import { Typography } from '@progress/kendo-react-common';
import { useDeleteHealthCheckListMutation } from '../../../generated/graphql';

type DeleteHealthChecksDialogProps = {
    onClose: () => void,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    ids: string[],
};

const DeleteHealthChecksDialog = (props: DeleteHealthChecksDialogProps) => {
    const { style, visible, ids, onClose } = props;
    const [deleteHealthChecks, deleteHealthChecksResult] = useDeleteHealthCheckListMutation({
        variables: {
            ids: ids
        }
    });

    const handleOnYesClick = () => {
        deleteHealthChecks()
            .then(res => onClose())
            .catch(err => { });
    };

    return (
        <DialogBase
            title="Delete HealthChecks"
            type="YesNo"
            visible={visible}
            onClose={onClose}
            onYesClick={handleOnYesClick}
            isLoading={deleteHealthChecksResult.loading}
            className={styles.DeleteHealthChecksDialog}
            style={style}
            data-testid="DeleteHealthChecksDialog">
            <Typography.p>Are you sure you want to delete these Health Checks?</Typography.p>
        </DialogBase>
    );
};

export default DeleteHealthChecksDialog;