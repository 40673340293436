import { Typography } from '@progress/kendo-react-common';
import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import { useDeleteLibrariesMutation, useDeleteProjectsMutation } from '../../../generated/graphql';
import styles from './DeleteLibrariesDialog.module.css';

type DeleteLibrariesDialogProps = {
    onClose: () => void,
    clientId: string,
    ids: string[],
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const DeleteLibrariesDialog = (props: DeleteLibrariesDialogProps) => {
    const { style, visible, clientId, ids, onClose } = props;
    const [deleteLibraries, deleteLibrariesResult] = useDeleteLibrariesMutation({
        variables: {
            clientId: clientId,
            ids: ids
        }
    });
    const handleOnYesClick = () => {
        deleteLibraries()
            .then(res => onClose())
            .catch(err => { });
    };

    return (
        <DialogBase
            isLoading={deleteLibrariesResult.loading}
            title="Delete Libraries"
            type="YesNo"
            onYesClick={handleOnYesClick}
            visible={visible}
            onClose={onClose}
            className={styles.DeleteLibrariesDialog}
            style={style}
            data-testid="DeleteLibrariesDialog">
            <Typography.p>Are you sure you want to delete these libraries?</Typography.p>
        </DialogBase>
    );
};

export default DeleteLibrariesDialog;