import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './DeleteTagsDialog.module.css';
import { Typography } from '@progress/kendo-react-common';
import { useDeleteTagListMutation } from '../../../generated/graphql';

type DeleteTagsDialogProps = {
    onClose: () => void,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    ids: string[],
};

const DeleteTagsDialog = (props: DeleteTagsDialogProps) => {
    const { style, visible, ids, onClose } = props;
    const [deleteTags, deleteTagsResult] = useDeleteTagListMutation({
        variables: {
            ids: ids
        }
    });

    const handleOnYesClick = () => {
        deleteTags()
            .then(res => onClose())
            .catch(err => { });
    };

    return (
        <DialogBase
            title="Delete Tags"
            type="YesNo"
            visible={visible}
            onClose={onClose}
            onYesClick={handleOnYesClick}
            isLoading={deleteTagsResult.loading}
            className={styles.DeleteTagsDialog}
            style={style}
            data-testid="DeleteTagsDialog">
            <Typography.p>Are you sure you want to delete these tags?</Typography.p>
        </DialogBase>
    );
};

export default DeleteTagsDialog;