import React, { CSSProperties, ReactNode, useState } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './SearchForApprovedContentsDialog.module.css';
import { Tag, useGetContentsWithCategoryQuery, useSetProjectApprovedContentsMutation } from '../../../generated/graphql';
import DataTable from '../../main/DataTable/DataTable';
import ImageViewerButton from '../../main/ImageViewerButton/ImageViewerButton';

type SearchForApprovedContentsDialogProps = {
    onClose: () => void,
    projectId: string,
    libraryId: string,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const SearchForApprovedContentsDialog = (props: SearchForApprovedContentsDialogProps) => {
    const { style, visible, libraryId, projectId, onClose } = props;
    const [search, setSearch] = useState("");
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [tagIds, setTagIds] = useState<string[]>([]);
    const contents = useGetContentsWithCategoryQuery({
        variables: {
            tagIds: tagIds,
            contains: search,
            category: "Views",
            libraryId: libraryId
        }
    });
    const [setApprovedContents, setApprovedContentsResult] = useSetProjectApprovedContentsMutation();

    const handleOnOk = () => {
        setApprovedContents({
            variables: {
                id: projectId,
                ids: selectedIds
            }
        })
            .then(() => onClose())
            .catch(err => onClose());
    };

    const handleFilterChanged = (tags: Tag[]) => {
        tags && setTagIds(tags.map(t => t.id || ""));
    };

    return (
        <DialogBase
            title="Search Contents"
            type="OkCancel"
            isLoading={setApprovedContentsResult.loading}
            visible={visible}
            onClose={onClose}
            onOkClick={handleOnOk}
            className={styles.SearchForApprovedContentsDialog}
            style={style}
            data-testid="SearchForApprovedContentsDialog">
            <DataTable
                showTagsColumn
                style={{ height: "600px", width: "900px" }}
                onSelected={(ids) => setSelectedIds(ids)}
                onSearchBoxChanged={(value) => setSearch(value)}
                onFilterChanged={handleFilterChanged}
                data={contents.data?.Library?.contents?.nodes}
                isLoading={contents.loading}
                actionButtonsWidth='55px'
                actionButtons={[
                    (row) => row.dataItem?.thumbnail?.downloadUrl && <ImageViewerButton
                        title={row.dataItem?.name}
                        link={row.dataItem?.thumbnail?.downloadUrl} />
                ]}
                fetchMore={() => contents.data?.Library?.contents?.pageInfo?.hasNextPage && contents.fetchMore({
                    variables: {
                        after: contents.data?.Library?.contents?.pageInfo.endCursor
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return previousResult;
                        const previousContents = contents.data?.Library?.contents?.nodes || [];
                        const newContents = fetchMoreResult.Library?.contents?.nodes || [];
                        return {
                            ...previousResult,
                            Library: {
                                id: contents.data?.Library?.id,
                                contents: {
                                    nodes: [...previousContents, ...newContents],
                                    pageInfo: fetchMoreResult.Library?.contents?.pageInfo as any
                                }
                            }
                        };
                    }
                })}
            />
        </DialogBase>
    );
};

export default SearchForApprovedContentsDialog;