import React, { CSSProperties, ReactNode, useState } from 'react';
import styles from './HealthChecks.module.css';
import { Toolbar, Button, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import DataTable from '../../../components/main/DataTable/DataTable';
import { useGetHealthCheckListQuery } from '../../../generated/graphql';
import DeleteHealthChecksDialog from '../../../components/dialogs/DeleteHealthChecksDialog/DeleteHealthChecksDialog';
import NewHealthCheckDialog from '../../../components/dialogs/NewHealthCheckDialog/NewHealthCheckDialog';
import AddHealthChecksTagsDialog from '../../../components/dialogs/AddHealthChecksTagsDialog/AddHealthChecksTagsDialog';
import { GridCellProps } from '@progress/kendo-react-grid';
import { useParams } from 'react-router-dom';
import EditContentSettingsDialog from '../../../components/dialogs/EditContentSettingsDialog/EditContentSettingsDialog';
import EditHealthCheckSettingsDialog from '../../../components/dialogs/EditHealthCheckSettingsDialog/EditHealthCheckSettingsDialog';

type HealthChecksProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
};

const HealthChecks = (props: HealthChecksProps) => {
    const { style, className } = props;
    const [assignHealthChecksTagsVisible, setAssignHealthChecksTagsVisible] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [deleteHealthCheckDialogVisible, setDeleteHealthChecksDialogVisible] = useState(false);
    const [newHealthCheckVisible, setNewHealthCheckVisible] = useState(false);

    const result = useGetHealthCheckListQuery({
        variables: {
            contains: searchText
        }
    });

    const handleOnClose = () => {
        setDeleteHealthChecksDialogVisible(false);
        setNewHealthCheckVisible(false);
        setAssignHealthChecksTagsVisible(false);
        result.refetch();
    };

    const handleDataTableOnSelected = (ids: string[]) => {
        setSelectedIds(ids);
    };

    const handleSearchBoxonChanged = (value: string) => {
        setSearchText(value);
    };

    const SettingCell = (row: GridCellProps) => {
        const [editHealthCheckSettingsVisible, setEditHealthCheckSettingsVisible] = useState(false);
        const handleOnClose = () => {
            setEditHealthCheckSettingsVisible(false);
            result.refetch();
        };

        return (
            <StackLayout orientation='horizontal' align={{ horizontal: "center" }}>
                <Button icon='edit' onClick={() => setEditHealthCheckSettingsVisible(true)} />
                {editHealthCheckSettingsVisible && <EditHealthCheckSettingsDialog
                    visible={editHealthCheckSettingsVisible}
                    id={row.dataItem.id}
                    settings={row.dataItem.settings}
                    onClose={handleOnClose} />}
            </StackLayout>);
    };

    return (
        <>
            <StackLayout orientation='vertical' className={`${styles.HealthChecks} ${className || ""}`}
                style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0
                }} data-testid="HealthChecks"
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="add" fillMode="flat" themeColor={"primary"} onClick={() => setNewHealthCheckVisible(true)}>
                        Add
                    </Button>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => result.refetch()}>
                        Refresh
                    </Button>
                    {selectedIds.length > 0 && <>
                        <ToolbarSeparator />
                        <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setAssignHealthChecksTagsVisible(true)}>
                            Assign Tags
                        </Button>
                        <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteHealthChecksDialogVisible(true)}>
                            Delete
                        </Button>
                    </>}
                </Toolbar>
                <DataTable
                    showDescriptionColumn
                    showTagsColumn
                    style={{ flex: 1 }}
                    onSelected={handleDataTableOnSelected}
                    onSearchBoxChanged={handleSearchBoxonChanged}
                    isLoading={result.loading}
                    data={result.data?.HealthCheckList?.nodes}
                    customProps={[
                        { id: "group", name: "Group" },
                        { id: "display", name: "Display" },
                        { id: "limit", name: "Limit" },
                        { id: "settings", name: "Settings", width: "78px", customComponent: SettingCell }
                    ]}
                    fetchMore={() => result.data?.HealthCheckList?.pageInfo?.hasNextPage && result.fetchMore({
                        variables: {
                            contains: searchText,
                            after: result.data?.HealthCheckList?.pageInfo.endCursor
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return previousResult;
                            const previousHealthChecks = result.data?.HealthCheckList?.nodes || [];
                            const newHealthChecks = fetchMoreResult.HealthCheckList?.nodes || [];
                            return {
                                ...previousResult,
                                HealthCheckList: {
                                    nodes: [...previousHealthChecks, ...newHealthChecks],
                                    pageInfo: fetchMoreResult.HealthCheckList?.pageInfo as any
                                }
                            };
                        }
                    })} />
            </StackLayout>
            {deleteHealthCheckDialogVisible && <DeleteHealthChecksDialog ids={selectedIds} visible={deleteHealthCheckDialogVisible} onClose={handleOnClose} />}
            {newHealthCheckVisible && <NewHealthCheckDialog visible={newHealthCheckVisible} onClose={handleOnClose} />}
            {assignHealthChecksTagsVisible && <AddHealthChecksTagsDialog ids={selectedIds} visible={assignHealthChecksTagsVisible} onClose={handleOnClose} />}
        </>
    );
};

export default HealthChecks;