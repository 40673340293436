import { Typography } from '@progress/kendo-react-common';
import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import { useDeleteContentsMutation } from '../../../generated/graphql';
import styles from './DeleteContentsDialog.module.css';

type DeleteContentsDialogProps = {
    onClose: () => void,
    libraryId: string,
    ids: string[],
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const DeleteContentsDialog = (props: DeleteContentsDialogProps) => {
    const { style, visible, libraryId, ids, onClose } = props;
    const [deleteContents, deleteContentsResult] = useDeleteContentsMutation({
        variables: {
            libraryId: libraryId,
            ids: ids
        }
    });
    const handleOnYesClick = () => {
        deleteContents()
            .then(res => onClose())
            .catch(err => { });
    };

    return (
        <DialogBase
            title="Delete Contents"
            type="YesNo"
            onYesClick={handleOnYesClick}
            visible={visible}
            onClose={onClose}
            className={styles.DeleteContentsDialog}
            isLoading={deleteContentsResult.loading}
            style={style}
            data-testid="DeleteContentsDialog">
            <Typography.p>Are you sure you want to delete these contents?</Typography.p>
        </DialogBase>
    );
};

export default DeleteContentsDialog;