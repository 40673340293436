import { Typography } from '@progress/kendo-react-common';
import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import { useDeleteProjectsMutation } from '../../../generated/graphql';
import styles from './DeleteProjectsDialog.module.css';

type DeleteProjectsDialogProps = {
    onClose: () => void,
    ids: string[],
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const DeleteProjectsDialog = (props: DeleteProjectsDialogProps) => {
    const { style, visible, ids, onClose } = props;
    const [deleteProjects, deleteProjectsResult] = useDeleteProjectsMutation({
        variables: {
            ids: ids
        }
    });
    const handleOnYesClick = () => {
        deleteProjects()
            .then(res => onClose())
            .catch(err => { });
    };
    return (
        <DialogBase
            title="Delete Projects"
            type="YesNo"
            onYesClick={handleOnYesClick}
            visible={visible}
            onClose={onClose}
            className={styles.DeleteProjectsDialog}
            isLoading={deleteProjectsResult.loading}
            style={style}
            data-testid="DeleteProjectsDialog">
            <Typography.p>Are you sure you want to delete these projects?</Typography.p>
        </DialogBase>
    );
};

export default DeleteProjectsDialog;