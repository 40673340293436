import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './DeleteClassificationTypesDialog.module.css';
import { Typography } from '@progress/kendo-react-common';
import { useDeleteClassificationTypeListMutation } from '../../../generated/graphql';

type DeleteClassificationTypesDialogProps = {
    onClose: () => void,
    ids: string[],
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const DeleteClassificationTypesDialog = (props: DeleteClassificationTypesDialogProps) => {
    const { style, visible, ids, onClose } = props;
    const [deleteClassificationTypes, deleteClassificationTypesResult] = useDeleteClassificationTypeListMutation({
        variables: {
            ids: ids
        }
    });

    const handleOnYesClick = () => {
        deleteClassificationTypes()
            .then(res => onClose())
            .catch(err => { });
    };

    return (
        <DialogBase
            title="Delete Classification Types"
            type="YesNo"
            visible={visible}
            onClose={onClose}
            className={styles.DeleteClassificationTypesDialog}
            style={style}
            data-testid="DeleteClassificationTypesDialog"
            onYesClick={handleOnYesClick}
            isLoading={deleteClassificationTypesResult.loading}>
            <Typography.p>Are you sure you want to delete these classification types?</Typography.p>
        </DialogBase>
    );
};

export default DeleteClassificationTypesDialog;