import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// MSAL imports
import { BrowserRouter } from 'react-router-dom';
import AppProvider from './App.provider';
import { Auth0Provider } from '@auth0/auth0-react';

ReactDOM.render(
  <BrowserRouter >
    <Auth0Provider
      useRefreshTokens
      cacheLocation='localstorage'
      domain='code-dev.au.auth0.com'
      clientId='5NZktuuByWLR5aGm4DfpDwj9BUU0mGq4'
      authorizationParams={{
        audience: `https://aurecon.dev`,
        redirect_uri: window.location.origin,
        scope: "openid profile email offline_access"
      }}>
      <AppProvider >
        <App />
      </AppProvider>
    </Auth0Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
