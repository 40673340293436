import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import styles from './SettingsEditor.module.css';
import { GridItemChangeEvent, GridCellProps, Grid, GridToolbar, GridColumn } from '@progress/kendo-react-grid';

const editField: string = "inEdit";
let originalData: any[] = [];

const MyCommandCell = (props: any) => {
    const { dataItem } = props;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem.id === undefined;

    return inEdit ? (
        <td className="k-command-cell">
            <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command"
                onClick={() =>
                    isNewItem ? props.add(dataItem) : props.update(dataItem)
                }
            >
                {isNewItem ? "Add" : "Update"}
            </button>
            <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
                onClick={() =>
                    isNewItem ? props.discard(dataItem) : props.cancel(dataItem)
                }
            >
                {isNewItem ? "Discard" : "Cancel"}
            </button>
        </td>
    ) : (
        <td className="k-command-cell">
            <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
                onClick={() => props.edit(dataItem)}
            >
                Edit
            </button>
            <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command"
                onClick={() => props.remove(dataItem)}
            >
                Remove
            </button>
        </td>
    );
};

type SettingsEditorProps = {
    visualizeOnly?: boolean,
    style?: CSSProperties | undefined,
    settings: string,
    children?: ReactNode | undefined,
    className?: string;
    editable?: boolean,
    onDataChange: (data: any[]) => void,
};

const SettingsEditor = (props: SettingsEditorProps) => {
    const { visualizeOnly, style, className, settings, editable, onDataChange } = props;
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        if (!visualizeOnly) {
            originalData = settings ? (JSON.parse(settings) as any[])?.map((i, index) => {
                i.id = index;
                return i;
            }) : [];
            let newItems = getItems();
            setData(newItems);
        }
    }, []);

    useEffect(() => onDataChange(data), [data]);

    const generateId = (data: any) =>
        data.reduce((acc: any, current: any) => Math.max(acc, current.id), 0) + 1;

    const insertItem = (item: any) => {
        item.id = generateId(originalData);
        item.inEdit = false;
        originalData.unshift(item);
        return originalData;
    };

    const getItems = () => {
        return originalData;
    };

    const updateItem = (item: any) => {
        let index = originalData.findIndex((record: any) => record.id === item.id);
        originalData[index] = item;
        return originalData;
    };

    const deleteItem = (item: any) => {
        let index = originalData.findIndex((record: any) => record.id === item.id);
        originalData.splice(index, 1);
        return originalData;
    };

    // modify the data in the store, db etc
    const remove = (dataItem: any) => {
        const newData = [...deleteItem(dataItem)];
        setData(newData);
    };

    const add = (dataItem: any) => {
        dataItem.inEdit = true;
        const newData = insertItem(dataItem);
        setData(newData);

    };

    const update = (dataItem: any) => {
        dataItem.inEdit = false;
        const newData = updateItem(dataItem);
        setData(newData);
    };

    // Local state operations
    const discard = () => {
        const newData = [...data];
        newData.splice(0, 1);
        setData(newData);
    };

    const cancel = (dataItem: any) => {
        const originalItem = getItems().find(
            (p: any) => p.id === dataItem.id
        );
        const newData = data.map((item: any) =>
            item.id === originalItem.id ? originalItem : item
        );

        setData(newData);
    };

    const enterEdit = (dataItem: any) => {
        setData(
            data.map((item: any) =>
                item.id === dataItem.id ? { ...item, inEdit: true } : item
            )
        );
    };

    const itemChange = (event: GridItemChangeEvent) => {
        const newData = data.map((item: any) =>
            item.id === event.dataItem.id
                ? { ...item, [event.field || ""]: event.value }
                : item
        );

        setData(newData);
    };

    const addNew = () => {
        const newDataItem = { inEdit: true, Discontinued: false };
        setData([newDataItem, ...data]);
    };

    const CommandCell = (props: GridCellProps) => (
        <MyCommandCell
            {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
        />
    );

    return (
        <div className={`${styles.SettingsEditor} ${className || ""}`} style={style} data-testid="SettingsEditor">
            <div style={{ flex: 1, height: "100%", width: "100%", position: "relative" }}>
                {
                    visualizeOnly ?
                        <span style={{ whiteSpace: "pre" }}>
                            {settings && JSON.stringify(JSON.parse(settings), null, 4)}
                        </span> :
                        <Grid
                            style={{ position: "absolute", height: "100%" }}
                            data={data}
                            onItemChange={itemChange}
                            editField={editField}
                        >
                            {editable && <GridToolbar>
                                <button
                                    title="Add new"
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                    onClick={addNew}
                                >
                                    Add new
                                </button>
                            </GridToolbar>}
                            <GridColumn field="id" title="Id" width="40px" editable={false} />
                            <GridColumn field="name" title="Name" />
                            <GridColumn field="value" title="Value" />
                            <GridColumn field="unit" title="Unit" />
                            {editable && <GridColumn cell={CommandCell} width="200px" />}
                        </Grid>
                }
            </div>
        </div>
    );
};

export default SettingsEditor;