import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './DeleteUserPermissionDialog.module.css';
import { Typography } from '@progress/kendo-react-common';
import { useDeleteProjectAdminsMutation, useDeleteProjectMembersMutation } from '../../../generated/graphql';

type DeleteUserPermissionDialogProps = {
    onClose: () => void,
    admin?: boolean,
    projectId: string,
    ids: string[],
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const DeleteUserPermissionDialog = (props: DeleteUserPermissionDialogProps) => {
    const { style, visible, admin, projectId, ids, onClose } = props;
    const [deleteAdmins, deleteAdminResult] = useDeleteProjectAdminsMutation({
        variables: {
            id: projectId,
            ids: ids
        }
    });
    const [deleteMembers, deleteMembersResult] = useDeleteProjectMembersMutation({
        variables: {
            id: projectId,
            ids: ids
        }
    });

    const handleOnOkClick = () => {
        admin ? deleteAdmins().then(() => onClose()).catch(err => onClose()) :
            deleteMembers().then(() => onClose()).catch(err => onClose());
    };

    return (
        <DialogBase
            title="Delete User Permission"
            type="YesNo"
            isLoading={deleteAdminResult.loading || deleteMembersResult.loading}
            visible={visible}
            onClose={onClose}
            onYesClick={handleOnOkClick}
            className={styles.DeleteUserPermissionDialog}
            style={style}
            data-testid="DeleteUserPermissionDialog">
            <Typography.p>{`Are you sure you want to delete these ${admin ? "admins" : "members"}?`}</Typography.p>
        </DialogBase>
    );
};

export default DeleteUserPermissionDialog;