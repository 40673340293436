import { QueryResult } from "@apollo/client";
import react, { createContext, CSSProperties, ReactNode } from "react";
import { Exact, GetClassificationTypeQuery, InputMaybe } from "../../generated/graphql";

export const ClassificationTypeContext = createContext<QueryResult<GetClassificationTypeQuery, Exact<{
    id?: InputMaybe<string> | undefined;
}>> | undefined>(undefined);

type ClassificationTypeProviderProps = {
    classification: QueryResult<GetClassificationTypeQuery, Exact<{
        id?: InputMaybe<string> | undefined;
    }>>,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

export const ClassificationTypeProvider = (props: ClassificationTypeProviderProps) => {
    const { children, classification } = props;
    return (<>
        <ClassificationTypeContext.Provider value={classification}>
            {children}
        </ClassificationTypeContext.Provider>
    </>);
};