import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './DeleteApprovedContentsDialog.module.css';
import { useDeleteProjectApprovedContentsMutation } from '../../../generated/graphql';
import { Typography } from '@progress/kendo-react-common';

type DeleteApprovedContentsDialogProps = {
    onClose: () => void,
    projectId: string,
    ids: string[],
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const DeleteApprovedContentsDialog = (props: DeleteApprovedContentsDialogProps) => {
    const { style, visible, ids, projectId, onClose } = props;
    const [deleteApprovedContents, deleteApprovedContentsResult] = useDeleteProjectApprovedContentsMutation({
        variables: {
            id: projectId,
            ids: ids
        }
    });
    const handleOnYesClick = () => {
        deleteApprovedContents()
            .then(res => onClose())
            .catch(err => { });
    };
    return (
        <DialogBase
            title="Delete Contents"
            type="YesNo"
            isLoading={deleteApprovedContentsResult.loading}
            visible={visible}
            onClose={onClose}
            onYesClick={handleOnYesClick}
            className={styles.DeleteApprovedContentsDialog}
            style={style}
            data-testid="DeleteApprovedContentsDialog">
            <Typography.p>Are you sure you want to delete these contents?</Typography.p>
        </DialogBase>
    );
};

export default DeleteApprovedContentsDialog;