import './assets/theme.css';
import './App.css';
import React, { ReactNode } from 'react';
import { useCurrentUser } from './App.hooks';
import AppRouting from './App.routing';
import MainDrawer from './components/main/MainDrawer/MainDrawer';
import Spinner from './components/main/Spinner/Spinner';


type AppProps = {
  children?: ReactNode | undefined,
};

const items = [
  { text: "Inbox", icon: "k-i-inbox", selected: true, route: "/" },
  { separator: true },
  { text: "Notifications", icon: "k-i-bell", route: "/notifications" },
  { text: "Calendar", icon: "k-i-calendar", route: "/calendar" },
  { separator: true },
  { text: "Attachments", icon: "k-i-hyperlink-email", route: "/attachments" },
  { text: "Favourites", icon: "k-i-star-outline", route: "/favourites" },
];

function App(props: AppProps) {
  const currentUser = useCurrentUser();

  return (
    <div className="App">
      {currentUser ?
        <MainDrawer>
          <AppRouting />
        </MainDrawer>
        : <Spinner size="large" />
      }
    </div>
  );
}

export default App;
