import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { TextArea } from '@progress/kendo-react-inputs';
import { Error } from "@progress/kendo-react-labels";
import { Label, Hint } from '@progress/kendo-react-labels';
import React, { CSSProperties, ReactNode } from 'react';
import styles from './ValidatedTextArea.module.css';

type ValidatedTextAreaProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const ValidatedTextArea = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        hint,
        disabled,
        optional,
        ...others
    } = fieldRenderProps;

    const showValidationMessage: string | false | null =
        touched && validationMessage;
    const showHint: boolean = !showValidationMessage && hint;
    const hintId: string = showHint ? `${id}_hint` : "";
    const errorId: string = showValidationMessage ? `${id}_error` : "";

    return (
        <FieldWrapper>
            <Label editorId={id} editorValid={valid} optional={optional}>
                {label}
            </Label>
            <TextArea
                valid={valid}
                id={id}
                disabled={disabled}
                ariaDescribedBy={`${hintId} ${errorId}`}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
        </FieldWrapper>
    );
};

export default ValidatedTextArea;