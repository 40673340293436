import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './ImageViewerDialog.module.css';

type ImageViewerDialogProps = {
    onClose: () => void,
    title: string,
    link: string,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const ImageViewerDialog = (props: ImageViewerDialogProps) => {
    const { style, visible, link, title, onClose } = props;

    return (
        <DialogBase
            title={title}
            type="Custom"
            visible={visible}
            onClose={onClose}
            className={styles.ImageViewerDialog}
            style={style}
            data-testid="ImageViewerDialog">
            <div style={{ maxHeight: "800px" }}>
                <img width="600px" src={link} />
            </div>
        </DialogBase>
    );
};

export default ImageViewerDialog;