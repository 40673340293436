import { Toolbar, Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridHeaderCellProps, GridCellProps } from '@progress/kendo-react-grid';
import { StackLayout, TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import DataTable from '../../../components/main/DataTable/DataTable';
import SearchBox from '../../../components/main/SearchBox/SearchBox';
import { UserRoleEnum, useGetClientQuery, useGetUsersQuery } from '../../../generated/graphql';
import Libraries from '../Libraries/Libraries';
import Projects from '../Projects/Projects';
import Worksets from '../Worksets/Worksets';
import styles from './AccessControl.module.css';
import ShowOnRole from '../../../components/main/ShowOnRole/ShowOnRole';
import NewClientAdminDialog from '../../../components/dialogs/NewClientAdminDialog/NewClientAdminDialog';
import DeleteClientAdminDialog from '../../../components/dialogs/DeleteClientAdminDialog/DeleteClientAdminDialog';

type AccessControlProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const AccessControl = (props: AccessControlProps) => {
    const { style } = props;
    const { clientId } = useParams();
    const [selected, setSelected] = React.useState<number>(0);
    const [newClientAdminVisible, setNewClientAdminVisible] = useState(false);
    const client = useGetClientQuery({
        variables: {
            id: clientId
        }
    });
    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };
    const handleNewClientAdminClose = () => {
        client.refetch();
        setNewClientAdminVisible(false);
    };

    const DeleteAdminsButton = (row: GridCellProps) => {
        const [deleteAdminVisible, setDeleteAdminVisible] = useState(false);

        const handleOnClose = () => {
            setDeleteAdminVisible(false);
            client.refetch();
        };

        return (
            <>
                <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={client.data?.Client?.isAdmin || false}>
                    <Button icon='delete' themeColor="error" onClick={() => setDeleteAdminVisible(true)} />
                </ShowOnRole>
                {deleteAdminVisible && <DeleteClientAdminDialog visible={deleteAdminVisible} clientId={clientId || ""} ids={[row.dataItem?.id]} onClose={handleOnClose} />}
            </>);
    };

    return (
        <>
            <TabStrip
                className={styles.AccessControl} style={style} data-testid="AccessControl"

                selected={selected}
                onSelect={handleSelect}
                tabPosition="top"
            >
                <TabStripTab title="Admin">
                    <StackLayout
                        className={styles.Projects}
                        style={{
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0
                        }}
                        data-testid="Projects"
                        orientation='vertical'
                        align={{ horizontal: "stretch", vertical: "top" }}>
                        <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                            <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => client.refetch()}>
                                Refresh
                            </Button>
                            <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={client.data?.Client?.isAdmin || false}>
                                <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setNewClientAdminVisible(true)}>
                                    New Admin
                                </Button>
                            </ShowOnRole>
                        </Toolbar>
                        <DataTable
                            style={{ flex: 1 }}
                            isLoading={client.loading}
                            data={client.data?.Client?.admins}
                            customProps={[{ id: "email", name: "Email" }]}
                            actionButtonsWidth='55px'
                            actionButtons={[
                                DeleteAdminsButton,
                            ]} />
                    </StackLayout>
                </TabStripTab>
            </TabStrip>
            <NewClientAdminDialog visible={newClientAdminVisible} onClose={handleNewClientAdminClose} clientId={clientId ?? ""} />
        </>
    );
};

export default AccessControl;