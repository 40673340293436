import React, { CSSProperties, ReactNode, useState } from 'react';
import styles from './ImageViewerButton.module.css';
import { Button } from '@progress/kendo-react-buttons';
import ImageViewerDialog from '../../dialogs/ImageViewerDialog/ImageViewerDialog';

type ImageViewerButtonProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string,
    link: string,
    title: string,
};

const ImageViewerButton = (props: ImageViewerButtonProps) => {
    const { style, className, title, link } = props;
    const [imageViewerVisible, setImageViewerVisible] = useState(false);

    return (
        <div className={`${styles.ImageViewerButton} ${className || ""}`} style={style} data-testid="ImageViewerButton">
            <Button icon="search" onClick={() => setImageViewerVisible(true)} />
            {imageViewerVisible && <ImageViewerDialog title={title} link={link} visible={imageViewerVisible} onClose={() => setImageViewerVisible(false)} />}
        </div>
    );
};

export default ImageViewerButton;