import React, { CSSProperties, ReactNode, useRef, useState } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './EditDefaultDialog.module.css';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import { useEditDefaultMutation, useEditProjectMutation, useGetClientsQuery, useGetLibrariesQuery } from '../../../generated/graphql';
import ValidatedAutocomplete from '../../main/ValidatedAutocomplete/ValidatedAutocomplete';

type EditDefaultDialogProps = {
    onClose: () => void,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const EditDefaultDialog = (props: EditDefaultDialogProps) => {
    const { style, visible, onClose } = props;
    const [editDefault, editDefaultResult] = useEditDefaultMutation();

    const [searchClients, setSearchClients] = useState("");
    const [searchText, setSearchText] = useState("");
    const submitButton = useRef<Button>(null);

    const clients = useGetClientsQuery({
        variables: {
            contains: searchClients
        }
    });

    const libraryData = useGetLibrariesQuery();

    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            const selected = libraryData.data?.LibraryList?.nodes?.filter(l => l?.name === dataItem["library"])[0];
            const selectedClient = clients.data?.ClientList?.nodes?.filter(l => l?.name === dataItem["client"])[0];
            editDefault({
                variables: {
                    clientId: selectedClient?.id,
                    libraryId: selected?.id
                }
            })
                .then(res => { onClose(); })
                .catch(err => { });
        }
    };
    const handleClientAutocompleteChanged = (event: any) => {
        setSearchClients(event.value);
        const client = clients.data?.ClientList?.nodes?.filter(l => l?.name === event.value)[0];
        if (client) libraryData.refetch({
            clientId: client.id,
            contains: searchText
        });
    };
    const handleAutocompleteChanged = (event: any) => {
        setSearchText(event.value);
    };
    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };
    const validator = (value: any) => {
        const library = getter("library")(value);
        const client = getter("client")(value);

        const selected = libraryData.data?.LibraryList?.nodes?.filter(l => l?.name === library)[0];
        const selectedClient = clients.data?.ClientList?.nodes?.filter(l => l?.name === client)[0];

        const libraryValidation = { ["library"]: "Field Cannot Empty!" };
        const validLibraryValidation = { ["library"]: "Please Select A Valid Library!" };

        const clientValidation = { ["client"]: "Field Cannot Empty!" };
        const validClientValidation = { ["client"]: "Please Select A Valid Client!" };

        if (library && selected && client && selectedClient)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!library && libraryValidation),
                ...((library && !selected) && validLibraryValidation),
                ...(!client && clientValidation),
                ...((client && !selectedClient) && validClientValidation),
            };
    };

    return (
        <DialogBase
            title="Edit Default"
            type="OkCancel"
            visible={visible}
            onClose={onClose}
            isLoading={editDefaultResult.loading}
            onOkClick={onCreateHandler}
            className={styles.EditDefaultDialog}
            style={style}
            data-testid="EditDefaultDialog">
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            value={searchClients}
                            name={"client"}
                            component={ValidatedAutocomplete}
                            data={clients.data?.ClientList?.nodes?.map(c => c?.name)}
                            onChange={handleClientAutocompleteChanged}
                            label={"Client"}
                        />
                        <Field
                            value={searchText}
                            name={"library"}
                            component={ValidatedAutocomplete}
                            data={libraryData.data?.LibraryList?.nodes?.map(l => l?.name)}
                            onChange={handleAutocompleteChanged}
                            label={"Library"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default EditDefaultDialog;