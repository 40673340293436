import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { useCurrentUser } from '../../../App.hooks';
import { UserRoleEnum, DisciplineEnum } from '../../../generated/graphql';
import styles from './ShowOnRole.module.css';

type ShowOnRoleProps = {
    isAdmin: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string,
    roles: UserRoleEnum[],
    discipline?: DisciplineEnum | null,
    userId?: string | null,
    hideUserId?: string | null,
};

const ShowOnRole = (props: ShowOnRoleProps) => {
    const { roles, isAdmin, children, userId, discipline, hideUserId } = props;
    const currentUser = useCurrentUser();
    const [visible, setvisible] = useState(false);
    useEffect(() => {
        if (isAdmin || roles.some(r => r === currentUser?.role) || userId === currentUser?.id || currentUser?.discipline === discipline || (hideUserId && !(hideUserId === currentUser?.id)))
            setvisible(true);
        else
            setvisible(false);
    }, [currentUser, discipline, hideUserId, roles, userId]);

    return (
        <>
            {visible && children}
        </>
    );

};

export default ShowOnRole;