import React, { CSSProperties, ReactNode, useRef } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './NewClassificationTypeDialog.module.css';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import { useCreateClassificationTypeMutation } from '../../../generated/graphql';
import ValidatedInput from '../../main/ValidatedInput/ValidatedInput';

type NewClassificationTypeDialogProps = {
    onClose: () => void,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const NewClassificationTypeDialog = (props: NewClassificationTypeDialogProps) => {
    const { style, visible, onClose } = props;
    const submitButton = useRef<Button>(null);
    const [createType, createTypeResult] = useCreateClassificationTypeMutation();

    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            createType({
                variables: {
                    classificationType: {
                        name: dataItem["name"],
                        description: dataItem["description"]
                    }
                }
            })
                .then(res => onClose())
                .catch(err => { });
        }
    };
    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };
    const validator = (value: any) => {
        const name = getter("name")(value);
        const nameValidation = { ["name"]: "Field Cannot Empty!" };

        const description = getter("description")(value);
        const descriptionValidation = { ["description"]: "Field Cannot Empty!" };

        if (name && description)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!name && nameValidation),
                ...(!description && descriptionValidation),
            };
    };

    return (
        <DialogBase
            title="New Classification Type"
            type="OkCancel"
            visible={visible}
            onClose={onClose}
            className={styles.NewClassificationTypeDialog}
            style={style}
            data-testid="NewClassificationTypeDialog"
            onOkClick={onCreateHandler}
            isLoading={createTypeResult.loading}>
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            name={"name"}
                            component={ValidatedInput}
                            label={"Name"}
                        />
                        <Field
                            name={"description"}
                            component={ValidatedInput}
                            label={"Description"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default NewClassificationTypeDialog;