import { QueryResult } from "@apollo/client";
import react, { createContext, CSSProperties, ReactNode, useState } from "react";
import { Client, Exact, GetClientQuery, InputMaybe } from "../../generated/graphql";

export const ClientContext = createContext<QueryResult<GetClientQuery, Exact<{
    id?: InputMaybe<string> | undefined;
}>> | undefined>(undefined);

type ClientProviderProps = {
    client: QueryResult<GetClientQuery, Exact<{
        id?: InputMaybe<string> | undefined;
    }>>,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

export const ClientProvider = (props: ClientProviderProps) => {
    const { children, client } = props;
    return (<>
        <ClientContext.Provider value={client}>
            {children}
        </ClientContext.Provider>
    </>);
};