import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import React, { CSSProperties, ReactNode, useContext, useRef, useState } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import { Client, Library, useCreateProjectMutation, useGetLibrariesQuery } from '../../../generated/graphql';
import { ClientContext } from '../../../views/Clients/Clients.Provider';
import ValidatedAutocomplete from '../../main/ValidatedAutocomplete/ValidatedAutocomplete';
import ValidatedInput from '../../main/ValidatedInput/ValidatedInput';
import ValidatedTextArea from '../../main/ValidatedTextArea/ValidatedTextArea';
import styles from './NewProjectDialog.module.css';

type NewProjectDialogProps = {
    onClose: () => void,
    clientId: string | undefined | null,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const NewProjectDialog = (props: NewProjectDialogProps) => {
    const { style, visible, clientId, onClose } = props;
    const [searchText, setSearchText] = useState("");
    const submitButton = useRef<Button>(null);
    const [createProject, createProjectResult] = useCreateProjectMutation();
    const libraryData = useGetLibrariesQuery({
        variables: {
            clientId: clientId,
            contains: searchText
        }
    });

    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            const selected = libraryData.data?.LibraryList?.nodes?.filter(l => l?.name === dataItem["library"])[0];
            createProject({
                variables: {
                    project: {
                        name: dataItem["name"],
                        description: dataItem["description"],
                        clientId: clientId,
                        libraryId: selected?.id || ""
                    }
                }
            })
                .then(res => { onClose(); })
                .catch(err => { });
        }
    };
    const handleAutocompleteChanged = (event: any) => {
        setSearchText(event.value);
    };
    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };
    const validator = (value: any) => {
        const name = getter("name")(value);
        const description = getter("description")(value);
        const library = getter("library")(value);

        const selected = libraryData.data?.LibraryList?.nodes?.filter(l => l?.name === library)[0];

        const nameValidation = { ["name"]: "Field Cannot Empty!" };
        const descriptionValidation = { ["description"]: "Field Cannot Empty!" };
        const libraryValidation = { ["library"]: "Field Cannot Empty!" };
        const validLibraryValidation = { ["library"]: "Please Select A Valid Library!" };

        if (name && description && library && selected)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!name && nameValidation),
                ...(!library && libraryValidation),
                ...((library && !selected) && validLibraryValidation),
                ...(!description && descriptionValidation),
            };
    };
    return (
        <DialogBase
            isLoading={createProjectResult.loading}
            title="New Project"
            type='OkCancel'
            onClose={onClose}
            onOkClick={onCreateHandler}
            visible={visible}
            className={styles.NewProjectDialog}
            style={style}
            data-testid="NewProjectDialog">
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            name={"name"}
                            component={ValidatedInput}
                            label={"Project Name"}
                        />
                        <Field
                            value={searchText}
                            name={"library"}
                            component={ValidatedAutocomplete}
                            data={libraryData.data?.LibraryList?.nodes?.map(l => l?.name)}
                            onChange={handleAutocompleteChanged}
                            label={"Library"}
                        />
                        <Field
                            name={"description"}
                            component={ValidatedTextArea}
                            label={"Description"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default NewProjectDialog;