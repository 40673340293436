import React, { CSSProperties, ReactNode, useRef } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './AddContentsTagsDialog.module.css';
import { Tag, useAssignContentsTagsMutation, useGetTagListQuery } from '../../../generated/graphql';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import ValidatedAutocomplete from '../../main/ValidatedAutocomplete/ValidatedAutocomplete';
import { Checkbox } from '@progress/kendo-react-inputs';
import ValidatedMultiSelect from '../../main/ValidatedMultiSelect/ValidatedMultiSelect';
import { GroupResult, groupBy } from '@progress/kendo-data-query';

type AddContentsTagsDialogProps = {
    onClose: () => void,
    libraryId: string,
    ids: string[],
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const AddContentsTagsDialog = (props: AddContentsTagsDialogProps) => {
    const { libraryId, ids, style, visible, onClose } = props;
    const submitButton = useRef<Button>(null);
    const [assignContentsTags, assignContentsTagsResult] = useAssignContentsTagsMutation();
    const tags = useGetTagListQuery({
        fetchPolicy: "no-cache",
        variables: {
            contains: "",
        }
    });

    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            const tagIds = (dataItem["tags"] as Tag[]).map(t => t.id || "");
            const overwrite = dataItem["overwrite"];
            assignContentsTags({
                variables: {
                    libraryId: libraryId,
                    ids: ids,
                    tagIds: tagIds,
                    overwrite: overwrite
                }
            })
                .then(res => { onClose(); })
                .catch(err => { });
        }
    };
    const handleTagChanged = (event: any) => {

    };

    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };

    const validator = (value: any) => {
        const tags = getter("tags")(value) as Tag[];
        const overwrite = getter("overwrite")(value);

        const tagValidation = { ["tags"]: "Field Cannot Empty!" };

        if (tags && tags.length > 0)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!(tags && tags.length > 0) && tagValidation),
            };
    };
    return (
        <DialogBase
            title="Assign Contents Tag"
            type="OkCancel"
            isLoading={assignContentsTagsResult.loading}
            visible={visible}
            onClose={onClose}
            onOkClick={onCreateHandler}
            className={styles.AddContentsTagsDialog}
            style={style}
            data-testid="AddContentsTagsDialog">
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            name={"tags"}
                            component={ValidatedMultiSelect}
                            textField="fullName"
                            data={tags.data?.TagList?.nodes?.sort((a, b) => (a?.fullName || "") < (b?.fullName || "") ? -1 : (a?.fullName || "") > (b?.fullName || "") ? 1 : 0)}
                            onChange={handleTagChanged}
                            label={"Tags"}
                        />
                        <div style={{ height: "8px" }} />
                        <Field
                            name={"overwrite"}
                            component={Checkbox}
                            label={"Overwrite"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default AddContentsTagsDialog;