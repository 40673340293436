import { Toolbar, Button } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { StackLayout, TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode, useState } from 'react';
import DataTable from '../../components/main/DataTable/DataTable';
import { useGetMembersQuery, useGetUsersQuery, User, UserRoleEnum } from '../../generated/graphql';
import styles from './AccessControl.module.css';
import ShowOnRole from '../../components/main/ShowOnRole/ShowOnRole';
import { ticksToDate } from '../../Utils';

type AccessControlProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const AccessControl = (props: AccessControlProps) => {
    const { style } = props;
    const [selected, setSelected] = React.useState<number>(0);
    const [searchMember, setSearchMember] = useState("");
    const members = useGetMembersQuery({
        variables: {
            contains: searchMember,
            role: UserRoleEnum.Admin
        }
    });
    const users = useGetUsersQuery({
        variables: {
            role: UserRoleEnum.Admin
        }
    });
    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };

    return (
        <StackLayout className={styles.AccessControl} style={style} data-testid="AccessControl"
            orientation='vertical'
            align={{ horizontal: "stretch", vertical: "top" }}>
            <Typography.h2 style={{ margin: "10px", flex: 0 }}>
                Access Control
            </Typography.h2>
            <TabStrip
                style={{ flex: 1 }}
                selected={selected}
                onSelect={handleSelect}
                tabPosition="top"
            >
                <TabStripTab title="Admin">
                    <StackLayout
                        className={styles.Projects}
                        style={{
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0
                        }}
                        data-testid="Projects"
                        orientation='vertical'
                        align={{ horizontal: "stretch", vertical: "top" }}>
                        <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                            <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => users.refetch()}>
                                Refresh
                            </Button>
                            <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={false}>
                                <Button icon="plus" fillMode="flat" themeColor={"primary"}>
                                    New Admin
                                </Button>
                            </ShowOnRole>
                        </Toolbar>
                        <DataTable
                            isLoading={users.loading}
                            data={users.data?.UserList?.nodes as User[]}
                            customProps={[
                                { id: "email", name: "Email" },
                                { id: "updatedTime", name: "Last Activity", customComponent: (row) => (<>{ticksToDate(row?.dataItem?.updatedTime)}</>) }
                            ]} />
                    </StackLayout>
                </TabStripTab>
                <TabStripTab title="Members">
                    <StackLayout
                        className={styles.Projects}
                        style={{
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0
                        }}
                        data-testid="Projects"
                        orientation='vertical'
                        align={{ horizontal: "stretch", vertical: "top" }}>
                        <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                            <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => members.refetch()}>
                                Refresh
                            </Button>
                        </Toolbar>
                        <DataTable
                            onSearchBoxChanged={(value) => setSearchMember(value)}
                            isLoading={users.loading}
                            data={members.data?.UserList?.nodes as User[]}
                            customProps={[
                                { id: "email", name: "Email" },
                                { id: "updatedTime", name: "Last Activity", customComponent: (row) => (<>{ticksToDate(row?.dataItem?.updatedTime)}</>) }
                            ]}
                            fetchMore={() => members.data?.UserList?.pageInfo?.hasNextPage && members.fetchMore({
                                variables: {
                                    contains: searchMember,
                                    after: members.data?.UserList?.pageInfo.endCursor
                                },
                                updateQuery: (previousResult, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) return previousResult;
                                    const previousContents = members.data?.UserList?.nodes || [];
                                    const newContents = fetchMoreResult.UserList?.nodes || [];
                                    return {
                                        ...previousResult,
                                        UserList: {
                                            nodes: [...previousContents, ...newContents],
                                            pageInfo: fetchMoreResult.UserList?.pageInfo as any
                                        }
                                    };
                                }
                            })} />
                    </StackLayout>
                </TabStripTab>
            </TabStrip>
        </StackLayout >
    );
};

export default AccessControl;