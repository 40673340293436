import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './DeleteClientHealthChecksDialog.module.css';
import { Typography } from '@progress/kendo-react-common';
import { useDeleteClientHealthChecksMutation } from '../../../generated/graphql';

type DeleteClientHealthChecksDialogProps = {
    onClose: () => void,
    healthCheckId: string,
    id: string,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const DeleteClientHealthChecksDialog = (props: DeleteClientHealthChecksDialogProps) => {
    const { id, healthCheckId, style, visible, onClose } = props;
    const [deleteClientHealthChecks, deleteClientHealthChecksResult] = useDeleteClientHealthChecksMutation({
        variables: {
            id: id,
            ids: [healthCheckId]
        }
    });

    const handleOnOkClick = () => {
        deleteClientHealthChecks()
            .then(() => onClose())
            .catch(err => onClose());
    };

    return (
        <DialogBase
            title="Delete Client Health Check"
            type="OkCancel"
            isLoading={deleteClientHealthChecksResult.loading}
            visible={visible}
            onClose={onClose}
            onOkClick={handleOnOkClick}
            className={styles.DeleteClientHealthChecksDialog}
            style={style}
            data-testid="DeleteClientHealthChecksDialog">
            <Typography.p>Are you sure you want to delete this Health Check?</Typography.p>
        </DialogBase>
    );
};

export default DeleteClientHealthChecksDialog;