import React, { CSSProperties, ReactNode, useRef, useState } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './MoveLibraryDialog.module.css';
import { Button } from '@progress/kendo-react-buttons';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import ValidatedAutocomplete from '../../main/ValidatedAutocomplete/ValidatedAutocomplete';
import { useGetClientsQuery, useMoveLibraryMutation } from '../../../generated/graphql';
import { getter } from '@progress/kendo-react-common';

type MoveLibraryDialogProps = {
    onClose: () => void,
    libraryId: string,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const MoveLibraryDialog = (props: MoveLibraryDialogProps) => {
    const { libraryId, style, visible, onClose } = props;
    const [moveLibrary, moveLibraryResult] = useMoveLibraryMutation();

    const [searchClients, setSearchClients] = useState("");
    const submitButton = useRef<Button>(null);

    const clients = useGetClientsQuery({
        variables: {
            contains: searchClients
        }
    });

    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            const selectedClient = clients.data?.ClientList?.nodes?.filter(l => l?.name === dataItem["client"])[0];
            moveLibrary({
                variables: {
                    id: libraryId,
                    clientId: selectedClient?.id,
                }
            })
                .then(res => { onClose(); })
                .catch(err => { });
        }
    };

    const handleClientAutocompleteChanged = (event: any) => {
        setSearchClients(event.value);
    };
    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };
    const validator = (value: any) => {
        const client = getter("client")(value);
        const selectedClient = clients.data?.ClientList?.nodes?.filter(c => c?.name === client)[0];

        const clientValidation = { ["client"]: "Field Cannot Empty!" };
        const validClientValidation = { ["client"]: "Please Select A Valid Client!" };

        if (client && selectedClient)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!client && clientValidation),
                ...((client && !selectedClient) && validClientValidation),
            };
    };

    return (
        <DialogBase
            title="Move Library"
            type="OkCancel"
            isLoading={moveLibraryResult.loading}
            visible={visible}
            onClose={onClose}
            onOkClick={onCreateHandler}
            className={styles.MoveLibraryDialog}
            style={style}
            data-testid="MoveLibraryDialog">
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            value={searchClients}
                            name={"client"}
                            component={ValidatedAutocomplete}
                            onChange={handleClientAutocompleteChanged}
                            data={clients.data?.ClientList?.nodes?.map(c => c?.name)}
                            label={"Client"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default MoveLibraryDialog;