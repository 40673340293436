import {
    ApolloClient,
    ApolloLink,
    DefaultOptions,
    InMemoryCache,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';
import TimeoutLink from "apollo-link-timeout";

export const apiUri: string = process.env.REACT_APP_API_URI || "";

export function getGraphQLClient(token: string) {
    const retryLink = new RetryLink({
        delay: {
            initial: 300,
            max: Infinity,
            jitter: true
        },
        attempts: {
            max: 5,
            retryIf: (error, _operation) => !!error
        }
    });

    const httpLink = createUploadLink({
        uri: `${apiUri}graphql`,
    });

    const timeoutLink = new TimeoutLink(10000);

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${token}`
            }
        };
    });

    const defaultOptions: DefaultOptions = {
        watchQuery: {
            fetchPolicy: 'cache-first',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'cache-first',
            errorPolicy: 'all',
        },
    };

    const client = new ApolloClient({
        link: ApolloLink.from([
            retryLink,
            timeoutLink,
            authLink,
            httpLink
        ]),
        cache: new InMemoryCache(),
        defaultOptions: defaultOptions,
    });

    return client;
}

