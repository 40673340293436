import { Button } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { StackLayout } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PageLayout from '../../../components/main/PageLayout/PageLayout';
import ProjectDrawer from '../../../components/main/ProjectDrawer/ProjectDrawer';
import Spinner from '../../../components/main/Spinner/Spinner';
import { useGetProjectQuery, Project as ProjectModel } from '../../../generated/graphql';
import { ProjectsProvider } from '../Projects.provider';
import ProjectsRouting, { routes } from '../Projects.routing';
import styles from './Project.module.css';

type ProjectProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const Project = (props: ProjectProps) => {
    const { style } = props;
    const { projectId } = useParams();
    const [path, setPath] = useState("");
    const location = useLocation();
    const items = routes;

    const project = useGetProjectQuery({
        variables: {
            id: projectId
        },
    });


    useEffect(() => {
        setPath(items.filter(item => item.path.split("/")[0] === location.pathname.split("/")[3])[0]?.name);
    }, [location.pathname]);

    return (<> {!project.data?.Project ? <Spinner size="large" /> :
        <PageLayout routes={routes} pageName="Project Management" name={project.data.Project.name} className={styles.Project} style={style} data-testid="Project">
            <ProjectDrawer style={style} data-testid="Project">
                <StackLayout orientation='vertical' style={{ height: "100%" }} align={{ horizontal: "stretch", vertical: "top" }} >
                    <ProjectsProvider project={project}>
                        <ProjectsRouting />
                    </ProjectsProvider>
                </StackLayout>
            </ProjectDrawer>
        </PageLayout>
    }</>
    );
};

export default Project;