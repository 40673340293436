import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Error, Label, Hint } from '@progress/kendo-react-labels';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const ValidatedDropdownList = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        data,
        optional,
        defaultValue,
        ...others
    } = fieldRenderProps;

    const showValidationMessage: string | false | null =
        touched && validationMessage;
    const showHint: boolean = !showValidationMessage && hint;
    const hintId: string = showHint ? `${id}_hint` : "";
    const errorId: string = showValidationMessage ? `${id}_error` : "";
    return (
        <FieldWrapper>
            <Label
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                optional={optional}
            >
                {label}
            </Label>
            <DropDownList
                valid={valid}
                id={id}
                data={data}
                defaultValue={defaultValue}
                disabled={disabled}
                ariaDescribedBy={`${hintId} ${errorId}`}
                {...others}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
            )}
        </FieldWrapper>
    );
};

export default ValidatedDropdownList;