import React, { CSSProperties, ReactNode, useState } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './EditHealthCheckSettingsDialog.module.css';
import SettingsEditor from '../../main/SettingsEditor/SettingsEditor';
import { useEditHealthCheckSettingsMutation } from '../../../generated/graphql';

type EditHealthCheckSettingsDialogProps = {
    onClose: () => void,
    id: string,
    settings: string,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const EditHealthCheckSettingsDialog = (props: EditHealthCheckSettingsDialogProps) => {
    const { id, style, visible, settings, onClose } = props;
    const [data, setData] = useState<any[]>([]);
    const [editHealthCheckSetting, editHealthCheckSettingResult] = useEditHealthCheckSettingsMutation();

    const handleOkClick = async () => {
        try {
            const result = JSON.stringify(data.map((d: any) => ({
                name: d.name || null,
                value: d.value || null,
                unit: d.unit || null,
            })));

            await editHealthCheckSetting({
                variables: {
                    healthCheckId: id,
                    settings: result
                }
            });

            onClose();
        }
        catch {

        }

    };

    return (
        <DialogBase
            title="Edit Health Check Settings"
            type="OkCancel"
            visible={visible}
            onClose={onClose}
            className={styles.EditHealthCheckSettingsDialog}
            style={style}
            data-testid="EditHealthCheckSettingsDialog"
            isLoading={editHealthCheckSettingResult.loading}
            onOkClick={handleOkClick}>
            <SettingsEditor
                visualizeOnly={false}
                editable
                style={{ height: "600px", width: "600px" }}
                settings={settings}
                onDataChange={(data) => setData(data)} />
        </DialogBase>
    );
};

export default EditHealthCheckSettingsDialog;