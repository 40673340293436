import React, { CSSProperties, ReactNode, useContext, useState } from 'react';
import styles from './Overview.module.css';
import { Toolbar, Button, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { TabStripSelectEventArguments, StackLayout, TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import Essentials from '../../../components/main/Essentials/Essentials';
import ShowOnRole from '../../../components/main/ShowOnRole/ShowOnRole';
import { UserRoleEnum, useGetClassificationListWithTypeQuery } from '../../../generated/graphql';
import { ClassificationTypeContext } from '../Classifications.Provider';
import DataTable from '../../../components/main/DataTable/DataTable';
import NewClassificationDialog from '../../../components/dialogs/NewClassificationDialog/NewClassificationDialog';

type OverviewProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
};

const Overview = (props: OverviewProps) => {
    const { style } = props;
    const [selected, setSelected] = React.useState<number>(0);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [searchEntities, setSearchEntities] = useState("");
    const [newEntityVisible, setNewEntityVisible] = useState(false);


    const classificationType = useContext(ClassificationTypeContext);

    const classifications = useGetClassificationListWithTypeQuery({
        variables: {
            typeId: classificationType?.data?.ClassificationType?.id,
            contains: searchEntities
        }
    });

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };

    const handleOnClose = () => {
        setNewEntityVisible(false);
        classifications.refetch();
        classificationType?.refetch();
    };

    return (
        <>

            <StackLayout orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}
                className={styles.Overview}
                style={style}
                data-testid="Overview">
                <Essentials
                    isAdmin={false}
                    rows={[
                        { title: "Name", value: classificationType?.data?.ClassificationType?.name || "" },
                        { title: "Description", value: classificationType?.data?.ClassificationType?.description || "" }
                    ]} />
                <TabStrip
                    style={{ flex: 1, position: "relative" }}
                    selected={selected}
                    onSelect={handleSelect}
                    tabPosition="top"
                >
                    <TabStripTab title="Entities">
                        <StackLayout
                            className={styles.Projects}
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0
                            }}
                            data-testid="Projects"
                            orientation='vertical'
                            align={{ horizontal: "stretch", vertical: "top" }}>
                            <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                                <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => classifications.refetch()}>
                                    Refresh
                                </Button>
                                <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={false}>
                                    <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setNewEntityVisible(true)}>
                                        New Entity
                                    </Button>
                                    {selectedIds.length > 0 && <>
                                        <ToolbarSeparator />
                                        <Button icon="delete" fillMode="flat" themeColor={"error"} >
                                            Delete
                                        </Button>
                                    </>}
                                </ShowOnRole>
                            </Toolbar>
                            <DataTable
                                showDefaultColumns
                                onSearchBoxChanged={value => setSearchEntities(value)}
                                onSelected={(value) => setSelectedIds(value)}
                                link="../model"
                                style={{ flex: 1 }}
                                isLoading={false}
                                data={classifications.data?.ClassificationList?.nodes}
                                customProps={[
                                ]}
                                fetchMore={() => classifications.data?.ClassificationList?.pageInfo?.hasNextPage && classifications.fetchMore({
                                    variables: {
                                        contains: searchEntities,
                                        after: classifications.data?.ClassificationList?.pageInfo.endCursor
                                    },
                                    updateQuery: (previousResult, { fetchMoreResult }) => {
                                        if (!fetchMoreResult) return previousResult;
                                        const previousContents = classifications.data?.ClassificationList?.nodes || [];
                                        const newContents = fetchMoreResult.ClassificationList?.nodes || [];
                                        return {
                                            ...previousResult,
                                            ClassificationList: {
                                                nodes: [...previousContents, ...newContents],
                                                pageInfo: fetchMoreResult.ClassificationList?.pageInfo as any
                                            }
                                        };
                                    }
                                })}
                            />
                        </StackLayout>
                    </TabStripTab>
                </TabStrip>
            </StackLayout>
            {newEntityVisible && <NewClassificationDialog visible={newEntityVisible} onClose={handleOnClose} typeId={classificationType?.data?.ClassificationType?.id || ""} />}
        </>
    );
};

export default Overview;