import { Chart, ChartTitle, ChartCategoryAxis, ChartCategoryAxisItem, ChartCategoryAxisTitle, ChartSeries, ChartSeriesItem, ChartLegend } from '@progress/kendo-react-charts';
import React, { CSSProperties, ReactNode } from 'react';
import { groupBy } from '../../../Utils';
import styles from './BarChart.module.css';
import { Typography } from '@progress/kendo-react-common';
import { StackLayout } from '@progress/kendo-react-layout';

export type Bar = {
    data: {
        category: string,
        count: number,
    }[];
    label: string,
    color?: string,
    stack?: boolean,
};
type BarChartProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
    title?: string;
    data: Bar[];

};

const BarChart = (props: BarChartProps) => {
    const { style, className, data, title } = props;

    return (
        <div className={`${styles.BarChart} ${className || ""}`} style={style} data-testid="BarChart">
            <StackLayout align={{ horizontal: "center" }} orientation='vertical' style={{ height: `${data[0].data.length * 40 + 300}px`, }}  >
                <Typography.h5 style={{ alignSelf: "center", margin: 10, flex: 0 }}>{title}</Typography.h5>
                <Chart style={{ width: "100%" }}>
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem categories={data[0].data.map(d => d.category)}>
                        </ChartCategoryAxisItem>
                    </ChartCategoryAxis>
                    <ChartLegend position="top" orientation="horizontal" />
                    <ChartSeries>
                        {data.map(d => (
                            <ChartSeriesItem
                                stack={d.stack || false}
                                tooltip={{ visible: true }}
                                type="bar"
                                color={d.color}
                                data={d.data.map(d => d.count)}
                                name={d.label} />
                        ))}
                    </ChartSeries>
                </Chart>
            </StackLayout>

        </div>
    );
};

export default BarChart;