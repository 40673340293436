import React, { CSSProperties, ReactNode, useState } from 'react';
import styles from './Essentials.module.css';
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent, GridLayout, GridLayoutItem, GridLayoutRowProps, StackLayout } from '@progress/kendo-react-layout';
import { Reveal } from '@progress/kendo-react-animation';
import { Link } from 'react-router-dom';
import { HealthCheck, Tag, UserRoleEnum } from '../../../generated/graphql';
import { Button, Chip } from '@progress/kendo-react-buttons';
import ShowOnRole from '../ShowOnRole/ShowOnRole';
import { newGuid } from '../../../Utils';

export type EssentialRow = {
    title: string,
    value: string;
    link?: string;
};

type EssentialsProps = {
    isAdmin: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
    tags?: Tag[];
    healthChecks?: HealthCheck[];
    preview?: string;
    rows: EssentialRow[];
    onRemoveTag?: (tagId: string) => void;
    onRemoveHealthCheck?: (healthCheckId: string) => void;
};

const Essentials = (props: EssentialsProps) => {
    const { preview, tags, healthChecks, rows, style, className, isAdmin, onRemoveTag, onRemoveHealthCheck } = props;
    const [expanded, setExpanded] = useState(true);

    return (
        <div className={`${styles.Essentials} ${className || ""}`} style={style} data-testid="Essentials">
            <ExpansionPanel
                title="Essentials"
                style={{ borderWidth: "1px 0" }}
                expanded={expanded}
                tabIndex={0}
                onAction={(event: ExpansionPanelActionEvent) => {
                    setExpanded(!expanded);
                }}
            >
                <Reveal>
                    {expanded && (
                        <ExpansionPanelContent>
                            <GridLayout cols={[
                                { width: "auto" },
                                { width: "auto" },
                            ]}>
                                <GridLayoutItem col={1}>
                                    <StackLayout orientation='horizontal' align={{ horizontal: "start" }}>
                                        <GridLayout
                                            align={{ vertical: "middle" }}
                                            rows={rows.map<GridLayoutRowProps>(r => ({ height: "auto" }))}
                                            gap={{ cols: 5, rows: 5 }}
                                            cols={[
                                                { width: "auto" },
                                                { width: "auto" },
                                                { width: "auto" },
                                            ]}>
                                            {
                                                rows.map((r, i) => (
                                                    <>
                                                        <GridLayoutItem key={newGuid()} col={1} row={i + 1}>
                                                            <span style={{ fontWeight: "bold", opacity: 0.8 }}>{r.title}</span>
                                                        </GridLayoutItem>
                                                        <GridLayoutItem key={newGuid()} col={2} row={i + 1}>
                                                            <span style={{ fontWeight: "bold", opacity: 0.8 }}>:</span>
                                                        </GridLayoutItem>
                                                        <GridLayoutItem key={newGuid()} col={3} row={i + 1} style={{ marginLeft: 15 }}>
                                                            {r.link ? <Link to={r.link}>{r.value}</Link> : <span>{r.value}</span>}
                                                        </GridLayoutItem>
                                                    </>
                                                ))
                                            }
                                            {
                                                tags &&
                                                <>
                                                    <GridLayoutItem col={1} row={0}>
                                                        <span style={{ fontWeight: "bold", opacity: 0.8 }}>Tags</span>
                                                    </GridLayoutItem>
                                                    <GridLayoutItem col={2} row={0}>
                                                        <span style={{ fontWeight: "bold", opacity: 0.8 }}>:</span>
                                                    </GridLayoutItem>
                                                    <GridLayoutItem col={3} row={0} style={{ marginLeft: 15 }} >
                                                        {tags.filter(t => t).map(t => (
                                                            <Chip key={newGuid()} style={{ padding: "5px", marginRight: 5 }} fillMode="outline">
                                                                <StackLayout orientation='horizontal' align={{ vertical: "middle" }} style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                                                                    <span style={{ fontWeight: "bold", opacity: 0.8 }}>{t.type}</span>
                                                                    <span style={{ fontWeight: "bold", opacity: 0.8, marginRight: 5 }}>:</span>
                                                                    <span>{t.name}</span>
                                                                    <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={isAdmin}>
                                                                        <Button
                                                                            icon='close'
                                                                            fillMode="flat"
                                                                            style={{ borderRadius: 100, padding: 1, marginLeft: 2, marginTop: -5, marginBottom: -5, marginRight: -5 }}
                                                                            onClick={() => onRemoveTag && onRemoveTag(t?.id || "")} />
                                                                    </ShowOnRole>
                                                                </StackLayout>
                                                            </Chip>))}
                                                    </GridLayoutItem>
                                                </>
                                            }{
                                                healthChecks &&
                                                <>
                                                    <GridLayoutItem col={1} row={0}>
                                                        <span style={{ fontWeight: "bold", opacity: 0.8 }}>Health Checks</span>
                                                    </GridLayoutItem>
                                                    <GridLayoutItem col={2} row={0}>
                                                        <span style={{ fontWeight: "bold", opacity: 0.8 }}>:</span>
                                                    </GridLayoutItem>
                                                    <GridLayoutItem col={3} row={0} style={{ marginLeft: 15 }} >
                                                        {healthChecks.filter(t => t).map(t => (
                                                            <Chip key={newGuid()} style={{ padding: "5px", marginRight: 5 }} fillMode="outline">
                                                                <StackLayout orientation='horizontal' align={{ vertical: "middle" }} style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                                                                    <span>{t.name}</span>
                                                                    <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={isAdmin}>
                                                                        <Button
                                                                            icon='close'
                                                                            fillMode="flat"
                                                                            style={{ borderRadius: 100, padding: 1, marginLeft: 2, marginTop: -5, marginBottom: -5, marginRight: -5 }}
                                                                            onClick={() => onRemoveHealthCheck && onRemoveHealthCheck(t?.id || "")} />
                                                                    </ShowOnRole>
                                                                </StackLayout>
                                                            </Chip>))}
                                                    </GridLayoutItem>
                                                </>
                                            }
                                        </GridLayout>
                                    </StackLayout>
                                </GridLayoutItem>
                                {preview && <GridLayoutItem col={2}>
                                    <StackLayout orientation='horizontal' align={{ horizontal: "end" }}>
                                        <img height="160px" style={{ marginRight: "50px" }} src={preview || ""} />
                                    </StackLayout>
                                </GridLayoutItem>}
                            </GridLayout>

                        </ExpansionPanelContent>
                    )}
                </Reveal>
            </ExpansionPanel>
        </div >
    );
};

export default Essentials;