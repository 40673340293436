import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { FormElement, Field, Form, FormRenderProps } from '@progress/kendo-react-form';
import React, { CSSProperties, ReactNode, useRef } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import { useCreateClientMutation } from '../../../generated/graphql';
import ValidatedInput from '../../main/ValidatedInput/ValidatedInput';
import ValidatedTextArea from '../../main/ValidatedTextArea/ValidatedTextArea';
import styles from './NewClientDialog.module.css';

type NewClientDialogProps = {
    onClose: () => void,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const NewClientDialog = (props: NewClientDialogProps) => {
    const { style, visible, onClose } = props;
    const submitButton = useRef<Button>(null);
    const [createClient, createClientResult] = useCreateClientMutation();

    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            createClient({
                variables: {
                    client: {
                        name: dataItem["name"],
                        longName: dataItem["longName"],
                        description: dataItem["description"]
                    }
                }
            })
                .then(res => onClose())
                .catch(err => { });
        }
    };
    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };
    const validator = (value: any) => {
        const name = getter("name")(value);
        const longName = getter("longName")(value);
        const description = getter("description")(value);

        const nameValidation = { ["name"]: "Field Cannot Empty!" };
        const longNameValidation = { ["longName"]: "Field Cannot Empty!" };
        const descriptionValidation = { ["description"]: "Field Cannot Empty!" };

        if (name && longName && description)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!name && nameValidation),
                ...(!longName && longNameValidation),
                ...(!description && descriptionValidation),
            };
    };
    return (
        <DialogBase
            isLoading={createClientResult.loading}
            title="New Client"
            type='OkCancel'
            onClose={onClose}
            onOkClick={onCreateHandler}
            visible={visible}
            className={styles.NewClientDialog}
            style={style}
            data-testid="NewClientDialog">
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            name={"name"}
                            component={ValidatedInput}
                            label={"Client Name"}
                        />
                        <Field
                            name={"longName"}
                            component={ValidatedInput}
                            label={"Long name"} />
                        <Field
                            name={"description"}
                            component={ValidatedTextArea}
                            label={"Description"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default NewClientDialog;