import React, { CSSProperties, ReactNode } from 'react';
import styles from './Spinner.module.css';
import { Loader } from "@progress/kendo-react-indicators";

type SpinnerProps = {
    size?: "small" | "medium" | "large";
    style?: CSSProperties | undefined,
};

const Spinner = (props: SpinnerProps) => {
    const { style, size } = props;

    return (
        <div className={styles.Spinner} style={style} data-testid="Spinner">
            <Loader style={{ top: "50%", left: "50%", transform: "translateX(-50%) translateY(-50%)" }} size={size || "medium"} type="infinite-spinner" />
        </div>
    );
};

export default Spinner;