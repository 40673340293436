import React, { useState } from 'react';
import { MultiSelect, MultiSelectChangeEvent, MultiSelectFocusEvent, MultiSelectBlurEvent } from '@progress/kendo-react-dropdowns';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Error, Label, Hint } from '@progress/kendo-react-labels';

const ValidatedMultiSelect = (fieldRenderProps: FieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        wrapperStyle,
        data,
        onChange,
        ...others
    } = fieldRenderProps;
    const [focused, setFocused] = useState(false);
    const onFocus = (event: MultiSelectFocusEvent) => setFocused(true);
    const onBlur = (event: MultiSelectBlurEvent) => setFocused(false);
    const onChangehandler = (event: MultiSelectChangeEvent) => {
        onChange(event);
        if (data && data.includes(event.value)) setFocused(false);
        else setFocused(true);
    };

    const editorRef = React.useRef<any>(null);

    const showValidationMessage: string | false | null =
        touched && validationMessage;
    const showHint: boolean = !showValidationMessage && hint;
    const hintId: string = showHint ? `${id}_hint` : "";
    const errorId: string = showValidationMessage ? `${id}_error` : "";
    const labelId: string = label ? `${id}_label` : "";

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
            >
                {label}
            </Label>
            <MultiSelect
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                data={data}
                opened={focused}
                disabled={disabled}
                {...others}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChangehandler}
            />
            {showHint && <Hint id={hintId}>{hint}</Hint>}
            {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
        </FieldWrapper>
    );
};

export default ValidatedMultiSelect;