import { getter } from '@progress/kendo-react-common';
import { Grid, GridColumn, GridCellProps, getSelectedState, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent, GridEvent, GridExpandChangeEvent, GridDetailRowProps } from '@progress/kendo-react-grid';
import { StackLayout } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { newGuid, ticksToDate } from '../../../Utils';
import SearchBox from '../SearchBox/SearchBox';
import Spinner from '../Spinner/Spinner';
import styles from './DataTable.module.css';
import { Tag } from '../../../generated/graphql';
import { Chip } from '@progress/kendo-react-buttons';

const DATA_ITEM_KEY: string = "id";
const SELECTED_FIELD: string = "selected";
const EXPAND_FIELD: string = "expanded";
const idGetter = getter(DATA_ITEM_KEY);

type CustomComponent = {
    id: string,
    name: string,
    width?: string,
    customComponent?: (row: GridCellProps) => JSX.Element,
};

type DataTableProps = {
    isLoading: boolean,
    noFlex?: boolean,
    detailComponent?: (props: GridDetailRowProps) => JSX.Element,
    onFilterChanged?: (tags: Tag[]) => void,
    onSearchBoxChanged?: (value: string) => void,
    onSelected?: (ids: string[]) => void,
    fetchMore?: () => void,
    showDefaultColumns?: boolean,
    customProps?: CustomComponent[],
    actionButtons?: ((value: GridCellProps) => JSX.Element)[],
    actionButtonsWidth?: string,
    showDescriptionColumn?: boolean,
    hideNameColumn?: boolean,
    showTagsColumn?: boolean,
    link?: string,
    data: any[] | undefined | null,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const DataTable = (props: DataTableProps) => {
    const { style, isLoading, data, showTagsColumn, link, customProps, noFlex,
        showDefaultColumns,
        showDescriptionColumn,
        hideNameColumn,
        actionButtons,
        actionButtonsWidth,
        detailComponent,
        onFilterChanged,
        onSearchBoxChanged,
        onSelected,
        fetchMore } = props;
    const [selectedState, setSelectedState] = useState<{ [id: string]: boolean | number[]; }>({});
    const [expandedState, setExpandedState] = useState<{ [id: string]: boolean | number[]; }>({});

    const onSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
            onSelected && onSelected(Object.keys(newSelectedState).filter(key => newSelectedState[key]));
        },
        [selectedState]
    );

    const onHeaderSelectionChange = useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            const checkboxElement: any = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState: any = {};

            event.dataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = checked;
            });
            setSelectedState(newSelectedState);
            onSelected && onSelected(Object.keys(newSelectedState).filter(key => newSelectedState[key]));
        },
        []
    );

    const onExpandChange = useCallback(
        (event: GridExpandChangeEvent) => {
            expandedState[idGetter(event.dataItem)] = event.value;
            setExpandedState({ ...expandedState });
        },
        [expandedState]
    );

    const handleSearchChanged = (value: string) => {
        onSearchBoxChanged && onSearchBoxChanged(value);
    };

    const scrollHandler = (event: GridEvent) => {
        const e = event.nativeEvent;
        if (
            e.target.scrollTop + 10 >=
            e.target.scrollHeight - e.target.clientHeight
        ) {
            fetchMore && fetchMore();
        }
    };

    return (
        <StackLayout className={styles.DataTable} style={style} data-testid="DataTable" orientation='vertical' align={{ horizontal: "stretch", vertical: "top" }}>
            {onSearchBoxChanged && <SearchBox onChanged={handleSearchChanged} onTagsChanged={onFilterChanged} />}
            <div style={{ flex: 1, position: "relative" }}>
                {isLoading ? <Spinner size='large' /> :
                    <Grid data={data?.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                        [EXPAND_FIELD]: expandedState[idGetter(item)]
                    }))}

                        resizable
                        onScroll={scrollHandler}
                        fixedScroll={true}
                        style={!noFlex ? { height: "100%", position: "absolute" } : {}}
                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        expandField={EXPAND_FIELD}
                        selectable={{
                            enabled: true,
                            drag: false,
                            cell: false,
                            mode: "multiple",
                        }}
                        detail={detailComponent}
                        onSelectionChange={onSelectionChange}
                        onHeaderSelectionChange={onHeaderSelectionChange}
                        onExpandChange={onExpandChange}>
                        <GridColumn
                            resizable={false}
                            field={SELECTED_FIELD}
                            width="41px"
                            headerSelectionValue={
                                data?.findIndex((item) => !selectedState[idGetter(item)]) === -1
                            }
                        />
                        {!hideNameColumn && <GridColumn field="name" title="Name" cell={(props: GridCellProps) => (
                            <td>
                                {link ? <Link to={`${link}/${props.dataItem.id}`}>
                                    {props.dataItem.name}
                                </Link> :
                                    <>{props.dataItem.name}</>}

                            </td>
                        )} />}
                        {customProps && customProps.map(prop =>
                            prop.customComponent ?
                                (<GridColumn key={newGuid()} width={prop.width} field={prop.id} title={prop.name} filterable={false} cell={(row: GridCellProps) => (
                                    <td>
                                        {prop.customComponent ? prop.customComponent(row) : <></>}
                                    </td>
                                )} />) :
                                (<GridColumn key={newGuid()} width={prop.width} field={prop.id} title={prop.name} filterable={false} />)

                        )}
                        {showDescriptionColumn && <GridColumn field="description" title="Description" />}
                        {showDefaultColumns && <GridColumn width="100px" field="createdBy" title="Created By" cell={(props: GridCellProps) => (
                            <td>
                                {props.dataItem.createdBy?.name}
                            </td>
                        )} />}
                        {showDefaultColumns && <GridColumn width="120px" field="createdTime" title="Created Date" cell={(props: GridCellProps) => (
                            <td>
                                {props.dataItem.createdTime ? ticksToDate(props.dataItem.createdTime) : ""}
                            </td>
                        )} />}
                        {
                            showTagsColumn && <GridColumn key={newGuid()} title={"Tags"} width="250px" filterable={false} cell={(props: GridCellProps) => (
                                <td>
                                    <StackLayout orientation='vertical' align={{ horizontal: "start" }}>
                                        {props.dataItem.tags?.filter((t: any) => t).map((t: Tag) => (
                                            <Chip key={newGuid()} style={{ whiteSpace: "nowrap", overflow: "hidden", marginBottom: 5 }} fillMode="outline" themeColor="base">
                                                <span style={{ fontWeight: "bold", opacity: 0.8 }}>{t?.type}</span>
                                                <span style={{ fontWeight: "bold", opacity: 0.8, marginRight: 5 }}>:</span>
                                                <span>{t?.name}</span>
                                            </Chip>))}
                                    </StackLayout>
                                </td>
                            )} />
                        }
                        {
                            actionButtons && <GridColumn key={newGuid()} width={actionButtonsWidth ?? "auto"} title={""} filterable={false} cell={(value: GridCellProps) => (
                                <td>
                                    <StackLayout orientation='horizontal' align={{ horizontal: "start" }}>
                                        {actionButtons.map(button => <div key={newGuid()} style={{ marginRight: "5px" }}>{button(value)}</div>)}
                                    </StackLayout>
                                </td>
                            )} />
                        }
                    </Grid>}
            </div>
        </StackLayout>

    );
};

export default DataTable;