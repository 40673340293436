import React, { CSSProperties, ReactNode, useRef, useState } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './RequestAccessProjectDialog.module.css';
import { useGetAllProjectsWithoutPermissionQuery, useSetProjectAccessMutation } from '../../../generated/graphql';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import ValidatedAutocomplete from '../../main/ValidatedAutocomplete/ValidatedAutocomplete';

type RequestAccessProjectDialogProps = {
    onClose: () => void,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const RequestAccessProjectDialog = (props: RequestAccessProjectDialogProps) => {
    const { style, visible, onClose } = props;
    const [setProjectAccess, setProjectAccessResult] = useSetProjectAccessMutation();
    const [searchText, setSearchText] = useState(undefined);
    const submitButton = useRef<Button>(null);

    const projects = useGetAllProjectsWithoutPermissionQuery({
        variables: {
            contains: searchText
        }
    });

    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            const selected = projects.data?.AllProjects?.nodes?.filter(l => l?.name === dataItem["project"])[0];
            setProjectAccess({
                variables: {
                    projectId: selected?.id,
                }
            })
                .then(res => { onClose(); })
                .catch(err => { });
        }
    };
    const handleAutocompleteChanged = (event: any) => {
        setSearchText(event.value);
    };
    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };
    const validator = (value: any) => {
        const project = getter("project")(value);

        const selected = projects.data?.AllProjects?.nodes?.filter(l => l?.name === project)[0];

        const projectValidation = { ["project"]: "Field Cannot Empty!" };
        const validprojectValidation = { ["project"]: "Please Select A Valid Project!" };

        if (project && selected)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!project && projectValidation),
                ...((project && !selected) && validprojectValidation),
            };
    };

    return (
        <DialogBase
            title="Request Access"
            type="OkCancel"
            isLoading={setProjectAccessResult.loading}
            visible={visible}
            onClose={onClose}
            onOkClick={onCreateHandler}
            className={styles.RequestAccessProjectDialog}
            style={style}
            data-testid="RequestAccessProjectDialog">
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            value={searchText}
                            name={"project"}
                            component={ValidatedAutocomplete}
                            data={projects.data?.AllProjects?.nodes?.map(p => p?.name)}
                            onChange={handleAutocompleteChanged}
                            label={"Project"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default RequestAccessProjectDialog;