import React, { CSSProperties, ReactNode, useRef } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './AddContentsGroupsDialog.module.css';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Group, useAssignContentsGroupsMutation, useGetGroupListQuery } from '../../../generated/graphql';
import ValidatedMultiSelect from '../../main/ValidatedMultiSelect/ValidatedMultiSelect';

type AddContentsGroupsDialogProps = {
    onClose: () => void,
    libraryId: string,
    ids: string[],
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const AddContentsGroupsDialog = (props: AddContentsGroupsDialogProps) => {
    const { libraryId, ids, style, visible, onClose } = props;
    const submitButton = useRef<Button>(null);
    const [assignContentsGroups, assignContentsGroupsResult] = useAssignContentsGroupsMutation();
    const groups = useGetGroupListQuery({
        fetchPolicy: "no-cache",
        variables: {
            contains: "",
        }
    });

    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            const groupIds = (dataItem["groups"] as Group[]).map(t => t.id || "");
            const overwrite = dataItem["overwrite"];
            assignContentsGroups({
                variables: {
                    libraryId: libraryId,
                    ids: ids,
                    groupIds: groupIds,
                    overwrite: overwrite
                }
            })
                .then(res => { onClose(); })
                .catch(err => { });
        }
    };
    const handleGroupChanged = (event: any) => {

    };

    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };

    const validator = (value: any) => {
        const groups = getter("groups")(value) as Group[];
        const overwrite = getter("overwrite")(value);

        const groupValidation = { ["groups"]: "Field Cannot Empty!" };

        if (groups && groups.length > 0)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!(groups && groups.length > 0) && groupValidation),
            };
    };
    return (
        <DialogBase
            title="Assign Contents Group"
            type="OkCancel"
            isLoading={assignContentsGroupsResult.loading}
            visible={visible}
            onClose={onClose}
            onOkClick={onCreateHandler}
            className={styles.AddContentsGroupsDialog}
            style={style}
            data-testid="AddContentsGroupsDialog">
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            name={"groups"}
                            component={ValidatedMultiSelect}
                            textField="name"
                            data={groups.data?.GroupList?.nodes}
                            onChange={handleGroupChanged}
                            label={"Groups"}
                        />
                        <div style={{ height: "8px" }} />
                        <Field
                            name={"overwrite"}
                            component={Checkbox}
                            label={"Overwrite"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default AddContentsGroupsDialog;