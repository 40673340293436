import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import styles from './MainDrawer.module.css';
import {
    Breadcrumb,
    DataModel,
    Drawer,
    DrawerContent,
    DrawerItem,
    DrawerItemProps,
    DrawerSelectEvent,
    StackLayout,
} from "@progress/kendo-react-layout";
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../../App.routing';
import { groupBy } from '../../../Utils';
import { RouteType } from '../../../App.enums';
import Header from '../Header/Header';
import { useCurrentUser } from '../../../App.hooks';
import { UserRoleEnum } from '../../../generated/graphql';
import Footer from '../Footer/Footer';

const CustomItem = (props: DrawerItemProps) => {
    const { expanded } = props;
    return <> {
        props.text === "collapse" ?
            <DrawerItem {...props} style={{ width: "100%", justifyContent: (expanded ? "end" : "start") }}>
                <span className={"k-icon " + (expanded ? "k-i-arrow-chevron-left" : "k-i-arrow-chevron-right")} />
            </DrawerItem> :
            <DrawerItem {...props}>
                <span className={"k-icon " + props.icon} />
                {expanded ? <div className="item-descr-wrap">
                    <div>{props.text}</div>
                </div> : <></>}
            </DrawerItem>
    }
    </>;
};

type MainDrawerProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const MainDrawer = (props: MainDrawerProps) => {
    const { style, children } = props;
    const [expanded, setExpanded] = useState(true);
    const [breadcums, setBreadcums] = useState<DataModel[]>([]);
    const currentUser = useCurrentUser();
    const navigate = useNavigate();
    const location = useLocation();
    const items = routes.map(route => ({
        text: route.name,
        icon: route.icon,
        route: route.path,
        type: route.type,
        role: route.role
    } as DrawerItemProps));
    const collapseButton: DrawerItemProps[] = [
        {
            text: "collapse",
            icon: "",
            route: "",
            type: RouteType.None
        },
    ];

    const onSelect = (e: DrawerSelectEvent) => {
        if (e.itemTarget.props.text === "collapse")
            setExpanded(!expanded);
        else {
            navigate(e.itemTarget.props.route);
        }
    };

    const setSelectedItem = (pathName: string) => {
        const currentPath: DrawerItemProps | undefined = items.find((item) => pathName.toLowerCase().split("/")[1] === item.route.toLowerCase().split("/")[1]);
        if (currentPath?.text) {
            return currentPath.text;
        }
    };
    const selected = setSelectedItem(location.pathname);

    useEffect(() => {
        const paths = location.pathname.split("/");
        setBreadcums(paths.filter(p => p).map(p => ({
            id: p,
            text: p[0].toUpperCase() + p.slice(1)
        })));

    }, [location.pathname]);

    return (
        <div className={styles.MainDrawer} style={style} data-testid="MainDrawer">
            <StackLayout style={{ height: "100%", width: "100%" }} orientation='vertical' align={{ vertical: "top" }}>
                <Header style={{ zIndex: 1000 }} />
                <Drawer
                    style={{ flex: 1 }}
                    expanded={expanded}
                    position={"start"}
                    mode={"push"}
                    mini={true}
                    items={
                        groupBy(collapseButton.concat(items.filter(item => item.type !== RouteType.None).filter(item => {
                            if (currentUser?.role === UserRoleEnum.Admin)
                                return true;
                            else {
                                if (item.role === UserRoleEnum.None)
                                    return true;
                                else
                                    return false;
                            }
                        })), item => item.text)
                            .map(g => {
                                g.push({ separator: true });
                                return g;
                            })
                            .flat()
                            .map(item => ({
                                ...item,
                                selected: item.text === selected,
                                expanded: expanded
                            }))}
                    item={CustomItem}
                    onSelect={onSelect}
                >
                    <DrawerContent style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
                        <Breadcrumb style={{ borderBottomWidth: "1px", padding: "5px" }}
                            data={breadcums} />
                        {children}
                    </DrawerContent>
                </Drawer>
                <Footer />
            </StackLayout>

        </div >
    );
};

export default MainDrawer;