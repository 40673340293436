import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import React, { CSSProperties, ReactNode, useRef } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import { useSetContentTypeMutation } from '../../../generated/graphql';
import ValidatedInput from '../../main/ValidatedInput/ValidatedInput';
import ValidatedTextArea from '../../main/ValidatedTextArea/ValidatedTextArea';
import styles from './NewContentTypeDialog.module.css';

type NewContentTypeDialogProps = {
    onClose: () => void,
    contentId: string,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const NewContentTypeDialog = (props: NewContentTypeDialogProps) => {
    const { style, visible, contentId, onClose } = props;
    const submitButton = useRef<Button>(null);
    const [createType, createTypeResult] = useSetContentTypeMutation();

    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            createType({
                variables: {
                    id: contentId,
                    type: {
                        name: dataItem['name'],
                        description: dataItem['description'],
                        settings: dataItem['settings'],
                    }
                }
            })
                .then(res => { onClose(); })
                .catch(err => { });
        }
    };
    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };
    const validator = (value: any) => {
        const name = getter("name")(value);
        const description = getter("description")(value);
        const settings = getter("settings")(value);

        const nameValidation = { ["name"]: "Field Cannot Empty!" };
        const descriptionValidation = { ["description"]: "Field Cannot Empty!" };
        const settingsValidation = { ["settings"]: "Field Cannot Empty!" };

        if (name && settings && description)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!name && nameValidation),
                ...(!description && descriptionValidation),
                ...(!settings && settingsValidation),
            };
    };

    return (
        <DialogBase
            title="New Type"
            type='OkCancel'
            onClose={onClose}
            isLoading={createTypeResult.loading}
            onOkClick={onCreateHandler}
            visible={visible}
            className={styles.NewContentTypeDialog}
            style={style}
            data-testid="NewContentTypeDialog">
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            name={"name"}
                            component={ValidatedInput}
                            label={"Type Name"}
                        />
                        <Field
                            name={"settings"}
                            component={ValidatedTextArea}
                            label={"Settings"}
                        />
                        <Field
                            name={"description"}
                            component={ValidatedTextArea}
                            label={"Description"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default NewContentTypeDialog;