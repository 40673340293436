import { Toolbar, Button, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { TabStripSelectEventArguments, StackLayout, TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';
import NewContentTypeDialog from '../../../components/dialogs/NewContentTypeDialog/NewContentTypeDialog';
import DataTable from '../../../components/main/DataTable/DataTable';
import { Tag, useGetContentQuery, useGetContentVersionsQuery, useGetLibrariesQuery, useGetLibraryQuery, UserRoleEnum } from '../../../generated/graphql';
import styles from './Content.module.css';
import { GridCellProps } from '@progress/kendo-react-grid';
import ShowOnRole from '../../../components/main/ShowOnRole/ShowOnRole';
import Essentials from '../../../components/main/Essentials/Essentials';
import DeleteContentTagDialog from '../../../components/dialogs/DeleteContentTagDialog/DeleteContentTagDialog';
import EditContentSettingsDialog from '../../../components/dialogs/EditContentSettingsDialog/EditContentSettingsDialog';

type ContentProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
};

const ImageCell = (row: GridCellProps) => {
    return (<img height="40px" width="40px" src={row.dataItem?.thumbnail?.downloadUrl} />);
};

const Content = (props: ContentProps) => {
    const { style } = props;
    const { clientId, contentId } = useParams();
    const [searchText, setSearchText] = useState("");
    const [searchVersionText, setSearchVersionText] = useState("");
    const [selectedVersionIds, setSelectedVersionIds] = useState<string[]>([]);
    const [selected, setSelected] = useState<number>(0);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [newTypeVisible, setNewTypeVisible] = useState(false);
    const [deleteContentTagVisible, setDeleteContentTagVisible] = useState(false);
    const [selectedTagId, setselectedTagId] = useState("");

    const content = useGetContentQuery({
        variables: {
            id: contentId
        }
    });

    const library = useGetLibraryQuery({
        variables: {
            id: content.data?.Content?.libraryId
        }
    });

    const contentVersion = useGetContentVersionsQuery({
        variables: {
            contentId: contentId,
            contains: searchVersionText
        }
    });

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };

    const handleOnClose = () => {
        setNewTypeVisible(false);
        setDeleteContentTagVisible(false);
        content.refetch();
    };

    const handleOnRemoveTag = (tagId: string) => {
        setselectedTagId(tagId);
        setDeleteContentTagVisible(true);
    };

    const SettingCell = (row: GridCellProps) => {
        const { contentId } = useParams();
        const [editContentSettingsVisible, setEditContentSettingsVisible] = useState(false);
        const handleOnClose = () => {
            setEditContentSettingsVisible(false);
            content.refetch();
        };
        return (
            <StackLayout orientation='horizontal' align={{ horizontal: "center" }}>
                <Button icon='edit' onClick={() => setEditContentSettingsVisible(true)} />
                {editContentSettingsVisible && <EditContentSettingsDialog
                    isAdmin={library.data?.Library?.isAdmin || false}
                    visible={editContentSettingsVisible}
                    contentId={contentId || ""}
                    typeId={row.dataItem.id}
                    settings={row.dataItem.settings}
                    onClose={handleOnClose} />}
            </StackLayout>);
    };

    return (
        <>
            <StackLayout orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}
                className={styles.Content}
                style={style}
                data-testid="Content">
                <Essentials
                    isAdmin={content.data?.Content?.library?.isAdmin || false}
                    onRemoveTag={handleOnRemoveTag}
                    tags={content.data?.Content?.tags?.map(t => t as Tag)}
                    rows={[
                        { title: "Name", value: content?.data?.Content?.name || "" },
                        { title: "Category", value: content?.data?.Content?.category || "" },
                        { title: "Library", value: content?.data?.Content?.library?.name || "", link: `/clients/${clientId}/library/${content?.data?.Content?.libraryId}` },
                        { title: "Description", value: content?.data?.Content?.description || "" },
                        { title: "IFC Entity", value: content?.data?.Content?.ifcEntity?.name || "" },
                        { title: "VBIS", value: content?.data?.Content?.vBIS?.name || "" },
                        { title: "Uniclass", value: content?.data?.Content?.uniclass?.name || "" },
                    ]}
                    preview={content.data?.Content?.thumbnail?.downloadUrl || ""} />
                <TabStrip
                    style={{ flex: 1, position: "relative" }}
                    selected={selected}
                    onSelect={handleSelect}
                    tabPosition="top"
                >
                    <TabStripTab title="Content Types" >
                        <StackLayout
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0
                            }}
                            orientation='vertical'
                            align={{ horizontal: "stretch", vertical: "top" }}>
                            <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                                <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => content.refetch()}>
                                    Refresh
                                </Button>
                                <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={content.data?.Content?.library?.isAdmin || false}>
                                    <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setNewTypeVisible(true)}>
                                        New Type
                                    </Button>
                                    {selectedIds.length > 0 && <>
                                        <ToolbarSeparator />
                                        <Button icon="delete" fillMode="flat" themeColor={"error"} >
                                            Delete
                                        </Button>
                                    </>}
                                </ShowOnRole>
                            </Toolbar>
                            <DataTable
                                hideNameColumn
                                showDefaultColumns
                                onSearchBoxChanged={(value) => setSearchText(value)}
                                onSelected={(value) => setSelectedIds(value)}
                                style={{ flex: 1 }}
                                isLoading={content.loading}
                                data={content.data?.Content?.types}
                                customProps={[
                                    { id: "image", name: "Image", width: "70px", customComponent: ImageCell },
                                    { id: "name", name: "Name" },
                                    { id: "settings", name: "Settings", width: "78px", customComponent: SettingCell }
                                ]} />
                        </StackLayout>
                    </TabStripTab>
                    <TabStripTab title="Content Versions" >
                        <StackLayout
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0
                            }}
                            orientation='vertical'
                            align={{ horizontal: "stretch", vertical: "top" }}>
                            <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                                <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => content.refetch()}>
                                    Refresh
                                </Button>
                                <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={content.data?.Content?.library?.isAdmin || false}>
                                    <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setNewTypeVisible(true)}>
                                        New Version
                                    </Button>
                                    {selectedIds.length > 0 && <>
                                        <ToolbarSeparator />
                                        <Button icon="delete" fillMode="flat" themeColor={"error"} >
                                            Delete
                                        </Button>
                                    </>}
                                </ShowOnRole>
                            </Toolbar>
                            <DataTable
                                showDefaultColumns
                                onSearchBoxChanged={(value) => setSearchVersionText(value)}
                                onSelected={(value) => setSelectedVersionIds(value)}
                                style={{ flex: 1 }}
                                isLoading={contentVersion.loading}
                                data={contentVersion.data?.ContentVersionList?.nodes}
                                customProps={[
                                    { id: "status", name: "Status" }
                                ]} />
                        </StackLayout>

                    </TabStripTab>
                </TabStrip>
            </StackLayout>
            {newTypeVisible && <NewContentTypeDialog contentId={contentId || ""} visible={newTypeVisible} onClose={handleOnClose} />}
            {deleteContentTagVisible && <DeleteContentTagDialog id={contentId || ""} tagId={selectedTagId} visible={deleteContentTagVisible} onClose={handleOnClose} />}
        </>
    );
};

export default Content;