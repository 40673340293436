import React, { CSSProperties, ReactNode, useRef, useState } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './NewTagDialog.module.css';
import { Button } from '@progress/kendo-react-buttons';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import ValidatedInput from '../../main/ValidatedInput/ValidatedInput';
import ValidatedTextArea from '../../main/ValidatedTextArea/ValidatedTextArea';
import { useCreateTagMutation, useGetTagTypesQuery } from '../../../generated/graphql';
import { getter } from '@progress/kendo-react-common';
import ValidatedAutocomplete from '../../main/ValidatedAutocomplete/ValidatedAutocomplete';

type NewTagDialogProps = {
    onClose: () => void,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const NewTagDialog = (props: NewTagDialogProps) => {
    const { style, visible, onClose } = props;
    const [searchText, setSearchText] = useState("");
    const submitButton = useRef<Button>(null);
    const types = useGetTagTypesQuery({
        fetchPolicy: "no-cache"
    });
    const [createTag, createTagResult] = useCreateTagMutation();


    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            createTag({
                variables: {
                    tag: {
                        name: dataItem["name"],
                        description: dataItem["description"],
                        type: dataItem["type"],
                    }
                }
            })
                .then(res => { onClose(); })
                .catch(err => { });
        }
    };
    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };

    const validator = (value: any) => {
        const name = getter("name")(value);
        const description = getter("description")(value);
        const type = getter("type")(value);

        const nameValidation = { ["name"]: "Field Cannot Empty!" };
        const descriptionValidation = { ["description"]: "Field Cannot Empty!" };
        const typeValidation = { ["type"]: "Field Cannot Empty!" };

        if (name && description && type)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!name && nameValidation),
                ...(!description && descriptionValidation),
                ...(!type && typeValidation),
            };
    };
    return (
        <DialogBase
            title="New Tag"
            type="OkCancel"
            visible={visible}
            onClose={onClose}
            onOkClick={onCreateHandler}
            isLoading={createTagResult.loading}
            className={styles.NewTagDialog}
            style={style}
            data-testid="NewTagDialog">
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            name={"name"}
                            component={ValidatedInput}
                            label={"Name"}
                        />
                        <Field
                            name={"type"}
                            component={ValidatedAutocomplete}
                            data={types.data?.TagTypes?.map(p => p)}
                            label={"Tag Type"}
                        />
                        <Field
                            name={"description"}
                            component={ValidatedTextArea}
                            label={"Description"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default NewTagDialog;