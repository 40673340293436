import React, { CSSProperties, ReactNode, useState } from 'react';
import styles from './ClientDrawer.module.css';
import {
    Breadcrumb,
    Drawer,
    DrawerContent,
    DrawerItem,
    DrawerItemProps,
    DrawerSelectEvent,
} from "@progress/kendo-react-layout";
import { useLocation, useNavigate } from 'react-router-dom';
import { groupBy } from '../../../Utils';
import { RouteType } from '../../../App.enums';
import { routes } from '../../../views/Clients/Clients.routing';

const CustomItem = (props: DrawerItemProps) => {
    const { expanded } = props;
    return <> {
        props.text === "collapse" ?
            <DrawerItem {...props} style={{ width: "100%", justifyContent: (expanded ? "end" : "start") }}>
                <span className={"k-icon " + (expanded ? "k-i-arrow-chevron-left" : "k-i-arrow-chevron-right")} />
            </DrawerItem> :
            <DrawerItem {...props}>
                <span className={"k-icon " + props.icon} />
                {expanded ? <div className="item-descr-wrap">
                    <div>{props.text}</div>
                </div> : <></>}
            </DrawerItem>
    }
    </>;
};

type ClientDrawerProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const ClientDrawer = (props: ClientDrawerProps) => {
    const { style, children } = props;
    const [expanded, setExpanded] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const items = routes.map(route => ({
        text: route.name,
        icon: route.icon,
        route: route.path,
        type: route.type
    } as DrawerItemProps));
    const collapseButton: DrawerItemProps[] = [
        {
            text: "collapse",
            icon: "",
            route: "",
            type: RouteType.None
        },
    ];

    const onSelect = (e: DrawerSelectEvent) => {
        if (e.itemTarget.props.text === "collapse") {
            setExpanded(!expanded);
        } else
            navigate(e.itemTarget.props.route);
    };

    const setSelectedItem = (pathName: string) => {
        const currentPath: DrawerItemProps | undefined = items.find((item) => pathName.split("/")[3] === item.route.split("/")[0]);
        if (currentPath?.text) {
            return currentPath.text;
        }
    };
    const selected = setSelectedItem(location.pathname);
    return (
        <div className={styles.ClientDrawer} style={style} data-testid="ClientDrawer">
            <Drawer
                style={{ height: "100%", width: "100%" }}
                expanded={expanded}
                position={"start"}
                mode={"push"}
                mini={true}
                items={groupBy(collapseButton.concat(items.filter(item => item.type !== RouteType.None)), item => item.text)
                    .map(g => {
                        g.push({ separator: true });
                        return g;
                    })
                    .flat()
                    .map(item => ({
                        ...item,
                        selected: item.text === selected,
                        expanded: expanded
                    }))}
                item={CustomItem}
                onSelect={onSelect}
            >
                <DrawerContent style={{ height: "100%" }}>
                    {children}
                </DrawerContent>
            </Drawer>
        </div>
    );
};

export default ClientDrawer;