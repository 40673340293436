import React, { CSSProperties, ReactNode, useRef, useState } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './NewProjectMemberDialog.module.css';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import { useGetUsersWithoutRoleQuery, useSetProjectMemberMutation } from '../../../generated/graphql';
import ValidatedAutocomplete from '../../main/ValidatedAutocomplete/ValidatedAutocomplete';

type NewProjectMemberDialogProps = {
    onClose: () => void,
    projectId: string,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const NewProjectMemberDialog = (props: NewProjectMemberDialogProps) => {
    const { style, visible, projectId, onClose } = props;
    const [searchUser, setSearchUser] = useState("");
    const submitButton = useRef<Button>(null);
    const [setProjectMember, setProjectMemberResult] = useSetProjectMemberMutation();
    const users = useGetUsersWithoutRoleQuery({
        variables: {
            contains: searchUser
        }
    });
    const handleUserAutocompleteChanged = (event: any) => {
        setSearchUser(event.value);
    };
    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            const email = dataItem["email"];
            const selectedEmail = users.data?.UserList?.nodes?.filter(u => u?.email === email)[0];
            setProjectMember({
                variables: {
                    id: projectId,
                    userId: selectedEmail?.id
                }
            })
                .then(res => onClose())
                .catch(err => { });
        }
    };
    const onOkClick = () => {
        submitButton.current?.element?.click();
    };

    const validator = (value: any) => {
        const email = getter("email")(value);

        const selectedEmail = users.data?.UserList?.nodes?.filter(u => u?.email === email)[0];

        const emailValidation = { ["email"]: "Field Cannot Empty!" };

        const validEmailValidation = { ["email"]: "Please Select A Valid Email!" };

        if (email && selectedEmail)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!email && emailValidation),
                ...((email && !selectedEmail) && validEmailValidation),
            };
    };
    return (
        <DialogBase
            title="New Project Member"
            type="OkCancel"
            isLoading={setProjectMemberResult.loading}
            visible={visible}
            onClose={onClose}
            onOkClick={onOkClick}
            className={styles.NewProjectMemberDialog}
            style={style}
            data-testid="NewProjectMemberDialog">
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            value={searchUser}
                            name={"email"}
                            component={ValidatedAutocomplete}
                            data={users.data?.UserList?.nodes?.map(c => c?.email)}
                            onChange={handleUserAutocompleteChanged}
                            label={"Email"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default NewProjectMemberDialog;