import React, { CSSProperties, ReactNode, useRef, useState } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './AddFilterDialog.module.css';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import { useGetTagTypesQuery, useGetTagListWithTypeQuery, Tag } from '../../../generated/graphql';
import ValidatedAutocomplete from '../../main/ValidatedAutocomplete/ValidatedAutocomplete';

type AddFilterDialogProps = {
    onClose: (tag?: Tag) => void,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const AddFilterDialog = (props: AddFilterDialogProps) => {
    const { style, visible, onClose } = props;
    const [tagType, setTagType] = useState("");
    const [searchTag, setsearchTag] = useState("");
    const submitButton = useRef<Button>(null);
    const types = useGetTagTypesQuery({
        fetchPolicy: "no-cache"
    });
    const tags = useGetTagListWithTypeQuery({
        variables: {
            contains: searchTag,
            type: tagType
        }
    });

    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            const tag = tags.data?.TagList?.nodes?.filter(l => l?.name === dataItem["tag"]).map(t => t as Tag);
            tag && onClose(tag[0]);
        }
    };
    const handleTypeChanged = (event: any) => {
        setTagType(event.value);
    };
    const handleTagChanged = (event: any) => {
        setsearchTag(event.value);
    };
    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };
    const validator = (value: any) => {
        const type = getter("type")(value);
        const tag = getter("tag")(value);

        const selectedType = types.data?.TagTypes?.filter(l => l === type)[0];
        const selectedTag = tags.data?.TagList?.nodes?.filter(t => t?.name === tag)[0];

        const typeValidation = { ["type"]: "Field Cannot Empty!" };
        const validtypeValidation = { ["type"]: "Please Select A Valid Type!" };

        const tagValidation = { ["tag"]: "Field Cannot Empty!" };
        const validtagValidation = { ["tag"]: "Please Select A Valid Tag!" };

        if (type && selectedType && tag && selectedTag)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!type && typeValidation),
                ...((type && !selectedType) && validtypeValidation),
                ...(!tag && tagValidation),
                ...((tag && !selectedTag) && validtagValidation),
            };
    };
    return (
        <DialogBase
            title="Add Filter"
            type="OkCancel"
            visible={visible}
            onClose={onClose}
            onOkClick={onCreateHandler}
            className={styles.AddFilterDialog}
            style={style}
            data-testid="AddFilterDialog">
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            name={"type"}
                            component={ValidatedAutocomplete}
                            data={types.data?.TagTypes?.map(p => p)}
                            onChange={handleTypeChanged}
                            label={"Tag Type"}
                        />
                        <Field
                            value={searchTag}
                            name={"tag"}
                            component={ValidatedAutocomplete}
                            data={tags.data?.TagList?.nodes?.map(p => p?.name)}
                            onChange={handleTagChanged}
                            label={"Tag"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default AddFilterDialog;