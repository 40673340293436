import { Toolbar, Button, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { TabStripSelectEventArguments, TabStrip, TabStripTab, StackLayout } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './AccessControl.module.css';
import DataTable from '../../../components/main/DataTable/DataTable';
import { UserRoleEnum, useGetProjectQuery } from '../../../generated/graphql';
import ShowOnRole from '../../../components/main/ShowOnRole/ShowOnRole';
import NewProjectAdminDialog from '../../../components/dialogs/NewProjectAdminDialog/NewProjectAdminDialog';
import NewProjectMemberDialog from '../../../components/dialogs/NewProjectMemberDialog/NewProjectMemberDialog';
import { ticksToDate } from '../../../Utils';
import DeleteUserPermissionDialog from '../../../components/dialogs/DeleteUserPermissionDialog/DeleteUserPermissionDialog';

type AccessControlProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const AccessControl = (props: AccessControlProps) => {
    const { projectId } = useParams();
    const { style } = props;
    const [selected, setSelected] = React.useState<number>(0);
    const [selectedAdminIds, setSelectedAdminIds] = useState<string[]>([]);
    const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
    const [newProjectAdminVisible, setNewProjectAdminVisible] = useState(false);
    const [newProjectMemberVisible, setNewProjectMemberVisible] = useState(false);
    const [deleteMembersVisible, setDeleteMembersVisible] = useState(false);
    const [deleteAdminsVisible, setDeleteAdminsVisible] = useState(false);

    const project = useGetProjectQuery({
        variables: {
            id: projectId
        }
    });

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };

    const handleOnClose = () => {
        project.refetch();
        setNewProjectAdminVisible(false);
        setNewProjectMemberVisible(false);
        setDeleteAdminsVisible(false);
        setDeleteMembersVisible(false);
    };

    return (
        <>
            <TabStrip
                className={styles.AccessControl} style={style} data-testid="AccessControl"
                selected={selected}
                onSelect={handleSelect}
                tabPosition="top"
            >
                <TabStripTab title="Admins">
                    <StackLayout
                        className={styles.Projects}
                        style={{
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0
                        }}
                        data-testid="Projects"
                        orientation='vertical'
                        align={{ horizontal: "stretch", vertical: "top" }}>
                        <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                            <Button icon="refresh" fillMode="flat" themeColor={"primary"}>
                                Refresh
                            </Button>
                            <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={project.data?.Project?.isAdmin || false}>
                                <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setNewProjectAdminVisible(true)}>
                                    New Admin
                                </Button>
                                {selectedAdminIds.length > 0 && <>
                                    <ToolbarSeparator />
                                    <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteAdminsVisible(true)}>
                                        Delete
                                    </Button>
                                </>}
                            </ShowOnRole>
                        </Toolbar>
                        <DataTable style={{ flex: 1 }}
                            onSelected={(ids) => setSelectedAdminIds(ids)}
                            isLoading={project.loading}
                            data={project.data?.Project?.admins}
                            customProps={[
                                { id: "email", name: "Email" },
                                { id: "updatedTime", name: "Last Activity", customComponent: (row) => (<>{ticksToDate(row?.dataItem?.updatedTime)}</>) }
                            ]} />
                    </StackLayout>
                </TabStripTab>
                <TabStripTab title="Members">
                    <StackLayout
                        className={styles.Projects}
                        style={{
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0
                        }}
                        data-testid="Projects"
                        orientation='vertical'
                        align={{ horizontal: "stretch", vertical: "top" }}>
                        <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                            <Button icon="refresh" fillMode="flat" themeColor={"primary"}>
                                Refresh
                            </Button>
                            <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={project.data?.Project?.isAdmin || false}>
                                <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setNewProjectMemberVisible(true)}>
                                    New Member
                                </Button>
                                {selectedMemberIds.length > 0 && <>
                                    <ToolbarSeparator />
                                    <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteMembersVisible(true)}>
                                        Delete
                                    </Button>
                                </>}
                            </ShowOnRole>
                        </Toolbar>
                        <DataTable style={{ flex: 1 }}
                            onSelected={(ids) => setSelectedMemberIds(ids)}
                            isLoading={project.loading}
                            data={project.data?.Project?.members}
                            customProps={[
                                { id: "email", name: "Email" },
                                { id: "updatedTime", name: "Last Activity", customComponent: (row) => (<>{ticksToDate(row?.dataItem?.updatedTime)}</>) }
                            ]} />
                    </StackLayout>
                </TabStripTab>
            </TabStrip>
            {newProjectAdminVisible && <NewProjectAdminDialog visible={newProjectAdminVisible} onClose={handleOnClose} projectId={projectId ?? ""} />}
            {newProjectMemberVisible && <NewProjectMemberDialog visible={newProjectMemberVisible} onClose={handleOnClose} projectId={projectId ?? ""} />}
            {deleteAdminsVisible && <DeleteUserPermissionDialog admin visible={deleteAdminsVisible} onClose={handleOnClose} projectId={projectId ?? ""} ids={selectedAdminIds} />}
            {deleteMembersVisible && <DeleteUserPermissionDialog visible={deleteMembersVisible} onClose={handleOnClose} projectId={projectId ?? ""} ids={selectedMemberIds} />}

        </>
    );
};

export default AccessControl;