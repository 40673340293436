import React, { CSSProperties, useState } from 'react';
import styles from './Clients.module.css';
import { StackLayout } from '@progress/kendo-react-layout';
import { Button, Chip, Toolbar, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { Typography } from "@progress/kendo-react-common";
import NewClientDialog from '../../components/dialogs/NewClientDialog/NewClientDialog';
import { Tag, UserRoleEnum, useGetClientsQuery } from '../../generated/graphql';
import DataTable from '../../components/main/DataTable/DataTable';
import DeleteClientsDialog from '../../components/dialogs/DeleteClientsDialog/DeleteClientsDialog';
import ShowOnRole from '../../components/main/ShowOnRole/ShowOnRole';
import AddClientsTagsDialog from '../../components/dialogs/AddClientsTagsDialog/AddClientsTagsDialog';
import AddClientHealthChecksDialog from '../../components/dialogs/AddClientHealthChecksDialog/AddClientHealthChecksDialog';
import { GridCellProps } from '@progress/kendo-react-grid';

const HealthChecksCell = (row: GridCellProps) => {
    return (<div>
        {(row.dataItem?.healthChecks as any[]).length ?? 0}
    </div>);
};

type ClientsProps = {
    style?: CSSProperties | undefined;
};

const Clients = (props: ClientsProps) => {
    const { style } = props;
    const [searchText, setSearchText] = useState("");
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [tagIds, setTagIds] = useState<string[]>([]);
    const [newClientDialogVisible, setNewClientDialogVisible] = useState(false);
    const [deleteClientsDialogVisible, setDeleteClientsDialogVisible] = useState(false);
    const [assignTagsDialogVisible, setAssignTagsDialogVisible] = useState(false);
    const [assignHealthCheckVisible, setAssignHealthCheckVisible] = useState(false);

    const result = useGetClientsQuery({
        variables: {
            tags: tagIds,
            contains: searchText
        },
    });

    const handleDataTableSelected = (ids: string[]) => {
        setSelectedIds(ids);
    };

    const handleOnClose = () => {
        setNewClientDialogVisible(false);
        setDeleteClientsDialogVisible(false);
        setAssignTagsDialogVisible(false);
        setAssignHealthCheckVisible(false);
        result.refetch();
    };

    const handleOnRefreshClick = () => {
        result.refetch();
    };

    const handleSearchChanged = (value: string) => {
        setSearchText(value);
    };

    const handleFilterChanged = (tags: Tag[]) => {
        tags && setTagIds(tags.map(t => t.id || ""));
    };

    return (
        <>
            <StackLayout
                orientation='vertical'
                className={styles.Clients}
                style={style}
                data-testid="Clients"
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Typography.h2 style={{ margin: "10px", flex: 0 }}>
                    Clients Management
                </Typography.h2>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={handleOnRefreshClick}>
                        Refresh
                    </Button>
                    <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={false}>
                        <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setNewClientDialogVisible(true)}>
                            New Client
                        </Button>
                        {selectedIds.length > 0 &&
                            <>
                                <ToolbarSeparator />
                                <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setAssignTagsDialogVisible(true)}>
                                    Assign Tags
                                </Button>
                                <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setAssignHealthCheckVisible(true)}>
                                    Assign Health Checks
                                </Button>
                                <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteClientsDialogVisible(true)}>
                                    Delete
                                </Button>
                            </>}
                    </ShowOnRole>
                </Toolbar>
                <DataTable
                    showTagsColumn
                    onSelected={handleDataTableSelected}
                    onSearchBoxChanged={handleSearchChanged}
                    onFilterChanged={handleFilterChanged}
                    style={{ flex: 1 }}
                    data={result.data?.ClientList?.nodes}
                    isLoading={result.loading}
                    link="/clients"
                    customProps={[
                        { id: "longName", name: "Long Name" },
                        { id: "healthChecls", name: "Health Checks", customComponent: HealthChecksCell, width: "150px" }
                    ]}
                />
            </StackLayout>
            {newClientDialogVisible && <NewClientDialog visible={newClientDialogVisible} onClose={handleOnClose} />}
            {deleteClientsDialogVisible && <DeleteClientsDialog ids={selectedIds} visible={deleteClientsDialogVisible} onClose={handleOnClose} />}
            {assignTagsDialogVisible && <AddClientsTagsDialog ids={selectedIds} visible={assignTagsDialogVisible} onClose={handleOnClose} />}
            {assignHealthCheckVisible && <AddClientHealthChecksDialog ids={selectedIds} visible={assignHealthCheckVisible} onClose={handleOnClose} />}
        </ >
    );
};

export default Clients;