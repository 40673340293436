import { Icon } from '@progress/kendo-react-common';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import styles from './SearchBox.module.css';
import { StackLayout } from '@progress/kendo-react-layout';
import { Button, Chip, Toolbar, ToolbarSeparator } from '@progress/kendo-react-buttons';
import AddFilterDialog from '../../dialogs/AddFilterDialog/AddFilterDialog';
import { Tag } from '../../../generated/graphql';

type SearchBoxProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    onChanged: (value: string) => void;
    onTagsChanged?: (tags: Tag[]) => void;
};

const SearchBox = (props: SearchBoxProps) => {
    const { style, onChanged, onTagsChanged } = props;
    const [tags, setTags] = useState<Tag[]>([]);
    const [addFilterVisible, setAddFilterVisible] = useState(false);

    const handleOnChange = (e: InputChangeEvent) => {
        onChanged(e.value);
    };

    const handleOnClose = (t?: Tag) => {
        setAddFilterVisible(false);
        t && t.id && setTags([...tags, t]);
    };

    const handleRemoveFilter = (tag?: Tag) => {
        setTags([...tags.filter(t => t.id != tag?.id)]);
    };

    useEffect(() => {
        onTagsChanged && onTagsChanged(tags);
    }, [tags]);

    return (
        <Toolbar className={styles.SearchBox} style={style} data-testid="SearchBox">
            <Input
                style={{ flex: 1, borderWidth: 0, height: "100%" }}
                placeholder="Search..."
                onChange={handleOnChange}
            />

            <ToolbarSeparator />
            {tags && tags.length > 0 && <StackLayout style={{ flex: 0, marginLeft: 5 }}>
                {tags.map(t => (
                    <Chip style={{ padding: "0 5px", marginRight: 5, flex: "unset" }} fillMode="outline">
                        <StackLayout align={{ vertical: "middle" }} style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                            <span style={{ fontWeight: "bold", opacity: 0.8 }}>{t.type}</span>
                            <span style={{ fontWeight: "bold", opacity: 0.8, marginRight: 5 }}>:</span>
                            <span>{t.name}</span>
                            <Button icon='close' fillMode="flat" style={{ borderRadius: 100, padding: 1, marginLeft: 2 }} onClick={() => handleRemoveFilter(t)} />
                        </StackLayout>
                    </Chip>))}

            </StackLayout>}
            {tags && tags.length > 0 && <ToolbarSeparator />}
            {onTagsChanged && <Button style={{ margin: "5px 5px 5px 0", flex: 0 }} icon='filter' fillMode="outline" onClick={() => setAddFilterVisible(true)}>Add Filter</Button>}
            {addFilterVisible && <AddFilterDialog visible={addFilterVisible} onClose={handleOnClose} />}
        </Toolbar>

    );
};

export default SearchBox;