import { Reveal } from '@progress/kendo-react-animation';
import { Toolbar, Button, ToolbarSeparator, Chip } from '@progress/kendo-react-buttons';
import { StackLayout, TabStrip, TabStripTab, TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode, useContext, useState } from 'react';
import EditProjectDialog from '../../../components/dialogs/EditProjectDialog/EditProjectDialog';
import NewWorksetDialog from '../../../components/dialogs/NewWorksetDialog/NewWorksetDialog';
import DataTable from '../../../components/main/DataTable/DataTable';
import { HealthCheck, Tag, UserRoleEnum, useGetModelsQuery, useGetProjectDynamicDataQuery, useToggleProjectPrivateMutation } from '../../../generated/graphql';
import { newGuid, ticksToDate } from '../../../Utils';
import { ProjectContext } from '../Projects.provider';
import Worksets from '../Worksets/Worksets';
import styles from './ProjectOverview.module.css';
import SearchForApprovedContentsDialog from '../../../components/dialogs/SearchForApprovedContentsDialog/SearchForApprovedContentsDialog';
import DeleteApprovedContentsDialog from '../../../components/dialogs/DeleteApprovedContentsDialog/DeleteApprovedContentsDialog';
import ImageViewerDialog from '../../../components/dialogs/ImageViewerDialog/ImageViewerDialog';
import Essentials from '../../../components/main/Essentials/Essentials';
import DeleteProjectTagDialog from '../../../components/dialogs/DeleteProjectTagDialog/DeleteProjectTagDialog';
import ShowOnRole from '../../../components/main/ShowOnRole/ShowOnRole';
import { GridCellProps } from '@progress/kendo-react-grid';

const CustomPropertiesCell = (row: GridCellProps) => {
    const object = row.dataItem?.customProperties && JSON.parse(row.dataItem?.customProperties);
    return (
        <StackLayout orientation='vertical' align={{ horizontal: "start" }} gap={5}>
            {object && Object.keys(object).map(key => (<Chip>{key}: {object[key].toString()}</Chip>))}
        </StackLayout>);
};

const ImageViewerButton = ({ link, title }: { link: string, title: string; }) => {
    const [imageViewerVisible, setImageViewerVisible] = useState(false);

    return (<>
        <Button icon="search" onClick={() => setImageViewerVisible(true)} />
        {imageViewerVisible && <ImageViewerDialog title={title} link={link} visible={imageViewerVisible} onClose={() => setImageViewerVisible(false)} />}
    </>);
};

const DynamicDataTab = ({ projectId, libraryId, contents, loading, onRefresh }: { projectId: string, libraryId: string, contents: any[], loading: boolean | undefined, onRefresh: () => void; }) => {
    const [search, setSearch] = useState("");
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const getDynamicData = useGetProjectDynamicDataQuery({
        variables: {
            id: projectId
        }
    });

    const handleOnClose = () => {
        onRefresh();
    };
    return (
        <>
            <StackLayout
                className={styles.Projects}
                style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0
                }}
                data-testid="Projects"
                orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={onRefresh}>
                        Refresh
                    </Button>
                    {selectedIds.length > 0 && <>
                        <ToolbarSeparator />
                        <Button icon="delete" fillMode="flat" themeColor={"error"}>
                            Delete
                        </Button>
                    </>}
                </Toolbar>
                <DataTable
                    onSelected={(ids) => setSelectedIds(ids)}
                    onSearchBoxChanged={value => setSearch(value)}
                    style={{ flex: 1 }}
                    isLoading={getDynamicData.loading}
                    data={getDynamicData.data?.Project?.dynamicData}
                    actionButtonsWidth='55px'
                    actionButtons={[
                        (row) => row.dataItem?.thumbnail?.downloadUrl && <ImageViewerButton
                            title={row.dataItem?.name}
                            link={row.dataItem?.thumbnail?.downloadUrl} />
                    ]}
                />
            </StackLayout>
        </>
    );
};

const StandardDetailsTab = ({ projectId, libraryId, contents, loading, isAdmin, onRefresh }: { isAdmin: boolean, projectId: string, libraryId: string, contents: any[], loading: boolean | undefined, onRefresh: () => void; }) => {
    const [search, setSearch] = useState("");
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [searchContentsVisible, setSearchContentsVisible] = useState(false);
    const [deleteContentsVisible, setDeleteContentsVisible] = useState(false);

    const handleOnClose = () => {
        setSearchContentsVisible(false);
        setDeleteContentsVisible(false);
        onRefresh();
    };
    return (
        <>
            <StackLayout
                className={styles.Projects}
                style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0
                }}
                data-testid="Projects"
                orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={onRefresh}>
                        Refresh
                    </Button>
                    <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={isAdmin}>
                        <Button icon="add" fillMode="flat" themeColor={"primary"} onClick={() => setSearchContentsVisible(true)}>
                            Add Details
                        </Button>
                        {selectedIds.length > 0 && <>
                            <ToolbarSeparator />
                            <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteContentsVisible(true)}>
                                Delete
                            </Button>
                        </>}
                    </ShowOnRole>
                </Toolbar>
                <DataTable
                    showTagsColumn
                    onSelected={(ids) => setSelectedIds(ids)}
                    onSearchBoxChanged={value => setSearch(value)}
                    style={{ flex: 1 }}
                    isLoading={loading || false}
                    data={contents}
                    actionButtonsWidth='55px'
                    actionButtons={[
                        (row) => row.dataItem?.thumbnail?.downloadUrl && <ImageViewerButton
                            title={row.dataItem?.name}
                            link={row.dataItem?.thumbnail?.downloadUrl} />
                    ]}
                />
            </StackLayout>
            {searchContentsVisible && <SearchForApprovedContentsDialog projectId={projectId} libraryId={libraryId} visible={searchContentsVisible} onClose={handleOnClose} />}
            {deleteContentsVisible && <DeleteApprovedContentsDialog projectId={projectId} ids={selectedIds} visible={deleteContentsVisible} onClose={handleOnClose} />}
        </>

    );
};

type ProjectOverviewProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const ProjectOverview = (props: ProjectOverviewProps) => {
    const { style } = props;
    const project = useContext(ProjectContext);
    const [selected, setSelected] = useState<number>(0);
    const [searchModel, setSearchModel] = useState("");
    const [newWorksetVisible, setNewWorksetVisible] = useState(false);
    const [editProjectVisible, setEditProjectVisible] = useState(false);
    const [deleteProjectTagVisible, setDeleteProjectTagVisible] = useState(false);
    const [selectedTagId, setselectedTagId] = useState("");
    const [togglePrivate, togglePrivateResult] = useToggleProjectPrivateMutation();

    const modelData = useGetModelsQuery({
        variables: {
            projectId: project?.data?.Project?.id,
            contains: searchModel
        }
    });

    const togglePrivateHandle = async () => {
        await togglePrivate({
            variables: {
                id: project?.data?.Project?.id
            }
        });
        project?.refetch();
    };

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };

    const handleOnClose = () => {
        setNewWorksetVisible(false);
        setEditProjectVisible(false);
        setDeleteProjectTagVisible(false);
        project?.refetch();
    };

    const handleOnRemoveTag = (tagId: string) => {
        setselectedTagId(tagId);
        setDeleteProjectTagVisible(true);
    };

    return (
        <>
            <StackLayout orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}
                className={styles.ProjectOverview}
                style={style}
                data-testid="ProjectOverview">
                <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={project?.data?.Project?.isAdmin || false}>
                    <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                        <Button disabled={togglePrivateResult.loading} icon={project?.data?.Project?.isPrivate === true ? "cancel-outline" : "eye"} fillMode="flat" themeColor={"primary"} onClick={togglePrivateHandle}>
                            {project?.data?.Project?.isPrivate === true ? "Private" : "Public"}
                        </Button>
                        <Button icon="edit" fillMode="flat" themeColor={"primary"} onClick={() => setEditProjectVisible(true)}>
                            Edit
                        </Button>
                    </Toolbar>
                </ShowOnRole>
                <Essentials
                    isAdmin={project?.data?.Project?.isAdmin || false}
                    onRemoveTag={handleOnRemoveTag}
                    tags={project?.data?.Project?.tags?.map(t => t as Tag)}
                    healthChecks={project?.data?.Project?.healthChecks?.map<HealthCheck>(c => c as HealthCheck)}
                    rows={[
                        { title: "Name", value: project?.data?.Project?.name || "" },
                        { title: "Client", value: project?.data?.Project?.client?.name || "", link: `/clients/${project?.data?.Project?.client?.id}` },
                        { title: "Client Library", value: project?.data?.Project?.library?.name || "", link: `/clients/${project?.data?.Project?.client?.id}/library/${project?.data?.Project?.library?.id}` },
                        { title: "Description", value: project?.data?.Project?.description || "" },
                    ]} />
                <TabStrip
                    style={{ flex: 1, position: "relative" }}
                    selected={selected}
                    onSelect={handleSelect}
                    tabPosition="top"
                >
                    <TabStripTab title="Models">
                        <StackLayout
                            className={styles.Projects}
                            style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0
                            }}
                            data-testid="Projects"
                            orientation='vertical'
                            align={{ horizontal: "stretch", vertical: "top" }}>
                            <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                                <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => modelData.refetch()}>
                                    Refresh
                                </Button>
                            </Toolbar>
                            <DataTable
                                showDefaultColumns
                                onSearchBoxChanged={value => setSearchModel(value)}
                                link="../model"
                                style={{ flex: 1 }}
                                isLoading={false}
                                data={modelData.data?.ModelList?.nodes}
                                customProps={[
                                    { id: "applicationName", name: "Application" },
                                    { id: "customProperties", name: "Custom Properties", customComponent: CustomPropertiesCell },
                                    { id: "updatedTime", name: "Updated Date", width: "120px", customComponent: ((row) => <>{ticksToDate(row.dataItem.updatedTime)}</>) }
                                ]}
                            />
                        </StackLayout>
                    </TabStripTab>
                    <TabStripTab title="Worksets">
                        <Worksets isAdmin={project?.data?.Project?.isAdmin || false} projectId={project?.data?.Project?.id || ""} isLoading={false} worksets={project?.data?.Project?.worksets || []} refresh={() => project?.refetch()} />
                    </TabStripTab>
                    <TabStripTab title="Standard Details">
                        <StandardDetailsTab
                            isAdmin={project?.data?.Project?.isAdmin || false}
                            projectId={project?.data?.Project?.id ?? ""}
                            libraryId={project?.data?.Project?.libraryId ?? ""}
                            onRefresh={() => project?.refetch()}
                            loading={project?.loading}
                            contents={project?.data?.Project?.approvedContents || []}
                        />
                    </TabStripTab>
                    {/* <TabStripTab title="Dynamic Data">
                        <DynamicDataTab
                            projectId={project?.data?.Project?.id ?? ""}
                            libraryId={project?.data?.Project?.libraryId ?? ""}
                            onRefresh={() => project?.refetch()}
                            loading={project?.loading}
                            contents={project?.data?.Project?.approvedContents || []}
                        />
                    </TabStripTab> */}
                </TabStrip>
            </StackLayout>
            {newWorksetVisible && <NewWorksetDialog key={newGuid()} projectId={project?.data?.Project?.id || ""} visible={newWorksetVisible} onClose={handleOnClose} />}
            {editProjectVisible && <EditProjectDialog key={newGuid()} projectId={project?.data?.Project?.id || ""} visible={editProjectVisible} onClose={handleOnClose} />}
            {deleteProjectTagVisible && <DeleteProjectTagDialog key={newGuid()} id={project?.data?.Project?.id || ""} tagId={selectedTagId} visible={deleteProjectTagVisible} onClose={handleOnClose} />}
        </>
    );
};

export default ProjectOverview;