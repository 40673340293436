import { Chart, ChartTitle, ChartLegend, ChartCategoryAxis, ChartCategoryAxisItem, ChartSeries, ChartSeriesItem } from '@progress/kendo-react-charts';
import React, { CSSProperties, ReactNode, useMemo, useState } from 'react';
import { Client, Project, Model, RequestStatusEnum } from '../../../generated/graphql';
import { ticksToDate } from '../../../Utils';
import styles from './Timeline.module.css';

type TimelineProps = {
    clientLoading: boolean,
    projectLoading: boolean,
    modelLoading: boolean,
    clients: Client[],
    projects: Project[],
    models: Model[],
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
};

const Timeline = (props: TimelineProps) => {
    const { clients, projects, models, clientLoading, projectLoading, modelLoading, className, style } = props;
    const [data, setdata] = useState<any[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    useMemo(() => {
        const list = [...clients, ...projects, ...models];
        const labels = list.map(item => item.createdTime as string).sort().map(item => ticksToDate(item)).filter((v, i, a) => a.indexOf(v) === i && v !== undefined);
        setLabels(labels);
        setdata([
            {
                name: 'Clients',
                data: labels
                    .map(label => clients.filter(item => ticksToDate(item.createdTime) === label).length)
                    .map((count, index, array) => {
                        if (index > 0) {
                            let c = count;
                            for (let i = 0; i <= index - 1; i++)
                                c = c + array[i];
                            return c;
                        }
                        else
                            return count;
                    })
            },
            {
                name: 'Projects',
                data: labels
                    .map(label => projects.filter(item => ticksToDate(item.createdTime) === label).length)
                    .map((count, index, array) => {
                        if (index > 0) {
                            let c = count;
                            for (let i = 0; i <= index - 1; i++)
                                c = c + array[i];
                            return c;
                        }
                        else
                            return count;
                    })
            },
            {
                name: 'Models',
                data: labels
                    .map(label => models.filter(item => ticksToDate(item.createdTime) === label).length)
                    .map((count, index, array) => {
                        if (index > 0) {
                            let c = count;
                            for (let i = 0; i <= index - 1; i++)
                                c = c + array[i];
                            return c;
                        }
                        else
                            return count;
                    })
            },
        ]);
    }, [clients, projects, models]);

    return (
        <Chart className={`${styles.Timeline} ${className || ""}`} style={style} data-testid="Timeline">
            <ChartTitle text="Timeline" visible={false} />
            <ChartLegend position="left" orientation="vertical" />
            <ChartSeries>
                {data.map((item, idx) => (
                    <ChartSeriesItem
                        key={idx}
                        type="line"
                        style="smooth"
                        markers={{ visible: false }}
                        tooltip={{ visible: true }}
                        data={item.data}
                        name={item.name}
                    />
                ))}
            </ChartSeries>
        </Chart>
    );
};

export default Timeline;