import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './DeleteProjectTagDialog.module.css';
import { useDeleteProjectTagsMutation } from '../../../generated/graphql';
import { Typography } from '@progress/kendo-react-common';

type DeleteProjectTagDialogProps = {
    onClose: () => void,
    id: string,
    tagId: string,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const DeleteProjectTagDialog = (props: DeleteProjectTagDialogProps) => {
    const { id, tagId, style, visible, onClose } = props;
    const [deleteProjectTag, deleteProjectTagResult] = useDeleteProjectTagsMutation({
        variables: {
            id: id,
            ids: [tagId]
        }
    });

    const handleOnOkClick = () => {
        deleteProjectTag()
            .then(() => onClose())
            .catch(err => onClose());
    };

    return (
        <DialogBase
            title="Delete Project Tag"
            type="OkCancel"
            isLoading={deleteProjectTagResult.loading}
            visible={visible}
            onClose={onClose}
            onOkClick={handleOnOkClick}
            className={styles.DeleteProjectTagDialog}
            style={style}
            data-testid="DeleteProjectTagDialog">
            <Typography.p>Are you sure you want to delete this Tag?</Typography.p>
        </DialogBase>
    );
};

export default DeleteProjectTagDialog;