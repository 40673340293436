import React, { CSSProperties, ReactNode, useRef } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './NewContentDialog.module.css';
import { useCreateContentMutation } from '../../../generated/graphql';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import ValidatedInput from '../../main/ValidatedInput/ValidatedInput';
import ValidatedTextArea from '../../main/ValidatedTextArea/ValidatedTextArea';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import ValidatedDropdownList from '../../main/ValidatedDropdownList/ValidatedDropdownList';

type NewContentDialogProps = {
    onClose: () => void,
    libraryId: string,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const NewContentDialog = (props: NewContentDialogProps) => {
    const { style, visible, libraryId, onClose } = props;
    const submitButton = useRef<Button>(null);
    const [createContent, createContentResult] = useCreateContentMutation();

    const onSubmitHandler = async (dataItem: { [name: string]: any; }) => {
        if (visible) {
            try {
                await createContent({
                    variables: {
                        content: {
                            name: dataItem['name'],
                            category: dataItem['category'],
                            description: dataItem['description'],
                            libraryId: libraryId,
                        }
                    }
                });
                onClose();
            }
            catch {

            }
        }
    };
    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };
    const validator = (value: any) => {
        const name = getter("name")(value);
        const description = getter("description")(value);
        const category = getter("category")(value);

        const nameValidation = { ["name"]: "Field Cannot Empty!" };
        const descriptionValidation = { ["description"]: "Field Cannot Empty!" };
        const categoryValidation = { ["category"]: "Field Cannot Empty!" };

        if (name && category && description)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!name && nameValidation),
                ...(!description && descriptionValidation),
                ...(!category && categoryValidation),
            };
    };

    return (
        <DialogBase
            title="New Content"
            type="OkCancel"
            visible={visible}
            onClose={onClose}
            className={styles.NewContentDialog}
            style={style}
            data-testid="NewContentDialog"
            isLoading={createContentResult.loading}
            onOkClick={onCreateHandler}>
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            name={"name"}
                            component={ValidatedInput}
                            label={"Name"}
                        />
                        <Field
                            name={"category"}
                            component={ValidatedDropdownList}
                            data={["Workset"]}
                            label={"Category"}
                        />
                        <Field
                            name={"description"}
                            component={ValidatedTextArea}
                            label={"Description"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default NewContentDialog;