import { QueryResult } from "@apollo/client";
import react, { createContext, CSSProperties, ReactNode, useState } from "react";
import { Exact, GetProjectQuery, InputMaybe, Project } from "../../generated/graphql";

export const ProjectContext = createContext<QueryResult<GetProjectQuery, Exact<{
    id?: InputMaybe<string> | undefined;
}>> | undefined>(undefined);

type ProjectsProviderProps = {
    project: QueryResult<GetProjectQuery, Exact<{
        id?: InputMaybe<string> | undefined;
    }>>,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

export const ProjectsProvider = (props: ProjectsProviderProps) => {
    const { children, project } = props;
    return (<>
        <ProjectContext.Provider value={project}>
            {children}
        </ProjectContext.Provider>
    </>);
};