import { Toolbar, Button, ToolbarSeparator, Chip } from '@progress/kendo-react-buttons';
import { StackLayout, TabStrip, TabStripTab, TabStripSelectEventArguments, GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import styles from './Library.module.css';
import DataTable from '../../../components/main/DataTable/DataTable';
import DeleteContentsDialog from '../../../components/dialogs/DeleteContentsDialog/DeleteContentsDialog';
import { LibraryCacheStatus, Tag, UserRoleEnum, useGetDirectContentsQuery, useGetInheritedLibrariesQuery, useGetLibraryCacheQuery, useGetLibraryCustomContentsQuery, useGetLibraryProjectsQuery, useGetLibraryQuery, useGetRegisteredContentsQuery } from '../../../generated/graphql';
import { useParams } from 'react-router-dom';
import InheritLibraryDialog from '../../../components/dialogs/InheritLibraryDialog/InheritLibraryDialog';
import { capitalizeFirstLetter, newGuid, ticksToDate } from '../../../Utils';
import { useCurrentUser, useDownloadAttachment, useLoadHttpClient } from '../../../App.hooks';
import UnInheritLibraryDialog from '../../../components/dialogs/UnInheritLibraryDialog/UnInheritLibraryDialog';
import UnregisterContentsDialog from '../../../components/dialogs/UnregisterContentsDialog/UnregisterContentsDialog';
import ShowOnRole from '../../../components/main/ShowOnRole/ShowOnRole';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridDetailRowProps, GridExpandChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import Spinner from '../../../components/main/Spinner/Spinner';
import { State, process as gridProcess, DataResult } from '@progress/kendo-data-query';
import Essentials from '../../../components/main/Essentials/Essentials';
import AddContentsTagsDialog from '../../../components/dialogs/AddContentsTagsDialog/AddContentsTagsDialog';
import ImageViewerButton from '../../../components/main/ImageViewerButton/ImageViewerButton';
import RegisterContentsDialog from '../../../components/dialogs/RegisterContentsDialog/RegisterContentsDialog';
import AddCustomContentsDialog from '../../../components/dialogs/AddCustomContentsDialog/AddCustomContentsDialog';
import AddCustomContentTypesDialog from '../../../components/dialogs/AddCustomContentTypesDialog/AddCustomContentTypesDialog';
import EditCustomContentTypeSettingsDialog from '../../../components/dialogs/EditCustomContentTypeSettingsDialog/EditCustomContentTypeSettingsDialog';
import EditCustomContentSettingsDialog from '../../../components/dialogs/EditCustomContentSettingsDialog/EditCustomContentSettingsDialog';
import DeleteCustomContentsDialog from '../../../components/dialogs/DeleteCustomContentsDialog/DeleteCustomContentsDialog';
import DeleteCustomContentTypesDialog from '../../../components/dialogs/DeleteCustomContentTypesDialog/DeleteCustomContentTypesDialog';
import NewLibraryAdminDialog from '../../../components/dialogs/NewLibraryAdminDialog/NewLibraryAdminDialog';
import DeleteLibraryAdminDialog from '../../../components/dialogs/DeleteLibraryAdminDialog/DeleteLibraryAdminDialog';
import MoveLibraryDialog from '../../../components/dialogs/MoveLibraryDialog/MoveLibraryDialog';
import EditContentSettingsDialog from '../../../components/dialogs/EditContentSettingsDialog/EditContentSettingsDialog';
import MoveContentsDialog from '../../../components/dialogs/MoveContentsDialog/MoveContentsDialog';
import AddContentsGroupsDialog from '../../../components/dialogs/AddContentsGroupsDialog/AddContentsGroupsDialog';
import NewContentDialog from '../../../components/dialogs/NewContentDialog/NewContentDialog';
import AddContentsClassificationsDialog from '../../../components/dialogs/AddContentsClassificationsDialog/AddContentsClassificationsDialog';

const ClassificationsCell = (row: GridCellProps) => {
    return (
        <StackLayout orientation='vertical' align={{ horizontal: "start" }} gap={5}>
            {(row.dataItem?.classifications as any[])?.map(c => (
                <Chip key={newGuid()} style={{ whiteSpace: "nowrap", overflow: "hidden", marginBottom: 5 }} fillMode="outline" themeColor="base">
                    <span style={{ fontWeight: "bold", opacity: 0.8 }}>{c?.type?.name}</span>
                    <span style={{ fontWeight: "bold", opacity: 0.8, marginRight: 5 }}>:</span>
                    <span>{c?.name}</span>
                </Chip>))}

        </StackLayout>);
};

const LIBRARY_CACHE_URI: string = process.env.REACT_APP_LIBRARY_CACHE_URI || "";

const PreviewTab = () => {
    const { libraryId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any[]>([]);
    const [heathData, setHeathData] = useState<any>();
    const [dataState, setDataState] = React.useState<State>({
        skip: 0,
        take: 100,
        sort: [{ field: "Name", dir: "asc" }],
        group: [{ field: "Category" }, { field: "Family" }],
    });
    const download = useDownloadAttachment();
    const [dataResult, setDataResult] = React.useState<DataResult>(
        gridProcess(data, dataState)
    );

    const library = useGetLibraryCacheQuery({
        variables: {
            id: libraryId
        }
    });

    useEffect(() => {
        if (library.data && library.data.Library?.caches) {
            setIsLoading(true);
            download(library.data.Library?.caches).then(res => {
                setIsLoading(false);
                const str = atob(res);
                const rawData = JSON.parse(str);
                const data = (rawData?.data?.ContentsBySecret?.nodes as any[]).flatMap(f => {
                    return (f.types as any[]).length > 0 ? (f.types as any[]).flatMap(t => {
                        return {
                            Name: t.name,
                            Family: f.name,
                            Category: f.category,
                            Application: f.applicationName,
                            Settings: t.settings,
                            TypeMark: (JSON.parse(t.settings ?? "") as any[])?.filter(s => s.name === "Type Mark")[0]?.value ?? "",
                            IfcExportAs: f.ifcEntity?.name ?? "",
                            Uniclass: f.uniclass?.name ?? "",
                            VBIS: f.vBIS?.name ?? "",
                        };
                    }) : {
                        Name: (f.name as string)?.substring((f.name as string)?.indexOf("_") + 1) ?? "",
                        Family: (f.name as string)?.split("_")[0] ?? "",
                        Application: f.applicationName,
                        Category: f.category,
                        Settings: f.settings,
                        TypeMark: f.typeMark ?? "",
                        IfcExportAs: f.ifcExportAs?.name ?? "",
                        Uniclass: f.uniclass?.name ?? "",
                        VBIS: f.vBIS?.name ?? "",
                    };
                });
                setHeathData(rawData.HeathData);
                setData(data);
                setDataResult(gridProcess(data, dataState));
            }).catch(err => setIsLoading(false));
        }
    }, [library]);


    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataResult(gridProcess(data, event.dataState));
        setDataState(event.dataState);
    };

    const expandChange = (event: GridExpandChangeEvent) => {
        const isExpanded =
            event.dataItem.expanded === undefined
                ? event.dataItem.aggregates
                : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;

        setDataResult({ ...dataResult });
    };

    const cellRender = (
        defaultRendering: React.ReactElement<HTMLTableCellElement, string | React.JSXElementConstructor<any>> | null,
        props: GridCellProps) => {
        if (props.rowType === "groupFooter") {
            if (props.field === "Family") {
                return (
                    <td aria-colindex={props.columnIndex} role={"gridcell"}>
                        Count: {props.dataItem.items.length}
                    </td>
                );
            }
        }
        return defaultRendering;
    };
    return (<StackLayout
        className={styles.Projects}
        style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0
        }}
        data-testid="Projects"
        orientation='vertical'
        align={{ horizontal: "stretch", vertical: "top" }}>
        {isLoading ? <Spinner /> :
            <Grid
                style={{
                    height: "100%",
                    width: "100%",
                }}
                sortable={true}
                filterable={true}
                groupable={{ footer: "visible" }}
                reorderable={true}
                pageable={{ buttonCount: 4, pageSizes: [10, 20, 50, 100, 1000] }}
                {...dataState}
                onDataStateChange={dataStateChange}
                expandField="expanded"
                onExpandChange={expandChange}
                data={dataResult}
                cellRender={cellRender}>

                <GridColumn field='Family' />
                <GridColumn field='Name' title='Type Name' />
                <GridColumn field='Category' />
                <GridColumn field='Application' />
                <GridColumn field='TypeMark' title='Type Mark' />
                <GridColumn field='IfcExportAs' />
                <GridColumn field='Uniclass' />
                <GridColumn field='VBIS' />
            </Grid>}
    </StackLayout>);
};
const ContentsTab = ({ isAdmin }: { isAdmin: boolean; }) => {
    const { clientId, libraryId } = useParams();
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [searchText, setSearchText] = useState("");
    const [createContentVisible, setCreateContentVisible] = useState(false);
    const [deleteContentVisible, setDeleteContentVisible] = useState(false);
    const [assignContentsTagsVisible, setAssignContentsTagsVisible] = useState(false);
    const [assignContentsClassificationsVisible, setAssignContentsClassificationsVisible] = useState(false);
    const [assignContentsGroupsVisible, setAssignContentsGroupsVisible] = useState(false);
    const [moveContentsVisible, setMoveContentsVisible] = useState(false);
    const [tagIds, setTagIds] = useState<string[]>([]);
    const currentUser = useCurrentUser();

    const contentData = useGetDirectContentsQuery({
        variables: {
            tagIds: tagIds,
            libraryId: libraryId,
            contains: searchText,
            category: searchText
        },
    });

    const handleOnClose = () => {
        setCreateContentVisible(false);
        setDeleteContentVisible(false);
        setAssignContentsTagsVisible(false);
        setAssignContentsClassificationsVisible(false);
        setAssignContentsGroupsVisible(false);
        setMoveContentsVisible(false);
        contentData.refetch();
    };

    const handleFilterChanged = (tags: Tag[]) => {
        tags && setTagIds(tags.map(t => t.id || ""));
    };

    const GroupsCell = (row: GridCellProps) => {
        return (
            <StackLayout orientation='vertical' align={{ horizontal: "start" }} gap={5}>
                {(row.dataItem?.groups as any[])?.map(g => (<Chip>
                    {g.name}
                </Chip>))}

            </StackLayout>);
    };

    const SettingCell = (row: GridCellProps) => {
        const { contentId } = useParams();
        const [editContentSettingsVisible, setEditContentSettingsVisible] = useState(false);
        const handleOnClose = () => {
            setEditContentSettingsVisible(false);
            contentData.refetch();
        };
        return (
            <StackLayout orientation='horizontal' align={{ horizontal: "center" }}>
                <Button icon='edit' onClick={() => setEditContentSettingsVisible(true)} />
                {editContentSettingsVisible && <EditContentSettingsDialog
                    isAdmin={isAdmin}
                    visible={editContentSettingsVisible}
                    contentId={row.dataItem.id || ""}
                    settings={row.dataItem.settings}
                    onClose={handleOnClose} />}
            </StackLayout>);
    };

    return (
        <>
            <StackLayout
                style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0
                }}
                orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => contentData.refetch()}>
                        Refresh
                    </Button>
                    <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={isAdmin}>
                        <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setCreateContentVisible(true)}>
                            New Content
                        </Button>
                        {selectedIds.length > 0 && <>
                            <ToolbarSeparator />
                            <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setAssignContentsTagsVisible(true)}>
                                Assign Tags
                            </Button>
                            <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setAssignContentsClassificationsVisible(true)}>
                                Assign Classifications
                            </Button>
                            <Button icon="folder-add" fillMode="flat" themeColor={"primary"} onClick={() => setAssignContentsGroupsVisible(true)}>
                                Assign Groups
                            </Button>
                            <Button icon="arrow-right" fillMode="flat" themeColor={"primary"} onClick={() => setMoveContentsVisible(true)}>
                                Move
                            </Button>
                            <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteContentVisible(true)}>
                                Delete
                            </Button>
                        </>}
                    </ShowOnRole>
                </Toolbar>
                <DataTable
                    showTagsColumn
                    onSearchBoxChanged={(value) => setSearchText(value)}
                    onSelected={(value) => setSelectedIds(value)}
                    onFilterChanged={handleFilterChanged}
                    fetchMore={() => contentData.data?.Library?.directContents?.pageInfo?.hasNextPage && contentData.fetchMore({
                        variables: {
                            libraryId: libraryId,
                            contains: searchText,
                            after: contentData.data?.Library?.directContents?.pageInfo.endCursor
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return previousResult;
                            const previousContents = contentData.data?.Library?.directContents?.nodes || [];
                            const newContents = fetchMoreResult.Library?.directContents?.nodes || [];
                            return {
                                ...previousResult,
                                Library: {
                                    id: contentData.data?.Library?.id,
                                    directContents: {
                                        nodes: [...previousContents, ...newContents],
                                        pageInfo: fetchMoreResult.Library?.directContents?.pageInfo as any
                                    }
                                }
                            };
                        }
                    })}
                    style={{ flex: 1 }}
                    isLoading={contentData.loading}
                    data={contentData.data?.Library?.directContents?.nodes}
                    link={`/clients/${clientId}/content`}
                    customProps={[
                        { id: "category", name: "Category" },
                        { id: "applicationName", name: "Application" },
                        ...currentUser?.role !== UserRoleEnum.None ? [{ id: "usageCount", name: "Usage" }] : [],
                        { id: "settings", name: "Settings", width: "78px", customComponent: SettingCell },
                        { id: "groups", name: "Groups", width: "120px", customComponent: GroupsCell },
                        { id: "classifications", name: "Classifications", width: "180px", customComponent: ClassificationsCell }
                    ]}
                    actionButtonsWidth='56px'
                    actionButtons={[
                        (row) => (row.dataItem?.thumbnail?.downloadUrl && <ImageViewerButton
                            title={row.dataItem?.name || ""}
                            link={row.dataItem?.thumbnail?.downloadUrl || ""}
                        />)
                    ]} />
            </StackLayout>
            {createContentVisible && <NewContentDialog libraryId={libraryId || ""} visible={createContentVisible} onClose={handleOnClose} />}
            {deleteContentVisible && <DeleteContentsDialog libraryId={libraryId || ""} ids={selectedIds} visible={deleteContentVisible} onClose={handleOnClose} />}
            {assignContentsTagsVisible && <AddContentsTagsDialog libraryId={libraryId || ""} ids={selectedIds} visible={assignContentsTagsVisible} onClose={handleOnClose} />}
            {assignContentsClassificationsVisible && <AddContentsClassificationsDialog libraryId={libraryId || ""} ids={selectedIds} visible={assignContentsClassificationsVisible} onClose={handleOnClose} />}
            {assignContentsGroupsVisible && <AddContentsGroupsDialog libraryId={libraryId || ""} ids={selectedIds} visible={assignContentsGroupsVisible} onClose={handleOnClose} />}
            {moveContentsVisible && <MoveContentsDialog libraryId={libraryId || ""} ids={selectedIds} visible={moveContentsVisible} onClose={handleOnClose} />}
        </>

    );
};
const RegisteredContentsTab = ({ isAdmin }: { isAdmin: boolean; }) => {
    const { clientId, libraryId } = useParams();
    const [searchRegisteredContents, setSearchRegisteredContents] = useState("");
    const [selectedRegisteredContentsIds, setSelectedRegisteredContentsIds] = useState<string[]>([]);
    const [unregisterContentsVisible, setUnregisterContentsVisible] = useState(false);
    const [registerContentsVisible, setRegisterContentsVisible] = useState(false);
    const [tagIds, setTagIds] = useState<string[]>([]);

    const registeredContents = useGetRegisteredContentsQuery({
        variables: {
            id: libraryId,
            contains: searchRegisteredContents,
            category: searchRegisteredContents,
            tagIds: tagIds
        }
    });

    const handleOnClose = () => {
        setUnregisterContentsVisible(false);
        setRegisterContentsVisible(false);
        registeredContents.refetch();
    };

    const handleFilterChanged = (tags: Tag[]) => {
        tags && setTagIds(tags.map(t => t.id || ""));
    };

    return (
        <>
            <StackLayout
                style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0
                }}
                orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => registeredContents.refetch()}>
                        Refresh
                    </Button>
                    <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={isAdmin}>
                        <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setRegisterContentsVisible(true)}>
                            Register Contents
                        </Button>
                        {selectedRegisteredContentsIds.length > 0 && <>
                            <ToolbarSeparator />
                            <Button icon="minus" fillMode="flat" themeColor={"primary"} onClick={() => setUnregisterContentsVisible(true)}>
                                Unregister
                            </Button>
                        </>}
                    </ShowOnRole>
                </Toolbar>
                <DataTable
                    showTagsColumn
                    showDefaultColumns
                    onFilterChanged={handleFilterChanged}
                    onSearchBoxChanged={(value) => setSearchRegisteredContents(value)}
                    onSelected={(value) => setSelectedRegisteredContentsIds(value)}
                    fetchMore={() => registeredContents.data?.Library?.registeredContents?.pageInfo?.hasNextPage && registeredContents.fetchMore({
                        variables: {
                            libraryId: libraryId,
                            contains: searchRegisteredContents,
                            after: registeredContents.data?.Library?.registeredContents?.pageInfo.endCursor
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return previousResult;
                            const previousContents = registeredContents.data?.Library?.registeredContents?.nodes || [];
                            const newContents = fetchMoreResult.Library?.registeredContents?.nodes || [];
                            return {
                                Library: {
                                    id: registeredContents.data?.Library?.id,
                                    registeredContents: {
                                        nodes: [...previousContents, ...newContents],
                                        pageInfo: fetchMoreResult.Library?.registeredContents?.pageInfo as any
                                    }
                                }
                            };
                        }
                    })}
                    style={{ flex: 1 }}
                    isLoading={registeredContents.loading}
                    data={registeredContents.data?.Library?.registeredContents?.nodes}
                    link={`/clients/${clientId}/content`}
                    customProps={[
                        { id: "library.name", name: "Library" },
                        { id: "category", name: "Category" },
                        { id: "classifications", name: "Classifications", width: "180px", customComponent: ClassificationsCell }
                    ]} />
            </StackLayout>
            {unregisterContentsVisible && <UnregisterContentsDialog libraryId={libraryId || ""} ids={selectedRegisteredContentsIds} visible={unregisterContentsVisible} onClose={handleOnClose} />}
            {registerContentsVisible && <RegisterContentsDialog visible={registerContentsVisible} clientId={clientId || ""} libraryId={libraryId || ""} onClose={handleOnClose} />}
        </>

    );
};
const InheritedLibrariesTab = ({ isAdmin }: { isAdmin: boolean; }) => {
    const { clientId, libraryId } = useParams();
    const [selectedInheritedLibraryIds, setSelectedInheritedLibraryIds] = useState<string[]>([]);
    const [searchInheritedLibraries, setSearchInheritedLibraries] = useState("");
    const [inheritLibraryVisible, setInheritLibraryVisible] = useState(false);
    const [unInheritLibrariesVisible, setUnInheritLibrariesVisible] = useState(false);

    const inheritedLibraries = useGetInheritedLibrariesQuery({
        variables: {
            id: libraryId,
            contains: searchInheritedLibraries
        }
    });
    const handleUnInheritLibrariesOnClose = () => {
        setUnInheritLibrariesVisible(false);
        inheritedLibraries.refetch();
    };

    const handleInheritLibraryClose = () => {
        setInheritLibraryVisible(false);
        inheritedLibraries.refetch();
    };

    return (
        <>
            <StackLayout
                style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0
                }}
                orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => inheritedLibraries.refetch()}>
                        Refresh
                    </Button>
                    <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={isAdmin}>
                        <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setInheritLibraryVisible(true)}>
                            Inherit From
                        </Button>
                        {selectedInheritedLibraryIds.length > 0 && <>
                            <ToolbarSeparator />
                            <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setUnInheritLibrariesVisible(true)}>
                                Delete
                            </Button>
                        </>}
                    </ShowOnRole>
                </Toolbar>
                <DataTable
                    showDefaultColumns
                    onSearchBoxChanged={(value) => setSearchInheritedLibraries(value)}
                    onSelected={(value) => setSelectedInheritedLibraryIds(value)}
                    style={{ flex: 1 }}
                    isLoading={false}
                    data={inheritedLibraries.data?.Library?.inheritedLibraries?.nodes}
                    link="../library" />
            </StackLayout>
            {unInheritLibrariesVisible && <UnInheritLibraryDialog libraryId={libraryId || ""} ids={selectedInheritedLibraryIds} visible={unInheritLibrariesVisible} onClose={handleUnInheritLibrariesOnClose} />}
            {inheritLibraryVisible && <InheritLibraryDialog clientId={clientId} libraryId={libraryId} visible={inheritLibraryVisible} onClose={handleInheritLibraryClose} />}

        </>
    );
};
const CustomContentsTab = ({ isAdmin }: { isAdmin: boolean; }) => {
    const { libraryId } = useParams();
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [addCustomContentsVisible, setAddCustomeContentsVisible] = useState(false);
    const [deleteCustomContentsVisible, setDeleteCustomContentsVisible] = useState(false);
    const customContents = useGetLibraryCustomContentsQuery({
        variables: {
            id: libraryId
        }
    });
    const ContentTypeComponent = (props: GridDetailRowProps) => {
        const [addCustomContentTypesVisible, setAddCustomContentTypesVisible] = useState(false);
        const [deleteCustomContentTypesVisible, setDeleteCustomContentTypesVisible] = useState(false);
        const [setselectedContentTypeIds, setSelectedContentTypeIds] = useState<string[]>([]);
        const handleOnClose = () => {
            setAddCustomContentTypesVisible(false);
            setDeleteCustomContentTypesVisible(false);
            customContents.refetch();
        };
        const EditCustomContentTypeButton = (row: GridCellProps) => {
            const [editCustomContentTypeSettingsVisible, setEditCustomContentTypeSettingsVisible] = useState(false);
            const handleOnClose = () => {
                setEditCustomContentTypeSettingsVisible(false);
                customContents.refetch();
            };
            return (
                <StackLayout orientation='horizontal' align={{ horizontal: "center" }}>
                    <Button icon='edit' onClick={() => setEditCustomContentTypeSettingsVisible(true)} />
                    {editCustomContentTypeSettingsVisible && <EditCustomContentTypeSettingsDialog
                        settings={row.dataItem.customSettings}
                        customContentTypeId={row.dataItem.id}
                        customContentId={props.dataItem.id}
                        libraryId={libraryId || ""}
                        visible={editCustomContentTypeSettingsVisible}
                        onClose={handleOnClose}
                    />}
                </StackLayout>);
        };
        return (
            <>
                <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={isAdmin}>
                    <Toolbar style={{ borderWidth: 1, backgroundColor: "transparent", padding: "5px" }}>
                        <Button icon='add' fillMode="flat" themeColor='primary' onClick={() => setAddCustomContentTypesVisible(true)}>Add Content Types</Button>

                        {setselectedContentTypeIds.length > 0 && <>
                            <ToolbarSeparator />
                            <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteCustomContentTypesVisible(true)} >
                                Delete
                            </Button>
                        </>}
                    </Toolbar>
                </ShowOnRole>
                <DataTable
                    noFlex
                    hideNameColumn
                    data={props.dataItem.customContentTypes}
                    isLoading={false}
                    onSelected={(value) => setSelectedContentTypeIds(value)}
                    customProps={[
                        { id: "contentType.name", name: "Type Name" },
                        { id: "", name: "Custom Settings", width: "140px", customComponent: EditCustomContentTypeButton }
                    ]}
                />
                {addCustomContentTypesVisible && <AddCustomContentTypesDialog
                    visible={addCustomContentTypesVisible}
                    onClose={handleOnClose}
                    libraryId={libraryId || ""}
                    customContentId={props.dataItem.id}
                    contentId={props.dataItem.contentId} />}
                {deleteCustomContentTypesVisible && <DeleteCustomContentTypesDialog
                    visible={deleteCustomContentTypesVisible}
                    onClose={handleOnClose}
                    libraryId={libraryId || ""}
                    customContentId={props.dataItem.id}
                    ids={setselectedContentTypeIds}
                />}
            </>
        );
    };
    const handleOnClose = () => {
        setAddCustomeContentsVisible(false);
        setDeleteCustomContentsVisible(false);
        customContents.refetch();
    };
    const EditCustomContentButton = (row: GridCellProps) => {
        const [editCustomContentSettingsVisible, setEditCustomContentSettingsVisible] = useState(false);
        const handleOnClose = () => {
            setEditCustomContentSettingsVisible(false);
            customContents.refetch();
        };
        return (
            <StackLayout orientation='horizontal' align={{ horizontal: "center" }}>
                <Button icon='edit' onClick={() => setEditCustomContentSettingsVisible(true)} />
                {editCustomContentSettingsVisible && <EditCustomContentSettingsDialog
                    settings={row.dataItem.customSettings}
                    customContentId={row.dataItem.id}
                    libraryId={libraryId || ""}
                    visible={editCustomContentSettingsVisible}
                    onClose={handleOnClose}

                />}
            </StackLayout>);
    };
    return (
        <>
            <StackLayout
                style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0
                }}
                orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => customContents.refetch()}>
                        Refresh
                    </Button>
                    <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={isAdmin}>
                        <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setAddCustomeContentsVisible(true)}>
                            Add Contents
                        </Button>
                        {selectedIds.length > 0 && <>
                            <ToolbarSeparator />
                            <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteCustomContentsVisible(true)}>
                                Delete
                            </Button>
                        </>}
                    </ShowOnRole>
                </Toolbar>
                <DataTable
                    hideNameColumn
                    data={customContents.data?.Library?.customContents}
                    isLoading={customContents.loading}
                    detailComponent={ContentTypeComponent}
                    onSelected={(value) => setSelectedIds(value)}
                    customProps={[
                        { id: "content.name", name: "Name" },
                        { id: "", name: "Custom Settings", width: "140px", customComponent: EditCustomContentButton }
                    ]}
                />

            </StackLayout>
            {addCustomContentsVisible && <AddCustomContentsDialog
                visible={addCustomContentsVisible}
                onClose={handleOnClose}
                libraryId={libraryId || ""} />}
            {deleteCustomContentsVisible && <DeleteCustomContentsDialog
                visible={deleteCustomContentsVisible}
                onClose={handleOnClose}
                libraryId={libraryId || ""}
                ids={selectedIds}
            />}
        </>
    );
};
const AdminsTab = () => {
    const { libraryId } = useParams();
    const [selected, setSelected] = React.useState<number>(0);
    const [newClientAdminVisible, setNewClientAdminVisible] = useState(false);
    const library = useGetLibraryQuery({
        variables: {
            id: libraryId
        }
    });
    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };
    const handleNewLibraryAdminClose = () => {
        library.refetch();
        setNewClientAdminVisible(false);
    };

    const DeleteAdminsButton = (row: GridCellProps) => {
        const [deleteAdminVisible, setDeleteAdminVisible] = useState(false);

        const handleOnClose = () => {
            setDeleteAdminVisible(false);
            library.refetch();
        };

        return (
            <>
                <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={library.data?.Library?.isAdmin || false}>
                    <Button icon='delete' themeColor="error" onClick={() => setDeleteAdminVisible(true)} />
                </ShowOnRole>
                {deleteAdminVisible && <DeleteLibraryAdminDialog visible={deleteAdminVisible} libraryId={libraryId || ""} ids={[row.dataItem?.id]} onClose={handleOnClose} />}
            </>);
    };

    return (
        <>
            <StackLayout
                className={styles.Projects}
                style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0
                }}
                data-testid="Projects"
                orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => library.refetch()}>
                        Refresh
                    </Button>
                    <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={library.data?.Library?.isAdmin || false}>
                        <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setNewClientAdminVisible(true)}>
                            New Admin
                        </Button>
                    </ShowOnRole>
                </Toolbar>
                <DataTable
                    style={{ flex: 1 }}
                    isLoading={library.loading}
                    data={library.data?.Library?.admins}
                    customProps={[{ id: "email", name: "Email" }]}
                    actionButtonsWidth='55px'
                    actionButtons={[
                        DeleteAdminsButton,
                    ]} />
            </StackLayout>
            {newClientAdminVisible && <NewLibraryAdminDialog visible={newClientAdminVisible} onClose={handleNewLibraryAdminClose} libraryId={libraryId ?? ""} />}
        </>
    );
};
const ProjectsTab = () => {
    const { libraryId } = useParams();
    const [newProjectVisible, setNewProjectVisible] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [tagIds, setTagIds] = useState<string[]>([]);

    const result = useGetLibraryProjectsQuery({
        variables: {
            tagIds: tagIds,
            libraryId: libraryId,
            contains: searchText
        }
    });

    const handleSearchBoxOnChanged = (value: string) => {
        setSearchText(value);
    };

    const handleDataTableOnSelected = (ids: string[]) => {
        setSelectedIds(ids);
    };

    const handleOnFilterChanged = (tags: Tag[]) => {
        tags && setTagIds(tags.map(t => t.id || ""));
    };

    return (
        <>
            <StackLayout
                className={styles.Projects}
                style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0
                }}
                data-testid="Projects"
                orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => result.refetch()}>
                        Refresh
                    </Button>
                </Toolbar>
                <DataTable
                    showDefaultColumns
                    style={{ flex: 1 }}
                    isLoading={result.loading}
                    data={result.data?.ProjectList?.nodes}
                    link="/Projects"
                    onSearchBoxChanged={handleSearchBoxOnChanged}
                    onSelected={handleDataTableOnSelected}
                    onFilterChanged={handleOnFilterChanged}
                    customProps={[{ id: "client.name", name: "Client" }]}
                    fetchMore={() => result.data?.ProjectList?.pageInfo?.hasNextPage && result.fetchMore({
                        variables: {
                            libraryId: libraryId,
                            contains: searchText,
                            after: result.data?.ProjectList?.pageInfo.endCursor
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return previousResult;
                            const previousContents = result.data?.ProjectList?.nodes || [];
                            const newContents = fetchMoreResult.ProjectList?.nodes || [];
                            return {
                                ...previousResult,
                                ProjectList: {
                                    nodes: [...previousContents, ...newContents],
                                    pageInfo: fetchMoreResult.ProjectList?.pageInfo as any
                                }
                            };
                        }
                    })} />
            </StackLayout>
        </>
    );
};
type LibraryProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const Library = (props: LibraryProps) => {
    const { style } = props;
    const { libraryId } = useParams();
    const [selected, setSelected] = useState<number>(0);
    const [createCacheLoading, setCreateCacheLoading] = useState(false);
    const [moveLibraryVisible, setMoveLibraryVisible] = useState(false);
    const httpClient = useLoadHttpClient();
    const library = useGetLibraryQuery({
        fetchPolicy: 'no-cache',
        variables: {
            id: libraryId
        },
    });
    const handleCreateCache = () => {
        if (libraryId &&
            (
                library.data?.Library?.cacheStatus === LibraryCacheStatus.Failed ||
                library.data?.Library?.cacheStatus === LibraryCacheStatus.Succeeded ||
                !library.data?.Library?.cacheStatus
            )
        ) {
            setCreateCacheLoading(true);
            const form = new FormData();
            form.append("libraryId", libraryId || "");
            httpClient.post(LIBRARY_CACHE_URI, form)
                .then(res => library.refetch())
                .catch(err => library.refetch())
                .finally(() => setCreateCacheLoading(false));
        }
    };
    const onCloseHandle = () => {
        setMoveLibraryVisible(false);
        library.refetch();
    };
    return (
        <>
            <StackLayout orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}
                className={styles.Library}
                style={style}
                data-testid="Library">
                <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={library.data?.Library?.isAdmin || false}>
                    <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                        <Button disabled={createCacheLoading} icon="plus" fillMode="flat" themeColor={"primary"} onClick={handleCreateCache}>
                            Create Cache
                        </Button>
                        <Button icon="arrow-right" fillMode="flat" themeColor={"primary"} onClick={() => setMoveLibraryVisible(true)}>
                            Move
                        </Button>
                    </Toolbar>
                </ShowOnRole>
                <Essentials
                    isAdmin={library.data?.Library?.isAdmin || false}
                    rows={[
                        { title: "Name", value: library?.data?.Library?.name || "" },
                        { title: "Cache Status", value: capitalizeFirstLetter(library?.data?.Library?.cacheStatus || "") },
                        { title: "Cache Created Time", value: ticksToDate(library?.data?.Library?.cacheCreatedTime || "") },
                        { title: "Description", value: library?.data?.Library?.description || "" },
                    ]} />
                <TabStrip
                    style={{ flex: 1, position: "relative" }}
                    selected={selected}
                    onSelect={(e) => setSelected(e.selected)}
                    tabPosition="top"
                >
                    <TabStripTab title="Contents" >
                        {selected === 0 && <PreviewTab />}
                    </TabStripTab>
                    <TabStripTab title="Direct Contents" >
                        {selected === 1 && <ContentsTab isAdmin={library.data?.Library?.isAdmin || false} />}
                    </TabStripTab>
                    <TabStripTab title="Registered Contents" >
                        {selected === 2 && <RegisteredContentsTab isAdmin={library.data?.Library?.isAdmin || false} />}
                    </TabStripTab>
                    <TabStripTab title="Inherited Libraries">
                        {selected === 3 && <InheritedLibrariesTab isAdmin={library.data?.Library?.isAdmin || false} />}
                    </TabStripTab>
                    <TabStripTab title="Projects">
                        {selected === 4 && <ProjectsTab />}
                    </TabStripTab>
                    <TabStripTab title="Admins">
                        {selected === 5 && <AdminsTab />}
                    </TabStripTab>

                </TabStrip>
            </StackLayout >
            {moveLibraryVisible && <MoveLibraryDialog visible={moveLibraryVisible} onClose={onCloseHandle} libraryId={library.data?.Library?.id || ""} />}
        </>
    );
};

export default Library;