import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { RouteType } from './App.enums';
import { newGuid } from './Utils';

import Clients from './views/Clients/Clients';
import Dashboard from './views/Dashboard/Dashboard';
import Classifications from './views/Classifications/Classifications';
import Classification from './views/Classifications/Classification/Classification';
import Projects from './views/Projects/Projects';
import AccessControl from './views/AccessControl/AccessControl';
import Client from './views/Clients/Client/Client';
import Project from './views/Projects/Project/Project';
import Settings from './views/Settings/Settings';
import { UserRoleEnum } from './generated/graphql';
import { useCurrentUser } from './App.hooks';

export type AppRoute = {
    name: string,
    role?: UserRoleEnum,
    type: RouteType,
    icon: string,
    path: string,
    element: JSX.Element,
};

export const routes: AppRoute[] = [
    {
        name: "Dashboard",
        role: UserRoleEnum.None,
        icon: "k-i-thumbnails-up",
        type: RouteType.Main,
        path: "/dashboard",
        element: <Dashboard />
    },
    {
        name: "Clients",
        role: UserRoleEnum.None,
        icon: "k-i-group",
        type: RouteType.Main,
        path: "/clients",
        element: <Clients />,
    },
    {
        name: "Client",
        role: UserRoleEnum.None,
        icon: "k-i-group",
        type: RouteType.None,
        path: "/clients/:clientId/*",
        element: <Client />,
    },
    {
        name: "Projects",
        role: UserRoleEnum.None,
        icon: "k-i-folder",
        type: RouteType.Main,
        path: "/projects",
        element: <Projects />,
    },
    {
        name: "Projects",
        role: UserRoleEnum.None,
        icon: "k-i-folder",
        type: RouteType.None,
        path: "/projects/:projectId/*",
        element: <Project />,
    },
    {
        name: "Classifications",
        role: UserRoleEnum.None,
        icon: "k-i-categorize",
        type: RouteType.Main,
        path: "/classifications",
        element: <Classifications />
    },
    {
        name: "Classifications",
        role: UserRoleEnum.None,
        icon: "k-i-categorize",
        type: RouteType.None,
        path: "/classifications/:classificationId/*",
        element: <Classification />
    },
    {
        name: "Settings",
        role: UserRoleEnum.Admin,
        icon: "k-i-settings",
        type: RouteType.Main,
        path: "/settings",
        element: <Settings />
    },
    {
        name: "Access Control",
        role: UserRoleEnum.Admin,
        icon: "k-i-lock",
        type: RouteType.Main,
        path: "/AccessControl",
        element: <AccessControl />
    }
];

function AppRouting() {
    const currentUser = useCurrentUser();

    return (
        <Routes>
            {
                routes.map(route => {
                    if (currentUser?.role === UserRoleEnum.Admin)
                        return (<Route key={newGuid()} path={route.path} element={route.element} />);
                    else {
                        if (route.role === UserRoleEnum.None)
                            return (<Route key={newGuid()} path={route.path} element={route.element} />);
                    }
                })
            }
            <Route path="/" element={<Navigate replace to="/dashboard" />} />
            <Route path="*" element={<Navigate replace to="/dashboard" />} />
        </Routes>
    );
}

export default AppRouting;