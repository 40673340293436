import React, { CSSProperties, ReactNode, useState } from 'react';
import styles from './Classifications.module.css';
import { Toolbar, Button, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { StackLayout } from '@progress/kendo-react-layout';
import DeleteClassificationTypesDialog from '../../components/dialogs/DeleteClassificationTypesDialog/DeleteClassificationTypesDialog';
import NewClassificationTypeDialog from '../../components/dialogs/NewClassificationTypeDialog/NewClassificationTypeDialog';
import DataTable from '../../components/main/DataTable/DataTable';
import ShowOnRole from '../../components/main/ShowOnRole/ShowOnRole';
import { Tag, UserRoleEnum, useGetClassificationTypeListQuery } from '../../generated/graphql';

type ClassificationsProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
};

const Classifications = (props: ClassificationsProps) => {
    const { style, className } = props;
    const [searchText, setSearchText] = useState("");
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [tagIds, setTagIds] = useState<string[]>([]);
    const [newClassificationTypeDialogVisible, setNewClassificationDialogVisible] = useState(false);
    const [deleteClassificationTypesDialogVisible, setDeleteClassificationsDialogVisible] = useState(false);

    const result = useGetClassificationTypeListQuery({
        variables: {
            contains: searchText
        },
    });

    const handleDataTableSelected = (ids: string[]) => {
        setSelectedIds(ids);
    };

    const handleOnClose = () => {
        setNewClassificationDialogVisible(false);
        setDeleteClassificationsDialogVisible(false);
        result.refetch();
    };

    const handleOnRefreshClick = () => {
        result.refetch();
    };

    const handleSearchChanged = (value: string) => {
        setSearchText(value);
    };

    const handleFilterChanged = (tags: Tag[]) => {
        tags && setTagIds(tags.map(t => t.id || ""));
    };

    return (
        <>
            <StackLayout
                orientation='vertical'
                className={styles.Classifications}
                style={style}
                data-testid="Classifications"
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Typography.h2 style={{ margin: "10px", flex: 0 }}>
                    Classifications Management
                </Typography.h2>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={handleOnRefreshClick}>
                        Refresh
                    </Button>
                    <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={false}>
                        <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setNewClassificationDialogVisible(true)}>
                            New Classification
                        </Button>
                        {selectedIds.length > 0 &&
                            <>
                                <ToolbarSeparator />
                                <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteClassificationsDialogVisible(true)}>
                                    Delete
                                </Button>
                            </>}
                    </ShowOnRole>
                </Toolbar>
                <DataTable
                    showTagsColumn
                    showDescriptionColumn
                    onSelected={handleDataTableSelected}
                    onSearchBoxChanged={handleSearchChanged}
                    onFilterChanged={handleFilterChanged}
                    style={{ flex: 1 }}
                    data={result.data?.ClassificationTypeList?.nodes}
                    isLoading={result.loading}
                    link="/classifications"
                    customProps={[
                    ]}
                />
            </StackLayout>
            {newClassificationTypeDialogVisible && <NewClassificationTypeDialog visible={newClassificationTypeDialogVisible} onClose={handleOnClose} />}
            {deleteClassificationTypesDialogVisible && <DeleteClassificationTypesDialog ids={selectedIds} visible={deleteClassificationTypesDialogVisible} onClose={handleOnClose} />}
        </ >
    );
};

export default Classifications;