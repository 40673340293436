import { Typography } from '@progress/kendo-react-common';
import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import { useDeleteClientsMutation } from '../../../generated/graphql';
import styles from './DeleteClientsDialog.module.css';

type DeleteClientsDialogProps = {
    onClose: () => void,
    ids: string[],
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const DeleteClientsDialog = (props: DeleteClientsDialogProps) => {
    const { style, visible, ids, onClose } = props;
    const [deleteClients, deleteClientsResult] = useDeleteClientsMutation({
        variables: {
            ids: ids
        }
    });
    const handleOnYesClick = () => {
        deleteClients()
            .then(res => onClose())
            .catch(err => { });
    };

    return (
        <DialogBase
            title="Delete Clients"
            type="YesNo"
            onYesClick={handleOnYesClick}
            isLoading={deleteClientsResult.loading}
            visible={visible}
            onClose={onClose}
            className={styles.DeleteClientsDialog}
            style={style}
            data-testid="DeleteClientsDialog">
            <Typography.p>Are you sure you want to delete these clients?</Typography.p>
        </DialogBase>
    );
};

export default DeleteClientsDialog;