import axios from "axios";
import { useCallback, useContext, useMemo } from "react";
import { AlertContext, CurrentUserContext, ReflectDataContext, TokenContext } from './App.provider';
import { apiUri } from "./authConfig";

export const useSuccessAlert = () => {
    const [setopen, setmessage] = useContext(AlertContext);
    const [reflectData, setreflectDatra] = useContext(ReflectDataContext);
    const setSuccessAlert = (message: string) => {
        setmessage(message);
        setopen(true);
        setreflectDatra(!reflectData);
    };
    return setSuccessAlert;
};

export const useCurrentUser = () => {
    const user = useContext(CurrentUserContext);
    return user;
};

export const useAccessToken = () => {
    const token = useContext(TokenContext);
    return token;
};

export const useLoadHttpClientEmpty = () => {
    const token = useAccessToken();

    return useMemo(() => {
        const backend = axios.create();
        return backend;
    }, [token]);
};

export const useLoadHttpClient = () => {
    const token = useAccessToken();

    return useMemo(() => {
        const backend = axios.create({
            baseURL: apiUri,
            headers: {
                Authorization: token && `Bearer ${token}`,
                "Cache-Control": "no-cache"
            }
        });
        return backend;
    }, [token]);
};

export function useDownloadAttachment(): (attachmentId: string) => Promise<string> {
    const client = useLoadHttpClientEmpty();

    const handleDownload = useCallback(async (attachmentId: string) => {
        const res = await client.get(`${attachmentId}`, {
            responseType: "text"
        });
        return res.data;
    }, [client]);

    return handleDownload;
}

