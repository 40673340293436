import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './DeleteClientAdminDialog.module.css';
import { Typography } from '@progress/kendo-react-common';
import { useDeleteClientAdminsMutation } from '../../../generated/graphql';

type DeleteClientAdminDialogProps = {
    onClose: () => void,
    clientId: string,
    ids: string[],
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const DeleteClientAdminDialog = (props: DeleteClientAdminDialogProps) => {
    const { clientId, ids, style, visible, onClose } = props;
    const [deleteClientAdmins, deleteClientAdminsResult] = useDeleteClientAdminsMutation({
        variables: {
            id: clientId,
            ids: ids
        }
    });
    const handleOnYesClick = () => {
        deleteClientAdmins()
            .then(res => onClose())
            .catch(err => { });
    };
    return (
        <DialogBase
            title="Delete Client Admin"
            type="YesNo"
            isLoading={deleteClientAdminsResult.loading}
            visible={visible}
            onClose={onClose}
            onYesClick={handleOnYesClick}
            className={styles.DeleteClientAdminDialog}
            style={style}
            data-testid="DeleteClientAdminDialog">
            <Typography.p>Are you sure you want to delete this admin?</Typography.p>
        </DialogBase>
    );
};

export default DeleteClientAdminDialog;