import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './DeleteContentTagDialog.module.css';
import { Typography } from '@progress/kendo-react-common';
import { useDeleteContentTagsMutation } from '../../../generated/graphql';

type DeleteContentTagDialogProps = {
    onClose: () => void,
    tagId: string,
    id: string,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const DeleteContentTagDialog = (props: DeleteContentTagDialogProps) => {
    const { id, tagId, style, visible, onClose } = props;
    const [deleteContentTag, deleteContentTagResult] = useDeleteContentTagsMutation({
        variables: {
            id: id,
            ids: [tagId]
        }
    });

    const handleOnOkClick = () => {
        deleteContentTag()
            .then(() => onClose())
            .catch(err => onClose());
    };

    return (
        <DialogBase
            title="Delete Content Tag"
            type="OkCancel"
            isLoading={deleteContentTagResult.loading}
            visible={visible}
            onClose={onClose}
            onOkClick={handleOnOkClick}
            className={styles.DeleteContentTagDialog}
            style={style}
            data-testid="DeleteContentTagDialog">

            <Typography.p>Are you sure you want to delete this Tag?</Typography.p>
        </DialogBase>
    );
};

export default DeleteContentTagDialog;