import React, { CSSProperties, ReactNode, useState } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './RegisterContentsDialog.module.css';
import { Tag, useGetUnregisteredContentsQuery, useRegisterContentsMutation } from '../../../generated/graphql';
import DataTable from '../../main/DataTable/DataTable';
import { Toolbar, Button } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';

type RegisterContentsDialogProps = {
    onClose: () => void,
    clientId: string,
    libraryId: string,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const RegisterContentsDialog = (props: RegisterContentsDialogProps) => {
    const { style, visible, clientId, libraryId, onClose } = props;
    const [searchUnregisteredContents, setSearchUnregisteredContents] = useState("");
    const [selectedUnregisteredContentIds, setSelectedUnregisteredContentIds] = useState<string[]>([]);
    const [tagIds, setTagIds] = useState<string[]>([]);

    const [registerContents, registerContentsResult] = useRegisterContentsMutation({
        variables: {
            id: libraryId,
            contentIds: selectedUnregisteredContentIds
        }
    });

    const unregisteredContents = useGetUnregisteredContentsQuery({
        variables: {
            id: libraryId,
            contains: searchUnregisteredContents,
            category: searchUnregisteredContents,
            tagIds: tagIds
        }
    });

    const handleOnOkClick = () => {
        registerContents()
            .then(res => onClose())
            .catch(err => { });
    };

    const handleFilterChanged = (tags: Tag[]) => {
        tags && setTagIds(tags.map(t => t.id || ""));
    };

    return (
        <DialogBase
            title="Register Contents"
            type="OkCancel"
            visible={visible}
            onClose={onClose}
            onOkClick={handleOnOkClick}
            isLoading={registerContentsResult.loading}
            className={styles.SearchUnregisterdContentsDialog}
            style={style}
            data-testid="RegisterContentsDialog">
            <StackLayout
                style={{
                    height: "600px",
                    width: "1200px",
                }}
                orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => unregisteredContents.refetch()}>
                        Refresh
                    </Button>
                </Toolbar>
                <DataTable
                    showTagsColumn
                    showDefaultColumns
                    onFilterChanged={handleFilterChanged}
                    onSearchBoxChanged={(value) => setSearchUnregisteredContents(value)}
                    onSelected={(value) => setSelectedUnregisteredContentIds(value)}
                    fetchMore={() => unregisteredContents.data?.Library?.unregisteredContents?.pageInfo?.hasNextPage && unregisteredContents.fetchMore({
                        variables: {
                            after: unregisteredContents.data?.Library?.unregisteredContents?.pageInfo.endCursor
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return previousResult;
                            const previousContents = unregisteredContents.data?.Library?.unregisteredContents?.nodes || [];
                            const newContents = fetchMoreResult.Library?.unregisteredContents?.nodes || [];
                            return {
                                Library: {
                                    id: unregisteredContents.data?.Library?.id,
                                    unregisteredContents: {
                                        nodes: [...previousContents, ...newContents],
                                        pageInfo: fetchMoreResult.Library?.unregisteredContents?.pageInfo as any
                                    }
                                }
                            };
                        }
                    })}
                    style={{ flex: 1 }}
                    isLoading={unregisteredContents.loading}
                    data={unregisteredContents.data?.Library?.unregisteredContents?.nodes}
                    link={`/clients/${clientId}/content`}
                    customProps={[
                        { id: "library.name", name: "Library" },
                        { id: "category", name: "Category" }
                    ]} />
            </StackLayout>
        </DialogBase>
    );
};

export default RegisterContentsDialog;