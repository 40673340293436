import { Toolbar, Button, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode, useContext, useState } from 'react';
import styles from './Worksets.module.css';
import DataTable from '../../../components/main/DataTable/DataTable';
import DeleteWorksetsDialog from '../../../components/dialogs/DeleteWorksetsDialog/DeleteWorksetsDialog';
import NewWorksetDialog from '../../../components/dialogs/NewWorksetDialog/NewWorksetDialog';
import { ClientContext } from '../Clients.Provider';
import { UserRoleEnum, Workset, useGetClientQuery } from '../../../generated/graphql';
import ShowOnRole from '../../../components/main/ShowOnRole/ShowOnRole';

type WorksetsProps = {
    refresh: () => void;
    worksets: (Workset | null | undefined)[],
    isLoading: boolean,
    clientId: string,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const Worksets = (props: WorksetsProps) => {
    const { style, worksets, isLoading, clientId, refresh } = props;
    const [newWorksetVisible, setNewWorksetVisible] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [deleteWorksetsDialogVisible, setDeleteWorksetsDialogVisible] = useState(false);


    const client = useContext(ClientContext);

    const handleDeleteWorksetOnClose = () => {
        setDeleteWorksetsDialogVisible(false);
        refresh();
    };
    const handleSearchBoxOnChanged = (value: string) => {
        setSearchText(value);
    };
    const handleDataTableOnSelected = (ids: string[]) => {
        setSelectedIds(ids);
    };
    const handleNewWorksetDialogOnClose = () => {
        setNewWorksetVisible(false);
        refresh();
    };

    return (
        <>
            <StackLayout
                className={styles.Worksets}
                style={style}
                data-testid="Worksets"
                orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0 }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => refresh()}>
                        Refresh
                    </Button>
                    <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={client && client.data?.Client?.isAdmin || false}>
                        <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setNewWorksetVisible(true)}>
                            New Workset
                        </Button>
                        {selectedIds.length > 0 && <>
                            <ToolbarSeparator />
                            <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteWorksetsDialogVisible(true)}>
                                Delete
                            </Button>
                        </>}
                    </ShowOnRole>
                </Toolbar>
                <DataTable
                    showDefaultColumns
                    isLoading={isLoading}
                    style={{ flex: 1 }}
                    data={worksets}
                    customProps={[{ id: "group", name: "Group" }]}
                    onSearchBoxChanged={handleSearchBoxOnChanged}
                    onSelected={handleDataTableOnSelected} />
            </StackLayout>
            <NewWorksetDialog clientId={clientId} visible={newWorksetVisible} onClose={handleNewWorksetDialogOnClose} />
            <DeleteWorksetsDialog clientId={clientId} ids={selectedIds} visible={deleteWorksetsDialogVisible} onClose={handleDeleteWorksetOnClose} />
        </>
    );
};

export default Worksets;