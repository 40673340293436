import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { StackLayout } from '@progress/kendo-react-layout';
import React, { Children, CSSProperties, ReactNode, useState } from 'react';
import Spinner from '../../components/main/Spinner/Spinner';
import { newGuid } from '../../Utils';
import styles from './DialogBase.module.css';

type DialogBaseProps = {
    title: string,
    visible: boolean,
    type: "OkCancel" | "YesNo" | "Custom";
    onClose: () => void,
    onOkClick?: () => void,
    onYesClick?: () => void,
    width?: string,
    isLoading?: boolean,
    actions?: ReactNode | undefined,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
};

const DialogBase = (props: DialogBaseProps) => {
    const { style, actions, className, children, title, visible, type, isLoading, width, onClose, onOkClick, onYesClick } = props;

    const onCloseHandler = () => {
        onClose();
    };

    const onOkClickHandler = () => {
        if (onOkClick) onOkClick();
    };

    const onYesClickHandler = () => {
        if (onYesClick) onYesClick();
    };

    return (<>{
        visible &&
        <Dialog
            title={title}
            onClose={onCloseHandler}
            className={`${styles.DialogBase} ${className || ""}`}
            style={style}
            data-testid="DialogBase">
            <div style={{ width: width || "450px" }}></div>
            {children}
            {(actions || type !== "Custom") &&
                <DialogActionsBar>
                    <StackLayout gap="5px" orientation='horizontal' align={{ horizontal: "end", vertical: "middle" }}>
                        {type === "OkCancel" && <>
                            <Button fillMode="outline" themeColor="error" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button fillMode="outline" themeColor="success" onClick={onOkClickHandler}>
                                Ok
                            </Button> </>}
                        {type === "YesNo" && <>
                            <Button fillMode="outline" themeColor="error" onClick={onClose}>
                                No
                            </Button>
                            <Button fillMode="outline" themeColor="success" onClick={onYesClickHandler}>
                                Yes
                            </Button> </>}
                        {type === "Custom" && <> {actions}</>}
                    </StackLayout>
                    {isLoading && <div style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        backgroundColor: "black",
                        top: 0,
                        left: 0,
                        zIndex: 1000,
                        opacity: 0.5

                    }}>
                        <Spinner size="large" />
                    </div>}
                </DialogActionsBar>
            }
        </Dialog>
    }
    </>);
};

export default DialogBase;