import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { RouteType } from '../../App.enums';
import { newGuid } from '../../Utils';
import { AppRoute } from '../../App.routing';
import Overview from './Overview/Overview';
import AccessControl from './AccessControl/AccessControl';
import Library from './Library/Library';
import Content from './Content/Content';


export const routes: AppRoute[] = [
    {
        name: "Overview",
        icon: "k-icon k-i-group",
        type: RouteType.Main,
        path: "overview",
        element: <Overview />
    },
    {
        name: "Access Control",
        icon: "k-icon k-i-lock",
        type: RouteType.Main,
        path: "AccessControl",
        element: <AccessControl />
    },
    {
        name: "Library",
        icon: "k-icon k-i-group",
        type: RouteType.None,
        path: "library/:libraryId",
        element: <Library />
    },
    {
        name: "Content",
        icon: "k-icon k-i-group",
        type: RouteType.None,
        path: "Content/:contentId",
        element: <Content />
    },
];


function ClientsRouting() {
    return (
        <Routes>
            {routes.map(route => {
                return (<Route key={newGuid()} path={`${route.path}`} element={route.element} />);
            })}
            <Route path="/" element={<Navigate replace to="overview" />} />
            <Route path="*" element={<Navigate replace to="overview" />} />
        </Routes>
    );
}

export default ClientsRouting;