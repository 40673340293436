import React, { CSSProperties, ReactNode } from 'react';
import styles from './Classification.module.css';
import { useGetClassificationTypeQuery } from '../../../generated/graphql';
import { StackLayout } from '@progress/kendo-react-layout';
import { useParams } from 'react-router-dom';
import ClientDrawer from '../../../components/main/ClientDrawer/ClientDrawer';
import PageLayout from '../../../components/main/PageLayout/PageLayout';
import Spinner from '../../../components/main/Spinner/Spinner';
import ClassificationsRouting, { routes } from '../Classifications.routing';
import { ClassificationTypeProvider } from '../Classifications.Provider';
import ChildDrawer from '../../../components/main/ChildDrawer/ChildDrawer';

type ClassificationProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
};

const Classification = (props: ClassificationProps) => {
    const { style, className } = props;
    const { classificationId } = useParams();
    const result = useGetClassificationTypeQuery({
        variables: {
            id: classificationId
        },
    });

    return (<> {!result.data?.ClassificationType ? <Spinner size="large" /> :
        <PageLayout routes={routes}
            pageName="Classifications Management"
            name={result.data.ClassificationType?.name}
            className={styles.Classification} style={style} data-testid="Classification">
            <ChildDrawer routes={routes} >
                <StackLayout orientation='vertical' style={{ height: "100%" }} align={{ horizontal: "stretch", vertical: "top" }} >
                    <ClassificationTypeProvider classification={result}>
                        <ClassificationsRouting />
                    </ClassificationTypeProvider>
                </StackLayout>
            </ChildDrawer>
        </PageLayout>
    }</>
    );
};

export default Classification;