import React, { CSSProperties, ReactNode, useState } from 'react';
import styles from './Groups.module.css';
import { Toolbar, Button, ToolbarSeparator } from '@progress/kendo-react-buttons';
import { StackLayout } from '@progress/kendo-react-layout';
import DataTable from '../../../components/main/DataTable/DataTable';
import { useGetGroupListQuery } from '../../../generated/graphql';
import DeleteGroupsDialog from '../../../components/dialogs/DeleteGroupsDialog/DeleteGroupsDialog';
import NewGroupDialog from '../../../components/dialogs/NewGroupDialog/NewGroupDialog';

type GroupsProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
};

const Groups = (props: GroupsProps) => {
    const { style, className } = props;
    const [searchText, setSearchText] = useState("");
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [deleteGroupDialogVisible, setDeleteGroupsDialogVisible] = useState(false);
    const [newGroupVisible, setNewGroupVisible] = useState(false);

    const result = useGetGroupListQuery({
        variables: {
            contains: searchText
        }
    });
    const handleNewGroupOnClose = () => {
        setNewGroupVisible(false);
        result.refetch();
    };

    const handleDataTableOnSelected = (ids: string[]) => {
        setSelectedIds(ids);
    };
    const handleDeleteGroupOnClose = () => {
        setDeleteGroupsDialogVisible(false);
        result.refetch();
    };
    const handleSearchBoxonChanged = (value: string) => {
        setSearchText(value);
    };

    return (
        <>
            <StackLayout orientation='vertical' className={`${styles.Groups} ${className || ""}`}
                style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0
                }} data-testid="Groups"
                align={{ horizontal: "stretch", vertical: "top" }}>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="add" fillMode="flat" themeColor={"primary"} onClick={() => setNewGroupVisible(true)}>
                        Add
                    </Button>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => result.refetch()}>
                        Refresh
                    </Button>
                    {selectedIds.length > 0 && <>
                        <ToolbarSeparator />
                        <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteGroupsDialogVisible(true)}>
                            Delete
                        </Button>
                    </>}
                </Toolbar>
                <DataTable
                    showDescriptionColumn
                    style={{ flex: 1 }}
                    onSelected={handleDataTableOnSelected}
                    onSearchBoxChanged={handleSearchBoxonChanged}
                    isLoading={result.loading}
                    data={result.data?.GroupList?.nodes}
                    fetchMore={() => result.data?.GroupList?.pageInfo?.hasNextPage && result.fetchMore({
                        variables: {
                            contains: searchText,
                            after: result.data?.GroupList?.pageInfo.endCursor
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return previousResult;
                            const previousContents = result.data?.GroupList?.nodes || [];
                            const newContents = fetchMoreResult.GroupList?.nodes || [];
                            return {
                                ...previousResult,
                                GroupList: {
                                    nodes: [...previousContents, ...newContents],
                                    pageInfo: fetchMoreResult.GroupList?.pageInfo as any
                                }
                            };
                        }
                    })} />
            </StackLayout>
            <DeleteGroupsDialog ids={selectedIds} visible={deleteGroupDialogVisible} onClose={handleDeleteGroupOnClose} />
            <NewGroupDialog visible={newGroupVisible} onClose={handleNewGroupOnClose} />
        </>
    );
};

export default Groups;