import React, { CSSProperties, ReactNode, useRef, useState } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './AddClientHealthChecksDialog.module.css';
import { HealthCheck, useAssignClientsHealthChecksMutation, useGetHealthCheckListQuery } from '../../../generated/graphql';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import ValidatedAutocomplete from '../../main/ValidatedAutocomplete/ValidatedAutocomplete';
import { Checkbox } from '@progress/kendo-react-inputs';
import ValidatedMultiSelect from '../../main/ValidatedMultiSelect/ValidatedMultiSelect';

type AddClientHealthChecksDialogProps = {
    onClose: () => void,
    ids: string[],
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const AddClientHealthChecksDialog = (props: AddClientHealthChecksDialogProps) => {
    const { ids, style, visible, onClose } = props;
    const submitButton = useRef<Button>(null);
    const [assignClientsHealthChecks, assignClientsHealthChecksResult] = useAssignClientsHealthChecksMutation();
    const healthChecks = useGetHealthCheckListQuery({
        fetchPolicy: "no-cache",
        variables: {
            contains: "",
        }
    });

    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            const healthCheckIds = (dataItem["healthChecks"] as HealthCheck[]).map(t => t.id || "");
            const overwrite = dataItem["overwrite"];
            assignClientsHealthChecks({
                variables: {
                    ids: ids,
                    healthCheckIds: healthCheckIds,
                    overwrite: overwrite
                }
            })
                .then(res => { onClose(); })
                .catch(err => { });
        }
    };
    const handleHealthCheckChanged = (event: any) => {

    };

    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };

    const validator = (value: any) => {
        const healthChecks = getter("healthChecks")(value) as HealthCheck[];
        const overwrite = getter("overwrite")(value);

        const healthCheckValidation = { ["healthChecks"]: "Field Cannot Empty!" };

        if (healthChecks && healthChecks.length > 0)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!(healthChecks && healthChecks.length > 0) && healthCheckValidation),
            };
    };

    return (
        <DialogBase
            title="Add Clients Health Checks"
            type="OkCancel"
            visible={visible}
            onClose={onClose}
            className={styles.AddClientHealthChecksDialog}
            style={style}
            data-testid="AddClientHealthChecksDialog"
            isLoading={assignClientsHealthChecksResult.loading}
            onOkClick={onCreateHandler}>
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            name={"healthChecks"}
                            component={ValidatedMultiSelect}
                            textField="name"
                            data={healthChecks.data?.HealthCheckList?.nodes}
                            onChange={handleHealthCheckChanged}
                            label={"HealthChecks"}
                        />
                        <div style={{ height: "8px" }} />
                        <Field
                            name={"overwrite"}
                            component={Checkbox}
                            label={"Overwrite"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default AddClientHealthChecksDialog;