import React, { CSSProperties, ReactNode } from 'react';
import styles from './PieChart.module.css';
import { Chart, ChartTitle, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem } from '@progress/kendo-react-charts';
import { title } from 'process';
import { StackLayout } from '@progress/kendo-react-layout';
import { Typography } from '@progress/kendo-react-common';

export type Pie = {
    data: {
        category: string,
        color: string,
        value: number,
    }[];
    label: string,
    color?: string,
    stack?: boolean,
};

function sum(prev: any, next: any) {
    return prev + next;
}

type PieChartProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
    title?: string;
    data: Pie[];
};

const PieChart = (props: PieChartProps) => {
    const { style, className, data, title } = props;

    return (
        <div className={`${styles.PieChart} ${className || ""}`} style={style} data-testid="PieChart">
            <StackLayout align={{ horizontal: "center" }} orientation='vertical' >
                <Typography.h5 style={{ alignSelf: "center", margin: 10 }}>{title}</Typography.h5>
                <StackLayout orientation='horizontal'>
                    {data.map(d => (
                        <Chart style={{ height: "350px", width: "100%" }}>
                            <ChartTitle text={d.label} />
                            <ChartLegend position="bottom" orientation='vertical' />
                            <ChartSeries>
                                <ChartSeriesItem
                                    tooltip={{ visible: true }}
                                    type="pie"
                                    categoryField="category"
                                    colorField="color"
                                    field='percentage'
                                    color={d.color}
                                    data={d.data.map(i => {
                                        (i as any).percentage = Math.round(i.value * 100 / d.data.map(v => v.value).reduce(sum));
                                        return i;
                                    })}
                                    name={d.label} />
                            </ChartSeries>
                        </Chart>
                    ))}

                </StackLayout>
            </StackLayout>

        </div>
    );
};

export default PieChart;