import { Typography } from '@progress/kendo-react-common';
import { AppBar, AppBarSection, AppBarSpacer, Avatar, } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentUser } from '../../../App.hooks';
import logo from '../../../assets/logo.png';
import styles from './Header.module.css';

type HeaderProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
};

const Header = (props: HeaderProps) => {
    const { style, className } = props;

    const currentUser = useCurrentUser();


    return (
        <AppBar className={`${styles.Header} ${className || ""}`} style={style} data-testid="Header">
            <AppBarSpacer style={{ width: 4 }} />
            <AppBarSection>
                <img src={logo} style={{ height: "25px" }} />
            </AppBarSection>
            <AppBarSpacer />
            <AppBarSection>
                <span className="k-appbar-separator" />
            </AppBarSection>
            <AppBarSection>
                <Avatar size="medium" type="text">
                    {`${currentUser?.name?.split(' ')[0][0]}${currentUser?.name?.split(' ')[1][0]}`}
                </Avatar>
            </AppBarSection>
        </AppBar>
    );
};

export default Header;