import { StackLayout, TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode, useContext, useState } from 'react';
import styles from './Overview.module.css';
import Projects from '../Projects/Projects';
import Libraries from '../Libraries/Libraries';
import Worksets from '../Worksets/Worksets';
import { ClientContext } from '../Clients.Provider';
import { Toolbar, Button } from '@progress/kendo-react-buttons';
import ShowOnRole from '../../../components/main/ShowOnRole/ShowOnRole';
import { HealthCheck, Tag, UserRoleEnum, useToggleClientPrivateMutation } from '../../../generated/graphql';
import Essentials from '../../../components/main/Essentials/Essentials';
import DeleteClientTagDialog from '../../../components/dialogs/DeleteClientTagDialog/DeleteClientTagDialog';
import AddClientHealthChecksDialog from '../../../components/dialogs/AddClientHealthChecksDialog/AddClientHealthChecksDialog';
import EditClientDialog from '../../../components/dialogs/EditClientDialog/EditClientDialog';
import DeleteClientHealthChecksDialog from '../../../components/dialogs/DeleteClientHealthChecksDialog/DeleteClientHealthChecksDialog';

type OverviewProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const Overview = (props: OverviewProps) => {
    const { style } = props;
    const [selected, setSelected] = React.useState<number>(0);
    const [editClientVisible, setEditClientVisible] = useState(false);
    const [deleteClientTagVisible, setDeleteClientTagVisible] = useState(false);
    const [deleteClientHealthCheckVisible, setDeleteClientHealthCheckVisible] = useState(false);
    const [selectedTagId, setselectedTagId] = useState("");
    const [selectedHealthCheckId, setselectedHealthCheckId] = useState("");
    const client = useContext(ClientContext);
    const [togglePrivate, togglePrivateResult] = useToggleClientPrivateMutation();

    const togglePrivateHandle = async () => {
        await togglePrivate({
            variables: {
                id: client?.data?.Client?.id
            }
        });
        client?.refetch();
    };
    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };

    const handleOnClose = () => {
        setDeleteClientHealthCheckVisible(false);
        setDeleteClientTagVisible(false);
        setEditClientVisible(false);
        client?.refetch();
    };

    const handleOnRemoveTag = (tagId: string) => {
        setselectedTagId(tagId);
        setDeleteClientTagVisible(true);
    };

    const handleOnHealthCheckTag = (tagId: string) => {
        setselectedHealthCheckId(tagId);
        setDeleteClientHealthCheckVisible(true);
    };

    return (
        <StackLayout orientation='vertical'
            align={{ horizontal: "stretch", vertical: "top" }}
            className={styles.Overview}
            style={style}
            data-testid="Overview">
            <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={client && client.data?.Client?.isAdmin || false}>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button disabled={togglePrivateResult.loading} icon={client?.data?.Client?.isPrivate === true ? "cancel-outline" : "eye"} fillMode="flat" themeColor={"primary"} onClick={togglePrivateHandle}>
                        {client?.data?.Client?.isPrivate === true ? "Private" : "Public"}
                    </Button>
                    <Button icon="edit" fillMode="flat" themeColor={"primary"} onClick={() => setEditClientVisible(true)}>
                        Edit
                    </Button>
                </Toolbar>
            </ShowOnRole>

            <Essentials
                isAdmin={client && client.data?.Client?.isAdmin || false}
                onRemoveTag={handleOnRemoveTag}
                onRemoveHealthCheck={handleOnHealthCheckTag}
                tags={client?.data?.Client?.tags?.map<Tag>(t => t as Tag)}
                healthChecks={client?.data?.Client?.healthChecks?.map<HealthCheck>(c => c as HealthCheck)}
                rows={[
                    { title: "Name", value: client?.data?.Client?.name || "" },
                    { title: "Long Name", value: client?.data?.Client?.longName || "" },
                    { title: "Description", value: client?.data?.Client?.description || "" },
                    { title: "Default Library", value: client?.data?.Client?.defaultLibrary?.name || "", link: `/clients/${client?.data?.Client?.id}/library/${client?.data?.Client?.defaultLibrary?.id}` }
                ]} />
            <TabStrip
                style={{ flex: 1, position: "relative" }}
                selected={selected}
                onSelect={handleSelect}
                tabPosition="top"
            >
                <TabStripTab title="Libraries">
                    <Libraries />
                </TabStripTab>
                <TabStripTab title="Worksets">
                    <Worksets clientId={client?.data?.Client?.id || ""} isLoading={false} worksets={client?.data?.Client?.worksets || []} refresh={() => client?.refetch()} />
                </TabStripTab>
                <TabStripTab title="Projects">
                    <Projects />
                </TabStripTab>
            </TabStrip>
            {deleteClientHealthCheckVisible && <DeleteClientHealthChecksDialog id={client?.data?.Client?.id || ""} healthCheckId={selectedHealthCheckId} visible={deleteClientHealthCheckVisible} onClose={handleOnClose} />}
            {deleteClientTagVisible && <DeleteClientTagDialog id={client?.data?.Client?.id || ""} tagId={selectedTagId} visible={deleteClientTagVisible} onClose={handleOnClose} />}
            {editClientVisible && <EditClientDialog id={client?.data?.Client?.id || ""} visible={editClientVisible} onClose={handleOnClose} />}

        </StackLayout>
    );
};

export default Overview;