import { Toolbar, Button } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { Card, CardBody, CardHeader, StackLayout } from '@progress/kendo-react-layout';
import React, { CSSProperties } from 'react';
import Spinner from '../../components/main/Spinner/Spinner';
import Timeline from '../../components/main/Timeline/Timeline';
import { Client, Model, Project, useCountClientsQuery, useCountContentQuery, useCountModelsQuery, useCountProjectsQuery, useCountUsersQuery, useGetClientsShortQuery, useGetModelsShortQuery, useGetProjectsShortQuery } from '../../generated/graphql';
import styles from './Dashboard.module.css';

const categories = [2002, 2003, 2004];
const series = [
    {
        name: "India",
        data: [3.907, 7.943, 7.848],
    },
];
type DashboardProps = {
    style?: CSSProperties | undefined;
};

const Dashboard = (props: DashboardProps) => {
    const { style } = props;
    const clientCount = useCountClientsQuery();
    const clients = useGetClientsShortQuery();
    const projectCount = useCountProjectsQuery();
    const projects = useGetProjectsShortQuery();
    const modelCount = useCountModelsQuery();
    const models = useGetModelsShortQuery();
    const userCount = useCountUsersQuery();
    const contentCount = useCountContentQuery();

    const handleRefresh = () => {
        clientCount.refetch();
        projectCount.refetch();
        modelCount.refetch();
        userCount.refetch();
        contentCount.refetch();
    };

    return (
        <StackLayout
            orientation='vertical'
            className={styles.Dashboard}
            style={style}
            data-testid="Dashboard"
            align={{ horizontal: "stretch", vertical: "top" }}>
            <Typography.h2 style={{ margin: "10px", flex: 0 }}>
                Dashboard
            </Typography.h2>
            <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={handleRefresh}>
                    Refresh
                </Button>
            </Toolbar>
            <StackLayout style={{ margin: "10px" }} gap={10}>
                <Card>
                    <CardHeader style={{ display: "flex", justifyContent: "center" }}>
                        <Typography.h4 style={{ margin: 0, padding: 0 }}>
                            Clients
                        </Typography.h4>
                    </CardHeader>
                    <CardBody style={{ display: "flex", justifyContent: "center" }}>
                        <Typography.h1>
                            {clientCount.data?.ClientCount}
                        </Typography.h1>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader style={{ display: "flex", justifyContent: "center" }}>
                        <Typography.h4 style={{ margin: 0, padding: 0 }}>
                            Projects
                        </Typography.h4>
                    </CardHeader>
                    <CardBody style={{ display: "flex", justifyContent: "center" }}>
                        <Typography.h1>
                            {projectCount.data?.ProjectCount}
                        </Typography.h1>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader style={{ display: "flex", justifyContent: "center" }}>
                        <Typography.h4 style={{ margin: 0, padding: 0 }}>
                            Models
                        </Typography.h4>
                    </CardHeader>
                    <CardBody style={{ display: "flex", justifyContent: "center" }}>
                        <Typography.h1>
                            {modelCount.data?.ModelCount}
                        </Typography.h1>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader style={{ display: "flex", justifyContent: "center" }}>
                        <Typography.h4 style={{ margin: 0, padding: 0 }}>
                            Contents
                        </Typography.h4>
                    </CardHeader>
                    <CardBody style={{ display: "flex", justifyContent: "center" }}>
                        <Typography.h1>
                            {contentCount.data?.ContentCount}
                        </Typography.h1>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader style={{ display: "flex", justifyContent: "center" }}>
                        <Typography.h4 style={{ margin: 0, padding: 0 }}>
                            Active Users
                        </Typography.h4>
                    </CardHeader>
                    <CardBody style={{ display: "flex", justifyContent: "center" }}>
                        <Typography.h1>
                            {userCount.data?.UserCount}
                        </Typography.h1>
                    </CardBody>
                </Card>
            </StackLayout>
            <Card style={{ margin: "10px", flex: 1, position: "relative" }}>
                <CardHeader style={{ display: "flex", justifyContent: "left" }}>
                    <Typography.h4 style={{ margin: 0, padding: 0 }}>
                        Timeline
                    </Typography.h4>
                </CardHeader>
                {!clients.loading && !projects.loading && !models.loading ? <Timeline
                    clients={clients.data?.ClientList?.nodes as Client[]}
                    projects={projects.data?.ProjectList?.nodes as Project[]}
                    models={models.data?.ModelList?.nodes as Model[]}
                    clientLoading={clients.loading}
                    projectLoading={projects.loading}
                    modelLoading={models.loading}
                /> : <Spinner />}
            </Card>

        </StackLayout>
    );
};

export default Dashboard;