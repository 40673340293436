import { Button } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { StackLayout } from '@progress/kendo-react-layout';
import React, { Children, CSSProperties, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoute } from '../../../App.routing';
import { ClientProvider } from '../../../views/Clients/Clients.Provider';
import ClientsRouting from '../../../views/Clients/Clients.routing';
import ClientDrawer from '../ClientDrawer/ClientDrawer';
import styles from './PageLayout.module.css';

type PageLayoutProps = {
    routes: AppRoute[],
    pageName: string,
    name: string | undefined | null,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
};

const PageLayout = (props: PageLayoutProps) => {
    const { style, children, className, pageName, name, routes } = props;
    const [currentRoute, setCurrentRoute] = useState<AppRoute>();
    const location = useLocation();

    useEffect(() => {
        setCurrentRoute(
            routes.filter(route => route.path.split("/")[0]?.toLowerCase() === location.pathname.split("/")[3]?.toLowerCase())[0] ??
            routes.filter(route => route.path.split("/")[0]?.toLowerCase() === location.pathname.split("/")[2]?.toLowerCase())[0]
        );
    }, [location.pathname]);

    return (
        <StackLayout orientation='vertical' align={{ vertical: "top" }} className={`${styles.PageLayout} ${className || ""}`} style={style} data-testid="PageLayout">
            <StackLayout style={{ padding: "9.5px 20px", borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: "rgba(0, 0, 0, 0.08)" }} gap="20px" orientation='horizontal' align={{ horizontal: "start", vertical: "middle" }}>
                <span className={currentRoute?.icon} style={{ fontSize: "40px" }}></span>
                <StackLayout orientation='vertical' align={{ horizontal: "stretch", vertical: "top" }} >
                    <StackLayout gap="10px" orientation='horizontal' align={{ horizontal: "start", vertical: "middle" }}>
                        <Typography.h3 style={{ margin: "0" }}>
                            {name}
                        </Typography.h3>
                        {
                            currentRoute?.name &&
                            <>
                                <Typography.h3 style={{ margin: "0" }}>
                                    |
                                </Typography.h3>
                                <Typography.h3 style={{ margin: "0" }}>
                                    {currentRoute?.name}
                                </Typography.h3>
                            </>
                        }
                    </StackLayout>
                    <Typography.h6 style={{ margin: "0", opacity: 0.7, fontWeight: "normal" }}>
                        {pageName}
                    </Typography.h6>
                </StackLayout>
                <Button icon="more-horizontal" fillMode="flat" />
            </StackLayout>
            {children}
        </StackLayout>
    );
};

export default PageLayout;