import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import React, { CSSProperties, ReactNode, useContext, useRef } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import { Client, useCreateLibraryMutation, useCreateProjectMutation } from '../../../generated/graphql';
import { ClientContext } from '../../../views/Clients/Clients.Provider';
import ValidatedInput from '../../main/ValidatedInput/ValidatedInput';
import ValidatedTextArea from '../../main/ValidatedTextArea/ValidatedTextArea';
import styles from './NewLibraryDialog.module.css';

type NewLibraryDialogProps = {
    onClose: () => void,
    clientId: string | undefined | null,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const NewLibraryDialog = (props: NewLibraryDialogProps) => {
    const { style, visible, clientId, onClose } = props;
    const submitButton = useRef<Button>(null);
    const [createLibrary, createLibraryResult] = useCreateLibraryMutation();

    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            createLibrary({
                variables: {
                    library: {
                        name: dataItem["name"],
                        description: dataItem["description"],
                        clientId: clientId
                    }
                }
            })
                .then(res => { onClose(); })
                .catch(err => { });
        }
    };
    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };
    const validator = (value: any) => {
        const name = getter("name")(value);
        const description = getter("description")(value);

        const nameValidation = { ["name"]: "Field Cannot Empty!" };
        const descriptionValidation = { ["description"]: "Field Cannot Empty!" };

        if (name && description)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!name && nameValidation),
                ...(!description && descriptionValidation),
            };
    };

    return (
        <DialogBase
            isLoading={createLibraryResult.loading}
            title="New Library"
            type='OkCancel'
            onClose={onClose}
            onOkClick={onCreateHandler}
            visible={visible}
            className={styles.NewLibraryDialog}
            style={style}
            data-testid="NewLibraryDialog">
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )
                        }
                        <Field
                            name={"name"}
                            component={ValidatedInput}
                            label={"Library Name"}
                        />
                        <Field
                            name={"description"}
                            component={ValidatedTextArea}
                            label={"Description"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default NewLibraryDialog;