import { Button, Chip, Toolbar, ToolbarSeparator } from '@progress/kendo-react-buttons';
import React, { CSSProperties, ReactNode, useState } from 'react';
import styles from './Projects.module.css';
import { StackLayout } from '@progress/kendo-react-layout';
import { Typography } from '@progress/kendo-react-common';
import SearchBox from '../../components/main/SearchBox/SearchBox';
import DataTable from '../../components/main/DataTable/DataTable';
import { Tag, UserRoleEnum, useGetAllProjectsQuery } from '../../generated/graphql';
import DeleteProjectsDialog from '../../components/dialogs/DeleteProjectsDialog/DeleteProjectsDialog';
import ShowOnRole from '../../components/main/ShowOnRole/ShowOnRole';
import RequestAccessProjectDialog from '../../components/dialogs/RequestAccessProjectDialog/RequestAccessProjectDialog';
import AddProjectsTagsDialog from '../../components/dialogs/AddProjectsTagsDialog/AddProjectsTagsDialog';
import AddProjectHealthChecksDialog from '../../components/dialogs/AddProjectHealthChecksDialog/AddProjectHealthChecksDialog';
import { GridCellProps } from '@progress/kendo-react-grid';

const HealthChecksCell = (row: GridCellProps) => {
    return (
        <StackLayout orientation='vertical' align={{ horizontal: "start" }} gap={5}>
            {(row.dataItem?.healthChecks as any[])?.filter(i => i).map(g => (<Chip>
                {g.name}
            </Chip>))}

        </StackLayout>);
};


type ProjectsProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const Projects = (props: ProjectsProps) => {
    const { style } = props;
    const [searchText, setSearchText] = useState("");
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [deleteProjectsDialogVisible, setDeleteProjectsDialogVisible] = useState(false);
    const [assignProjectsTagsVisible, setAssignProjectsTagsVisible] = useState(false);
    const [requestAccessVisible, setRequestAccessVisible] = useState(false);
    const [assignHealthCheckVisible, setAssignHealthCheckVisible] = useState(false);
    const [tagIds, setTagIds] = useState<string[]>([]);
    const result = useGetAllProjectsQuery({
        variables: {
            tagIds: tagIds,
            contains: searchText
        }
    });
    const handleDataTableOnSelected = (ids: string[]) => {
        setSelectedIds(ids);
    };
    const handleOnClose = () => {
        setRequestAccessVisible(false);
        setDeleteProjectsDialogVisible(false);
        setAssignProjectsTagsVisible(false);
        setAssignHealthCheckVisible(false);
        result.refetch();
    };
    const handleSearchBoxonChanged = (value: string) => {
        setSearchText(value);
    };
    const handleFilterChanged = (tags: Tag[]) => {
        tags && setTagIds(tags.map(t => t.id || ""));
    };

    return (
        <div className={styles.Projects} style={style} data-testid="Projects">
            <StackLayout orientation='vertical'
                align={{ horizontal: "stretch", vertical: "top" }}
                style={{ height: "100%", width: "100%" }}>
                <Typography.h2 style={{ margin: "10px", flex: 0 }}>
                    Projects Management
                </Typography.h2>
                <Toolbar style={{ borderWidth: 0, backgroundColor: "transparent", padding: "5px" }}>
                    <Button icon="refresh" fillMode="flat" themeColor={"primary"} onClick={() => result.refetch()}>
                        Refresh
                    </Button>
                    <Button icon="add" fillMode="flat" themeColor={"primary"} onClick={() => setRequestAccessVisible(true)}>
                        Request Access
                    </Button>
                    <ShowOnRole roles={[UserRoleEnum.Admin]} isAdmin={false}>
                        {selectedIds.length > 0 && <>
                            <ToolbarSeparator />
                            <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setAssignProjectsTagsVisible(true)}>
                                Assign Tags
                            </Button>
                            <Button icon="plus" fillMode="flat" themeColor={"primary"} onClick={() => setAssignHealthCheckVisible(true)}>
                                Assign Health Checks
                            </Button>
                            <Button icon="delete" fillMode="flat" themeColor={"error"} onClick={() => setDeleteProjectsDialogVisible(true)}>
                                Delete
                            </Button>
                        </>}
                    </ShowOnRole>
                </Toolbar>
                <DataTable
                    showTagsColumn
                    style={{ flex: 1 }}
                    onSelected={handleDataTableOnSelected}
                    onSearchBoxChanged={handleSearchBoxonChanged}
                    onFilterChanged={handleFilterChanged}
                    isLoading={result.loading}
                    data={result.data?.ProjectList?.nodes}
                    link="/projects"
                    customProps={[
                        { id: "client.name", name: "Client" },
                        { id: "library.name", name: "Library" },
                        { id: "healthChecls", name: "Health Checks", customComponent: HealthChecksCell, width: "150px" }
                    ]}
                    fetchMore={() => result.data?.ProjectList?.pageInfo?.hasNextPage && result.fetchMore({
                        variables: {
                            contains: searchText,
                            after: result.data?.ProjectList?.pageInfo.endCursor
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return previousResult;
                            const previousContents = result.data?.ProjectList?.nodes || [];
                            const newContents = fetchMoreResult.ProjectList?.nodes || [];
                            return {
                                ...previousResult,
                                ProjectList: {
                                    nodes: [...previousContents, ...newContents],
                                    pageInfo: fetchMoreResult.ProjectList?.pageInfo as any
                                }
                            };
                        }
                    })} />
            </StackLayout>
            {deleteProjectsDialogVisible && <DeleteProjectsDialog ids={selectedIds} visible={deleteProjectsDialogVisible} onClose={handleOnClose} />}
            {requestAccessVisible && <RequestAccessProjectDialog visible={requestAccessVisible} onClose={handleOnClose} />}
            {assignProjectsTagsVisible && <AddProjectsTagsDialog ids={selectedIds} visible={assignProjectsTagsVisible} onClose={handleOnClose} />}
            {assignHealthCheckVisible && <AddProjectHealthChecksDialog ids={selectedIds} visible={assignHealthCheckVisible} onClose={handleOnClose} />}

        </div>
    );
};

export default Projects;