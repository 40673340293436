import React, { CSSProperties, ReactNode, useRef } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './NewHealthCheckDialog.module.css';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import { useCreateHealthCheckMutation } from '../../../generated/graphql';
import ValidatedInput from '../../main/ValidatedInput/ValidatedInput';
import ValidatedTextArea from '../../main/ValidatedTextArea/ValidatedTextArea';

type NewHealthCheckDialogProps = {
    onClose: () => void,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const NewHealthCheckDialog = (props: NewHealthCheckDialogProps) => {
    const { style, visible, onClose } = props;
    const submitButton = useRef<Button>(null);
    const [createHealthCheck, createHealthCheckResult] = useCreateHealthCheckMutation();


    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            createHealthCheck({
                variables: {
                    healthCheck: {
                        name: dataItem["name"],
                        group: dataItem["group"],
                        display: dataItem["display"],
                        limit: Number.parseInt(dataItem["limit"]),
                        description: dataItem["description"],
                    }
                }
            })
                .then(res => { onClose(); })
                .catch(err => { });
        }
    };
    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };

    const validator = (value: any) => {
        const name = getter("name")(value);
        const group = getter("group")(value);
        const display = getter("display")(value);
        const limit = getter("limit")(value);
        const description = getter("description")(value);

        const nameValidation = { ["name"]: "Field Cannot Empty!" };
        const groupValidation = { ["group"]: "Field Cannot Empty!" };
        const displayValidation = { ["display"]: "Field Cannot Empty!" };
        const limitValidation = { ["limit"]: "Field Cannot Empty!" };
        const descriptionValidation = { ["description"]: "Field Cannot Empty!" };

        if (name && group && display && description)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!name && nameValidation),
                ...(!group && groupValidation),
                ...(!display && displayValidation),
                ...(!limit && limitValidation),
                ...(!description && descriptionValidation),
            };
    };
    return (
        <DialogBase
            title="New Health Check"
            type="OkCancel"
            visible={visible}
            onClose={onClose}
            onOkClick={onCreateHandler}
            isLoading={createHealthCheckResult.loading}
            className={styles.NewHealthCheckDialog}
            style={style}
            data-testid="NewHealthCheckDialog">
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            name={"name"}
                            component={ValidatedInput}
                            label={"Name"}
                        />
                        <Field
                            name={"group"}
                            component={ValidatedInput}
                            label={"Group"}
                        />
                        <Field
                            name={"display"}
                            component={ValidatedInput}
                            label={"Display"}
                        />
                        <Field
                            name={"limit"}
                            component={ValidatedInput}
                            type={"number"}
                            label={"Limit"}
                        />
                        <Field
                            name={"description"}
                            component={ValidatedTextArea}
                            label={"Description"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default NewHealthCheckDialog;