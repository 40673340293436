import { Typography } from '@progress/kendo-react-common';
import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import { useDeleteClientWorksetsMutation, useDeleteProjectWorksetsMutation } from '../../../generated/graphql';
import styles from './DeleteWorksetsDialog.module.css';

type DeleteWorksetsDialogProps = {
    onClose: () => void,
    clientId?: string,
    projectId?: string,
    ids: string[],
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const DeleteWorksetsDialog = (props: DeleteWorksetsDialogProps) => {
    const { style, visible, clientId, projectId, ids, onClose } = props;
    const [deleteClientWorkset, deleteClientWorksetResult] = useDeleteClientWorksetsMutation({
        variables: {
            id: clientId,
            ids: ids
        }
    });
    const [deleteProjectWorkset, deleteProjectWorksetResult] = useDeleteProjectWorksetsMutation({
        variables: {
            id: projectId,
            ids: ids
        }
    });

    const handleOnYesClick = () => {
        if (clientId)
            deleteClientWorkset()
                .then(res => onClose())
                .catch(err => { });
        else if (projectId)
            deleteProjectWorkset()
                .then(res => onClose())
                .catch(err => { });
    };

    return (
        <DialogBase
            title="Delete Worksets"
            type="YesNo"
            isLoading={deleteClientWorksetResult.loading || deleteProjectWorksetResult.loading}
            visible={visible}
            onClose={onClose}
            onYesClick={handleOnYesClick}
            className={styles.DeleteWorksetsDialog}
            style={style}
            data-testid="DeleteWorksetsDialog">
            <Typography.p>Are you sure you want to delete these worksets?</Typography.p>
        </DialogBase>
    );
};

export default DeleteWorksetsDialog;