import React, { CSSProperties, ReactNode } from 'react';
import styles from './Footer.module.css';
import { AppBar, AppBarSpacer, AppBarSection, Avatar } from '@progress/kendo-react-layout';

const env: string = process.env.REACT_APP_ENV || "";
const build: string = process.env.REACT_APP_BUILD || "";

type FooterProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
};

const Footer = (props: FooterProps) => {
    const { style, className } = props;

    return (
        <AppBar className={`${styles.Footer} ${className || ""}`} style={style} data-testid="Footer">
            <AppBarSpacer style={{ width: 4 }} />
            <span>
                {`© Aurecon Group 2023. Content Management System, Environment: ${env}, Version: 2.0.0.0, Build: ${build} `}
            </span>
            <AppBarSection>
            </AppBarSection>
            <AppBarSpacer />
        </AppBar>
    );
};

export default Footer;