import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { RouteType } from '../../App.enums';
import { newGuid } from '../../Utils';
import { AppRoute } from '../../App.routing';
import ProjectOverview from './ProjectOverview/ProjectOverview';
import AccessControl from './AccessControl/AccessControl';
import Model from './Model/Model';

export const routes: AppRoute[] = [
    {
        name: "Overview",
        icon: "k-icon k-i-group",
        type: RouteType.Main,
        path: "overview",
        element: <ProjectOverview />
    },
    {
        name: "Model",
        icon: "k-icon k-i-folder",
        type: RouteType.None,
        path: "model/:modelId",
        element: <Model />
    },
    {
        name: "Access Control",
        icon: "k-icon k-i-lock",
        type: RouteType.Main,
        path: "AccessControl",
        element: <AccessControl />
    },
];


function ProjectsRouting() {
    return (
        <Routes>
            {routes.map(route => {
                return (<Route key={newGuid()} path={`${route.path}`} element={route.element} />);
            })}
            <Route path="/" element={<Navigate replace to="overview" />} />
            <Route path="*" element={<Navigate replace to="overview" />} />
        </Routes>
    );
}

export default ProjectsRouting;