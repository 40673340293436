import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { RouteType } from '../../App.enums';
import { newGuid } from '../../Utils';
import { AppRoute } from '../../App.routing';
import Overview from './Overview/Overview';


export const routes: AppRoute[] = [
    {
        name: "Overview",
        icon: "k-icon k-i-group",
        type: RouteType.Main,
        path: "overview",
        element: <Overview />
    },
];


function ClassificationsRouting() {
    return (
        <Routes>
            {routes.map(route => {
                return (<Route key={newGuid()} path={`${route.path}`} element={route.element} />);
            })}
            <Route path="/" element={<Navigate replace to="overview" />} />
            <Route path="*" element={<Navigate replace to="overview" />} />
        </Routes>
    );
}

export default ClassificationsRouting;