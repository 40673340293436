import { Reveal } from '@progress/kendo-react-animation';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridExpandChangeEvent } from '@progress/kendo-react-grid';
import { DataResult, process, State } from "@progress/kendo-data-query";
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent, StackLayout, TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDownloadAttachment } from '../../../App.hooks';
import Spinner from '../../../components/main/Spinner/Spinner';
import { useGetModelQuery, useGetProjectQuery } from '../../../generated/graphql';
import styles from './Model.module.css';
import BarChart from '../../../components/main/BarChart/BarChart';
import { groupBy } from '../../../Utils';
import Essentials from '../../../components/main/Essentials/Essentials';
import PieChart from '../../../components/main/PieChart/PieChart';
import { CSVDownload, CSVLink } from "react-csv";
import { Button, Toolbar } from '@progress/kendo-react-buttons';

type HealthData = {
    Name: string,
    Group: string,
    Limit: number,
    Value: number,
    AdditionalData: string,
};

type ModelProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    className?: string;
};

const Model = (props: ModelProps) => {
    const { style, className } = props;
    const { modelId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = useState<number>(0);
    const [data, setData] = useState<any[]>([]);
    const [heathData, setHeathData] = useState<any>();
    const [dataState, setDataState] = React.useState<State>({
        skip: 0,
        take: 100,
        sort: [{ field: "Name", dir: "asc" }],
        group: [{ field: "Category" }, { field: "Family" }],
    });
    const download = useDownloadAttachment();
    const [dataResult, setDataResult] = React.useState<DataResult>(
        process(data, dataState)
    );

    const model = useGetModelQuery({
        variables: {
            id: modelId
        }
    });

    const project = useGetProjectQuery({
        variables: {
            id: model.data?.Model?.projectId
        }
    });

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };

    useEffect(() => {
        if (model.data && model.data.Model?.caches) {
            setIsLoading(true);
            download(model.data.Model?.caches).then(res => {
                setIsLoading(false);
                const str = atob(res);
                const rawData = JSON.parse(str);
                const data = (rawData?.Families as any[] || rawData?.Contents as any[]).flatMap(f => {
                    return (f.Types as any[]).length > 0 ? (f.Types as any[]).flatMap(t => {
                        return {
                            Name: t.Name,
                            Family: f.Name,
                            Category: f.Category,
                            Settings: t.Settings,
                            TypeMark: t.TypeMark,
                            IfcExportAs: f.IfcExportAs,
                            Uniclass: f.Uniclass,
                            VBIS: f.VBIS,
                        };
                    }) : {
                        Name: f.Name,
                        Family: f.Name,
                        Category: f.Category,
                        Settings: f.Settings,
                        TypeMark: f.TypeMark,
                        IfcExportAs: f.IfcExportAs,
                        Uniclass: f.Uniclass,
                        VBIS: f.VBIS,
                    };
                });
                setHeathData(rawData.HeathData);
                setData(data);
                setDataResult(process(data, dataState));
            }).catch(err => setIsLoading(false));
        }
    }, [model]);


    const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataResult(process(data, event.dataState));
        setDataState(event.dataState);
    };

    const expandChange = (event: GridExpandChangeEvent) => {
        const isExpanded =
            event.dataItem.expanded === undefined
                ? event.dataItem.aggregates
                : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;

        setDataResult({ ...dataResult });
    };

    const cellRender = (
        defaultRendering: React.ReactElement<HTMLTableCellElement, string | React.JSXElementConstructor<any>> | null,
        props: GridCellProps) => {
        if (props.rowType === "groupFooter") {
            if (props.field === "Family") {
                return (
                    <td aria-colindex={props.columnIndex} role={"gridcell"}>
                        Count: {props.dataItem.items.length}
                    </td>
                );
            }
        }
        return defaultRendering;
    };

    return (
        <StackLayout
            className={`${styles.Model} ${className || ""}`}
            orientation="vertical"
            style={style}
            data-testid="Model"
            align={{ horizontal: "stretch", vertical: "top" }}>
            <Essentials
                isAdmin={project.data?.Project?.isAdmin || false}
                rows={[
                    { title: "Name", value: model?.data?.Model?.name || "" },
                    { title: "Description", value: model?.data?.Model?.description || "" },
                ]} />
            <TabStrip
                style={{ flex: 1, position: "relative" }}
                selected={selected}
                onSelect={handleSelect}
                tabPosition="top"
            >
                <TabStripTab title="Overview">
                    <StackLayout
                        className={styles.Projects}
                        style={{
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0
                        }}
                        data-testid="Projects"
                        orientation='horizontal'
                        align={{ horizontal: "stretch", vertical: "top" }}>
                        {isLoading ? <Spinner /> :
                            <>
                                {heathData && <PieChart
                                    title='Model Heath'
                                    data={[
                                        {
                                            label: "Families",
                                            data: [
                                                { category: "Found In Library", color: "#89c925", value: heathData.FoundFamilyCount },
                                                { category: "Not Found In Library", color: "#efa693", value: heathData.NotFoundFamilyCount },
                                            ]
                                        },
                                        {
                                            label: "Family Types",
                                            data: [
                                                { category: "Found In Library", color: "#89c925", value: heathData.FoundFamilyTypeCount },
                                                { category: "Not Found In Library", color: "#efa693", value: heathData.NotFoundFamilyTypeCount },
                                                { category: "Not Checked", color: "#fcd672", value: heathData.NotCheckedFamilyTypeCount },
                                            ]
                                        },
                                    ]} />}
                                <BarChart
                                    style={{ flex: 2 }}
                                    title='Family By Category'
                                    data={[
                                        {
                                            label: "Family Count",
                                            color: "#89c925",
                                            data: groupBy(groupBy(data, f => f.Family).map(f => f[0]), item => item.Category).map(g => ({
                                                category: g[0].Category,
                                                count: g.length
                                            })).sort((a1, a2) => a2.category < a1.category ? 1 : -1)
                                        },
                                        {
                                            label: "Family Type Count",
                                            color: "#fcd672",
                                            data: groupBy(data, item => item.Category).map(g => ({
                                                category: g[0].Category,
                                                count: g.length
                                            })).sort((a1, a2) => a2.category < a1.category ? 1 : -1)
                                        }
                                    ]} />
                            </>
                        }
                    </StackLayout>
                </TabStripTab>
                <TabStripTab title="Families">
                    <StackLayout
                        className={styles.Projects}
                        style={{
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0
                        }}
                        data-testid="Projects"
                        orientation='vertical'
                        align={{ horizontal: "stretch", vertical: "top" }}>
                        {isLoading ? <Spinner /> :
                            <Grid
                                style={{
                                    height: "100%",
                                    width: "100%",
                                }}
                                sortable={true}
                                filterable={true}
                                groupable={{ footer: "visible" }}
                                reorderable={true}
                                pageable={{ buttonCount: 4, pageSizes: [10, 20, 50, 100, 1000] }}
                                {...dataState}
                                onDataStateChange={dataStateChange}
                                expandField="expanded"
                                onExpandChange={expandChange}
                                data={dataResult}
                                cellRender={cellRender}>

                                <GridColumn field='Family' />
                                <GridColumn field='Name' title='Type Name' />
                                <GridColumn field='Category' />
                                <GridColumn field='TypeMark' title='Type Mark' />
                                <GridColumn field='IfcExportAs' />
                                <GridColumn field='Uniclass' />
                                <GridColumn field='VBIS' />
                            </Grid>}
                    </StackLayout>
                </TabStripTab>
                <TabStripTab title="Health Check Results">
                    <StackLayout
                        className={styles.Projects}
                        style={{
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0
                        }}
                        data-testid="Projects"
                        orientation='vertical'
                        align={{ horizontal: "stretch", vertical: "top" }}>
                        <Toolbar style={{ borderWidth: 0 }}>
                            <CSVLink
                                style={{ textDecoration: "unset" }}

                                filename={model?.data?.Model?.name || ""}
                                data={(model.data?.Model?.healthResults && JSON.parse(model.data?.Model?.healthResults || "") as any[] || [])
                                    .map<HealthData>(o => {
                                        return {
                                            Name: o.Name,
                                            Group: o.Group,
                                            Value: o.Value,
                                            Limit: o.Limit,
                                            AdditionalData: (o.AdditionalData as string)?.replace(/['"]+/g, '')
                                        };
                                    })}>
                                <Button icon="download" fillMode="flat" themeColor={"primary"}>Download CSV</Button>
                            </CSVLink>
                        </Toolbar>
                        {isLoading ? <Spinner /> :
                            <div style={{ position: "relative", flex: 1 }}>
                                <Grid
                                    style={{
                                        position: "absolute",
                                        height: "100%",
                                        width: "100%",
                                    }}
                                    sortable={false}
                                    filterable={false}
                                    groupable={false}
                                    reorderable={false}
                                    pageable={{ buttonCount: 4, pageSizes: [10, 20, 50, 100, 1000] }}
                                    data={model.data?.Model?.healthResults && JSON.parse(model.data?.Model?.healthResults || "")}>
                                    <GridColumn field='Name' />
                                    <GridColumn field='Group' />
                                    <GridColumn field='Limit' />
                                    <GridColumn field='AdditionalData' title='Additional Data' />
                                    <GridColumn field='Value' />
                                </Grid>
                            </div>}
                    </StackLayout>
                </TabStripTab>
            </TabStrip>
        </StackLayout>
    );
};

export default Model;