import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import React, { CSSProperties, ReactNode, useRef } from 'react';
import { Client, Project, useCreateClientWorksetMutation, useCreateProjectMutation, useCreateProjectWorksetMutation } from '../../../generated/graphql';
import DialogBase from '../../../base/DialogBase/DialogBase';
import ValidatedInput from '../../main/ValidatedInput/ValidatedInput';
import ValidatedTextArea from '../../main/ValidatedTextArea/ValidatedTextArea';
import styles from './NewWorksetDialog.module.css';

type NewWorksetDialogProps = {
    onClose: () => void,
    projectId?: string,
    clientId?: string,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const NewWorksetDialog = (props: NewWorksetDialogProps) => {
    const { style, visible, onClose, projectId, clientId } = props;
    const submitButton = useRef<Button>(null);
    const [createClientWorkset, createClientWorksetResult] = useCreateClientWorksetMutation();
    const [createProjectWorkset, createProjectWorksetResult] = useCreateProjectWorksetMutation();

    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            if (clientId) {
                createClientWorkset({
                    variables: {
                        id: clientId,
                        workset: {
                            name: dataItem["name"],
                            group: dataItem["group"],
                            description: dataItem["description"],
                        }
                    }
                })
                    .then(res => { onClose(); })
                    .catch(err => { });
            }
            else if (projectId) {
                createProjectWorkset({
                    variables: {
                        id: projectId,
                        workset: {
                            name: dataItem["name"],
                            group: dataItem["group"],
                            description: dataItem["description"],
                        }
                    }
                })
                    .then(res => { onClose(); })
                    .catch(err => { });
            }

        }
    };
    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };
    const validator = (value: any) => {
        const name = getter("name")(value);
        const group = getter("group")(value);
        const description = getter("description")(value);

        const nameValidation = { ["name"]: "Field Cannot Empty!" };
        const groupValidation = { ["group"]: "Field Cannot Empty!" };
        const descriptionValidation = { ["description"]: "Field Cannot Empty!" };

        if (name && group && description)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!name && nameValidation),
                ...(!description && descriptionValidation),
                ...(!group && groupValidation),
            };
    };


    return (
        <DialogBase
            isLoading={createClientWorksetResult.loading || createProjectWorksetResult.loading}
            title="New Workset"
            type='OkCancel'
            onClose={onClose}
            onOkClick={onCreateHandler}
            visible={visible}
            className={styles.NewWorksetDialog}
            style={style}
            data-testid="NewWorksetDialog">
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            name={"name"}
                            component={ValidatedInput}
                            label={"Workset Name"}
                        />
                        <Field
                            name={"group"}
                            component={ValidatedInput}
                            label={"Group"}
                        />
                        <Field
                            name={"description"}
                            component={ValidatedTextArea}
                            label={"Description"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default NewWorksetDialog;