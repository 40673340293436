
export function newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r | 0x3 | 0x8);
        return v.toString(16);
    });
}

export function groupBy<T, K>(list: T[], getKey: (item: T) => K) {
    const map = new Map<K, T[]>();
    list.forEach((item) => {
        const key = getKey(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return Array.from(map.values());
}

export function ticksToDate(seconds: string | undefined | null) {
    var locale = (window.navigator as any).userLanguage || window.navigator.language;
    var t = new Date(1970, 0, 1); // Epoch
    t.setUTCSeconds(Number.parseInt(seconds || "") - t.getTimezoneOffset() * 60);
    return t.toLocaleDateString("en-au");
}

export function capitalizeFirstLetter(string: string) {
    return string.toLowerCase().charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export function base64Decode() {
    return;
}