import React, { CSSProperties, ReactNode, useRef, useState } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './AddContentsClassificationsDialog.module.css';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { Form, FormRenderProps, FormElement, Field } from '@progress/kendo-react-form';
import { useAssignContentsClassificationsMutation, useGetClassificationListWithTypeQuery, useGetClassificationTypeListQuery } from '../../../generated/graphql';
import ValidatedAutocomplete from '../../main/ValidatedAutocomplete/ValidatedAutocomplete';

type AddContentsClassificationsDialogProps = {
    onClose: () => void,
    libraryId: string,
    ids: string[],
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const AddContentsClassificationsDialog = (props: AddContentsClassificationsDialogProps) => {
    const { ids, style, visible, libraryId, onClose } = props;
    const [type, setType] = useState("");
    const [search, setsearch] = useState("");
    const submitButton = useRef<Button>(null);
    const [assignContentsClassifications, assignContentsClassificationsResult] = useAssignContentsClassificationsMutation();

    const types = useGetClassificationTypeListQuery({
        fetchPolicy: "no-cache",
        variables: {
            contains: ""
        }
    });

    const classifications = useGetClassificationListWithTypeQuery({
        fetchPolicy: "no-cache",
        variables: {
            contains: search,
            typeId: type
        }
    });

    const onSubmitHandler = (dataItem: { [name: string]: any; }) => {
        if (visible) {
            const classificationIds = classifications.data?.ClassificationList?.nodes?.filter(l => l?.name === dataItem["classification"]).map(t => t?.id || "");
            assignContentsClassifications({
                variables: {
                    ids: ids,
                    libraryId: libraryId,
                    classificationIds: classificationIds
                }
            })
                .then(res => { onClose(); })
                .catch(err => { });
        }
    };
    const handleTypeChanged = (event: any) => {
        const type = types.data?.ClassificationTypeList?.nodes?.filter(l => l?.name === event.value)[0] as any;
        setType(type.id);
    };
    const handleClassificationChanged = (event: any) => {
        setsearch(event.value);
    };
    const onCreateHandler = () => {
        submitButton.current?.element?.click();
    };
    const validator = (value: any) => {
        const type = getter("type")(value);
        const classification = getter("classification")(value);

        const selectedType = types.data?.ClassificationTypeList?.nodes?.filter(l => l?.name === type)[0];
        const selectedClassification = classifications.data?.ClassificationList?.nodes?.filter(t => t?.name === classification)[0];

        const typeValidation = { ["type"]: "Field Cannot Empty!" };
        const validtypeValidation = { ["type"]: "Please Select A Valid Type!" };

        const classificationValidation = { ["classification"]: "Field Cannot Empty!" };
        const validclassificationValidation = { ["classification"]: "Please Select A Valid Classification!" };

        if (type && selectedType && classification && selectedClassification)
            return;
        else
            return {
                VALIDATION_SUMMARY: "Please fill all of the following fields.",
                ...(!type && typeValidation),
                ...((type && !selectedType) && validtypeValidation),
                ...(!classification && classificationValidation),
                ...((classification && !selectedClassification) && validclassificationValidation),
            };
    };

    return (
        <DialogBase
            title="Add Contents Classifications"
            type="OkCancel"
            visible={visible}
            onClose={onClose}
            className={styles.AddContentsClassificationsDialog}
            style={style}
            data-testid="AddContentsClassificationsDialog"
            isLoading={assignContentsClassificationsResult.loading || types.loading || classifications.loading}
            onOkClick={onCreateHandler}>
            <Form
                onSubmit={onSubmitHandler}
                validator={validator}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{ maxWidth: 650 }}>
                        {formRenderProps.visited &&
                            formRenderProps.errors &&
                            formRenderProps.errors.VALIDATION_SUMMARY && (
                                <div className={"k-messagebox k-messagebox-error"}>
                                    {formRenderProps.errors.VALIDATION_SUMMARY}
                                </div>
                            )}
                        <Field
                            name={"type"}
                            component={ValidatedAutocomplete}
                            data={types.data?.ClassificationTypeList?.nodes?.map(p => p?.name)}
                            onChange={handleTypeChanged}
                            label={"Classification Type"}
                        />
                        <Field
                            value={search}
                            name={"classification"}
                            component={ValidatedAutocomplete}
                            data={classifications.data?.ClassificationList?.nodes?.map(p => p?.name)}
                            onChange={handleClassificationChanged}
                            label={"Classification"}
                        />
                        <Button ref={submitButton} style={{ display: "none" }} type="submit" />
                    </FormElement>)} />
        </DialogBase>
    );
};

export default AddContentsClassificationsDialog;