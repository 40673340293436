import { Typography } from '@progress/kendo-react-common';
import React, { CSSProperties, ReactNode } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import { useUnInheritLibrariesMutation } from '../../../generated/graphql';
import styles from './UnInheritLibraryDialog.module.css';

type UnInheritLibraryDialogProps = {
    libraryId: string,
    ids: string[],
    onClose: () => void,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const UnInheritLibraryDialog = (props: UnInheritLibraryDialogProps) => {
    const { style, visible, libraryId, ids, onClose } = props;
    const [unInheritLibraries, unInheritLibrariesResult] = useUnInheritLibrariesMutation({
        variables: {
            id: libraryId,
            ids: ids
        }
    });

    const handleOnYesClick = () => {
        unInheritLibraries()
            .then(res => onClose())
            .catch(err => { });
    };

    return (
        <DialogBase
            title="Un Inherit Libraries"
            type="YesNo"
            isLoading={unInheritLibrariesResult.loading}
            visible={visible}
            onClose={onClose}
            onYesClick={handleOnYesClick}
            className={styles.UnInheritLibraryDialog}
            style={style}
            data-testid="UnInheritLibraryDialog">
            <Typography.p>Are you sure you want to un inherit these Libraries?</Typography.p>
        </DialogBase>
    );
};

export default UnInheritLibraryDialog;