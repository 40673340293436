import { Button } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { StackLayout } from '@progress/kendo-react-layout';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ClientDrawer from '../../../components/main/ClientDrawer/ClientDrawer';
import PageLayout from '../../../components/main/PageLayout/PageLayout';
import Spinner from '../../../components/main/Spinner/Spinner';
import { useGetClientQuery, Client as ClientModel } from '../../../generated/graphql';
import { ClientProvider } from '../Clients.Provider';
import ClientsRouting, { routes } from '../Clients.routing';
import styles from './Client.module.css';

type ClientProps = {
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
};

const Client = (props: ClientProps) => {
    const { style } = props;
    const { clientId } = useParams();
    const result = useGetClientQuery({
        variables: {
            id: clientId
        },
    });
    return (<> {!result.data?.Client ? <Spinner size="large" /> :
        <PageLayout routes={routes} pageName="Client Management" name={result.data.Client.name} className={styles.Client} style={style} data-testid="Client">
            <ClientDrawer >
                <StackLayout orientation='vertical' style={{ height: "100%" }} align={{ horizontal: "stretch", vertical: "top" }} >
                    <ClientProvider client={result}>
                        <ClientsRouting />
                    </ClientProvider>
                </StackLayout>
            </ClientDrawer>
        </PageLayout>
    }</>
    );
};

export default Client;