import React, { CSSProperties, ReactNode, useState } from 'react';
import DialogBase from '../../../base/DialogBase/DialogBase';
import styles from './EditContentSettingsDialog.module.css';
import { UserRoleEnum, useEditContentSettingsMutation, useEditContentTypeSettingsMutation } from '../../../generated/graphql';
import { useCurrentUser } from '../../../App.hooks';
import SettingsEditor from '../../main/SettingsEditor/SettingsEditor';



type EditContentSettingsDialogProps = {
    onClose: () => void,
    contentId: string,
    typeId?: string,
    settings: string,
    visible: boolean,
    style?: CSSProperties | undefined,
    children?: ReactNode | undefined,
    isAdmin: boolean;
};

const EditContentSettingsDialog = (props: EditContentSettingsDialogProps) => {
    const { isAdmin, style, visible, contentId, typeId, settings, onClose } = props;
    const [data, setData] = useState<any[]>([]);
    const [editContentTypeSettings, editContentTypeSettingsResult] = useEditContentTypeSettingsMutation();
    const [editContentSettings, editContentSettingsResult] = useEditContentSettingsMutation();
    const currentUser = useCurrentUser();

    const handleOkClick = async () => {
        if (currentUser?.role !== UserRoleEnum.Admin && !isAdmin) {
            onClose();
        }

        const result = JSON.stringify(data.map(d => ({
            name: d.name || null,
            value: d.value || null,
            unit: d.unit || null,
        })));

        typeId ?
            await editContentTypeSettings({
                variables: {
                    contentId: contentId,
                    typeId: typeId,
                    settings: result
                }
            }) :
            await editContentSettings({
                variables: {
                    contentId: contentId,
                    settings: result
                }
            });

        onClose();
    };

    return (
        <DialogBase
            title={`Content ${typeId ? "Type Settings" : "Settings"}`}
            type={typeId ? "OkCancel" : "Custom"}
            visible={visible}
            onClose={onClose}
            onOkClick={handleOkClick}
            isLoading={editContentTypeSettingsResult.loading || editContentSettingsResult.loading}
            className={styles.EditContentSettingsDialog}
            style={style}
            data-testid="EditContentSettingsDialog">
            <SettingsEditor
                visualizeOnly={typeId ? false : true}
                editable={currentUser?.role === UserRoleEnum.Admin || isAdmin}
                style={typeId ? { height: "600px", width: "600px" } : { maxHeight: "600px", maxWidth: "600px" }}
                settings={settings}
                onDataChange={(data) => setData(data)} />
        </DialogBase>
    );
};

export default EditContentSettingsDialog;